<template>
    <div class="container" :style="$store.getters.secure_bg">
        <div class="columns">
            <div class="column">
                <router-link to="/tasks/open" class="box">
                    <div class="level is-mobile">
                    <div class="level-left">
                        <div class="pending-item-icon has-text-warning">
                        <svg xmlns="http://www.w3.org/2000/svg" width="42" viewBox="0 0 24 24">
                            <path
                            d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM6 14v-2.47l6.88-6.88c.2-.2.51-.2.71 0l1.77 1.77c.2.2.2.51 0 .71L8.47 14H6zm12 0h-7.5l2-2H18v2z"
                            ></path>
                        </svg>
                        </div>
                        <div>
                        <strong>Open Tasks</strong>
                        <div class="help">Total task for submission</div>
                        </div>
                    </div>
                    <div class="level-right">
                        <span class="title is-1">{{ stats.open }}</span>
                    </div>
                </div>
                </router-link>
            </div>
            <div class="column">
                <router-link to="/tasks/recent" class="box">
                    <div class="level is-mobile">
                    <div class="level-left">
                    <div class="pending-item-icon has-text-success">
                        <svg xmlns="http://www.w3.org/2000/svg" width="42" viewBox="0 0 24 24">
                        <path
                            d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-2 14l-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z"
                        ></path>
                        </svg>
                    </div>
                    <div>
                        <strong>Submited Today</strong>
                        <div class="help">Order submitted today</div>
                    </div>
                    </div>
                    <div class="level-right">
                    <span class="title is-1"> {{ stats.todayOrder }}</span>
                    </div>
                </div>
                </router-link>
            </div>
            <div class="column">
                <router-link to="/tasks/open?status=blocked" class="box">
                <div class="level is-mobile">
                <div class="level-left">
                    <div class="pending-item-icon has-text-danger">
                    <svg xmlns="http://www.w3.org/2000/svg" width="42" viewBox="0 0 24 24">
                        <path
                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM4 12c0-4.42 3.58-8 8-8 1.85 0 3.55.63 4.9 1.69L5.69 16.9C4.63 15.55 4 13.85 4 12zm8 8c-1.85 0-3.55-.63-4.9-1.69L18.31 7.1C19.37 8.45 20 10.15 20 12c0 4.42-3.58 8-8 8z"
                        ></path>
                    </svg>
                    </div>
                    <div>
                    <strong>Blocked tasks</strong>
                    <div class="help">Order to be hold</div>
                    </div>
                </div>
                <div class="level-right">
                    <span class="title is-1">{{ stats.blocked }}</span>
                </div>
                </div>
                </router-link>
            </div>
        </div>
    </div>
</template>


<script>
import moment from 'moment'
import * as R from 'ramda'
export default {
    mounted(){
        this.loadData()
    },
    methods:{
        loadData(){
            this.$store.dispatch('om.processors.mytasks')
        },
        getOpenTotal(orders){
            const openOrders = ['ASSIGNED', 'BLOCKED', 'ASSIGNED_RERAISE'].map(status => orders[status] || [])

            return R.pipe(R.flatten, R.length)(openOrders)
        },
        getTodayOrders(orders){
            const submittedOrders = ['RERAISED', 'SUBMITTED'].map(status => orders[status] || [])
            const updateToday = (order) =>  moment(order.updatedAt).isSame(moment(), 'day')
            return R.pipe(R.flatten, R.filter(updateToday), R.length)(submittedOrders)
        }
    },
    computed:{
        mytasks(){
            return this.$store.state.om.mytasks || []
        },
        stats(){
            if(!this.mytasks.length){
                return {}
            }

            const orders = R.pipe(
                R.groupBy(R.prop('Status')),
            )(this.mytasks)

            return {
                open: this.getOpenTotal(orders),
                todayOrder: this.getTodayOrders(orders),
                blocked: (orders.BLOCKED || []).length
            }
        }
    }
}
</script>


module.exports = [
    {
        path: '/',
        redirect: '/dashboard'
    },
    {
        path: '/masterlist',
        component: require('./Masterlist/Blank.vue').default,
        children: [
            {
                path: '/',
                component: require('./Masterlist/Index.vue').default
            },
            {
                meta: {
                    blank: true,
                },
                path: 'sheet',
                component: require('./Masterlist/sheet.vue').default
            }
        ]
    },
    {
        path: '/dashboard',
        component: require('./Dashboard/Index.vue').default
    },
    {
        path: '/dropfiles/:PK',
        component: require('./Dropfiles/Show.vue').default
    },
    {
        path: '/reports',
        component: require('./Reports/Index.vue').default
    },
    {
        path: '/search',
        component: require('./Search/Index.vue').default
    },
    {
        path: '/jobs',
        component: require('./Jobs/Index.vue').default,
        children: [
            {
                path: '',
                // component: require('./Jobs/Summary.vue').default
                redirect: '/jobs/unassigned'
            },
            {
                path: 'new',
                component: require('./Jobs/Create.vue').default
            },
            {
                path: 'unassigned',
                component: require('./Jobs/Unassigned.vue').default
            },
            {
                path: 'recent',
                component: require('./Jobs/Recent.vue').default
            },
            {
                path: 'mytasks',
                component: require('./Tasks/Index.vue').default
            },
            {
                path: 'projects/:project_id',
                component: require('./Jobs/Project.vue').default
            },
            {
                path: 'projects/:project_id/upload',
                component: require('./Jobs/Create.vue').default
            },
            {
                path: 'projects/:project_id/:jobsheet_id',
                component: require('./Jobs/Jobsheet.vue').default
            },
            {
                path: 'processors/:staff_id',
                component: require('./Jobs/Processor.vue').default
            },
        ]
    },
    {
        path: '/dropfiles',
        component: require('./Dropfiles/Layout.vue').default
    },
    {
        path: '/tasks',
        component: require('./Tasks/Layout.vue').default,
        children: [
            {
                path: '',
                redirect: 'open'
            },
            {
                path: 'open',
                component: require('./Tasks/Index.vue').default,
                props: { status: 'Open' }
            },
            {
                path: 'blocked',
                component: require('./Tasks/Index.vue').default,
                props: { status: 'Blocked' }
            },
            {
                path: 'recent',
                component: require('./Tasks/Recent.vue').default
            },
            {
                path: ':task_id',
                component: require('./Tasks/Index.vue').default
            },
            {
                path: 'projects/:project_id',
                component: require('./Tasks/Project.vue').default
            },
            {
                path: 'projects/:project_id/:jobsheet_id',
                component: require('./Tasks/Jobsheet.vue').default
            }
        ]
    },
    {
        path: '/settings',
        component: require('./Settings/Layout.vue').default,
        children: [
            {
                path: '',
                redirect: 'account'
            },
            {
                path: 'account',
                component: require('./Settings/Account.vue').default
            },
            {
                path: 'config',
                component: require('./Settings/Config.vue').default
            }
        ]
    }
]
<template>
    <div class="card-content">
       <div v-if="project">
           <div class="level">
            <div>
                <h1 class="title is-5 has-text-grey">{{ project.name }} Project Overview</h1>
            </div>
            <div>
                
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <div class="card" v-if="jobsheets && jobsheets.length > 0">
                    <table-jobsheets :jobsheets="jobsheets" />
                </div>
                <div v-else class="columns is-centered">
                    <div class="column is-6 has-text-centered has-text-grey-lighter content" style="padding:40px;">
                        <p>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 492.308 492.308" width="160" style="fill:currentColor;">
                                <path d="M316.038 254.298l-14.442-13.385-55.437 59.799-55.438-59.799-14.442 13.385 56.454 60.894-56.454 60.895 14.442 13.384 55.438-59.798 55.437 59.798 14.442-13.384-56.454-60.895z"/>
                                <path d="M242.173 63.731L181.452 0H0v492.308h492.308V63.731H242.173zm230.442 408.884H19.692v-312.23h452.923v312.23zm0-331.923H19.692v-121H173.01l60.721 63.731h238.885v57.269z"/>
                            </svg>
                        </p>
                        <p class="subtitle has-text-grey-light is-3">No jobsheet uploaded yet in this project</p>
                    </div>
                </div>
            </div>
        </div>
       </div>
    </div>
</template>


<script>
import * as R from 'ramda'
import moment from 'moment'
import TableJobsheets from './../TableJobsheets.vue'
export default {
    components:{
        TableJobsheets
    },
    computed:{
        project_id(){
            return this.$route.params.project_id
        },
        project(){
            return this.$store.state.om.projects[this.project_id]
        },
        jobsheets(){
            return (this.project.jobsheets || []).map(jobsheet => Object.assign({},jobsheet,{
                TotalAssigned: (this.taskAssigned[jobsheet.PK] || []).length
            }))
        },
        taskAssigned(){
            return R.pipe(
                R.values,
                R.groupBy(data => data.PK),
            )(this.$store.state.om.mytasks || [])
        }
    },
    methods:{
        loadData(){
            return Promise.all([
                this.$store.dispatch('om.project.load', this.project_id),
                this.$store.dispatch('om.processors.mytasks', this.project_id)
            ]).then(() => {
                this.$store.dispatch('isLoaded')
            })
        }
    },
    mounted(){
        this.loadData()
    },
    watch:{
        project_id(value){
            this.loadData()
        }
    },
    filters:{
        shortKey(value){
            return R.take(12, value)
        },
        formatDate(str){
            return moment(str).format('DD MMM YYYY')
        },
        formatTime(str){
            return moment(str).format('hh:mm A')
        }
    }
}
</script>


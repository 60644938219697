<template>
  <div>
    <div class="modal is-active" v-if="showInprogress">
      <div class="modal-background" @click="showInprogress = false"></div>
      <div class="modal-content" style="width:auto">
        <div class="box">
          <table-jobsheets :jobsheets="jobsheetsInprogress" />
        </div>
      </div>
    </div>
    <div>
      <div class="columns is-multiline">
        <div class="column">
          <router-link to="/dropfiles?status=OPEN" class="box">
            <div class="level is-mobile">
              <div class="level-left">
                <div class="pending-item-icon has-text-warning">
                  <svg xmlns="http://www.w3.org/2000/svg" width="42" viewBox="0 0 24 24">
                    <path
                      d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM6 14v-2.47l6.88-6.88c.2-.2.51-.2.71 0l1.77 1.77c.2.2.2.51 0 .71L8.47 14H6zm12 0h-7.5l2-2H18v2z"
                    ></path>
                  </svg>
                </div>
                <div>
                  <strong>Pending Review</strong>
                  <div class="help">Requests to be review</div>
                </div>
              </div>
              <div class="level-right">
                <span class="title is-3">{{ stats.open }}</span>
              </div>
          </div>
        </router-link>
      </div>

      <div class="column">
        <router-link to="/dropfiles?status=ON_HOLD" class="box">
            <div class="level is-mobile">
              <div class="level-left">
                  <div class="pending-item-icon">
                    <span v-if="stats.onHoldOverdue > 0" class="has-text-danger animated flash infinite">
                      <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 24 24" fill="none" stroke="#FF6600" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"><polygon fill="#FF6600" points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon></svg>
                    </span>
                    <span v-else class="has-text-primary">
                      <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 24 24" fill="none" stroke="#FF6600" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"><polygon fill="#FF6600" points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon></svg>
                    </span>
                    

                  </div>
                  <div>
                  <strong>On hold Request</strong>
                  <div class="help">Pending dropfile to be review</div>
                  </div>
              </div>
              <div class="level-right">
                  <span class="title is-3">{{ stats.onHold }}</span>
              </div>
          </div>
        </router-link>
      </div>

      <div class="column">
          <a @click="showJobsheet('OPEN')" class="box">
          <div class="level is-mobile">
          <div class="level-left">
              <div class="pending-item-icon has-text-info">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                    <title>
                        AWS-Systems-Manager_Automation_dark-bg
                    </title>
                    <path d="M29 48h-9a1 1 0 0 1-.73-.31 1 1 0 0 1-.27-.75l.17-2.89a20.54 20.54 0 0 1-4.77-2l-1.9 2.19a1 1 0 0 1-.72.33 1.08 1.08 0 0 1-.74-.29L4.72 38a1 1 0 0 1-.29-.74 1 1 0 0 1 .33-.72l2.17-1.93A20.09 20.09 0 0 1 5 29.8l-2.94.2A1 1 0 0 1 1 29v-9a1 1 0 0 1 .31-.73 1 1 0 0 1 .75-.27l2.94.2a20 20 0 0 1 2-4.76L4.76 12.5a1 1 0 0 1-.33-.72 1 1 0 0 1 .29-.78L11 4.72a1.1 1.1 0 0 1 .74-.29 1 1 0 0 1 .72.33l1.94 2.18A20 20 0 0 1 19.2 5L19 2.06a1 1 0 0 1 .27-.75A1 1 0 0 1 20 1h9a1 1 0 0 1 .73.31 1 1 0 0 1 .27.75L29.8 5a20.62 20.62 0 0 1 4.75 2l2-2.18a1 1 0 0 1 .72-.33 1 1 0 0 1 .74.29L44.28 11a1 1 0 0 1 .29.74 1 1 0 0 1-.33.72l-2.18 1.94a20.47 20.47 0 0 1 2 4.76l2.9-.17a1 1 0 0 1 .75.27A1 1 0 0 1 48 20v9a1 1 0 0 1-.31.73 1 1 0 0 1-.75.27L44 29.8a20.26 20.26 0 0 1-2 4.77l2.17 1.93a1 1 0 0 1 .33.72 1 1 0 0 1-.29.74L38 44.28a1.06 1.06 0 0 1-.74.29 1 1 0 0 1-.72-.33l-1.93-2.17a19.71 19.71 0 0 1-4.77 2l.16 2.87a1 1 0 0 1-.27.75A1 1 0 0 1 29 48zm-7.88-2h6.82l-.15-2.67a1 1 0 0 1 .78-1 17.87 17.87 0 0 0 5.69-2.36 1 1 0 0 1 1.28.18l1.78 2 4.83-4.83-2-1.78a1 1 0 0 1-.18-1.28 18.18 18.18 0 0 0 2.35-5.69 1 1 0 0 1 1-.78l2.68.15v-6.85l-2.68.15a1 1 0 0 1-1-.78 18 18 0 0 0-2.36-5.68 1 1 0 0 1 .18-1.28l2-1.79-4.85-4.83-1.79 2a1 1 0 0 1-1.29.18 18.31 18.31 0 0 0-5.67-2.35 1 1 0 0 1-.78-1L27.91 3h-6.82l.15 2.69a1 1 0 0 1-.78 1 17.8 17.8 0 0 0-5.68 2.35 1 1 0 0 1-1.28-.18l-1.79-2-4.83 4.85 2 1.79a1 1 0 0 1 .18 1.28 18.24 18.24 0 0 0-2.36 5.68 1 1 0 0 1-1 .78L3 21.09v6.82l2.67-.15a1 1 0 0 1 1 .78 17.85 17.85 0 0 0 2.35 5.69 1 1 0 0 1-.18 1.28l-2 1.78 4.83 4.83 1.78-2a1 1 0 0 1 1.28-.18 18.2 18.2 0 0 0 5.69 2.36 1 1 0 0 1 .78 1z" fill="currentFill"/>
                    <path d="M24.57 36.28a11.73 11.73 0 0 1-11.46-9l2-.46a9.78 9.78 0 0 0 19.09-.32l2 .4a11.81 11.81 0 0 1-11.63 9.38zM15 22.54l-2-.4a11.79 11.79 0 0 1 22.86-.94l-1.86.56a9.79 9.79 0 0 0-19 .78z" fill="currentFill"/>
                    <path fill="currentFill" d="M30.73 20.12l.38-.73 3.6 1.82 1.56-3.73.76.32-1.9 4.54-4.4-2.22zM13.33 34.05l-2-.41 1.44-6.88a1 1 0 0 1 1.17-.76l7.19 1.13-.31 2-6.25-1z"/>
                    <path d="M35.34 23.34a1 1 0 0 1-.4-.08l-6.65-3 .82-1.82L34.88 21l2.76-5.41 1.78.91-3.18 6.3a1 1 0 0 1-.9.54z" fill="currentFill"/>
                </svg>

              </div>
              <div>
              <strong>In Progress</strong>
              <div class="help">In progress jobsheets</div>
              </div>
          </div>
          <div class="level-right">
              <span class="title is-3">{{ stats.inprogress }}</span>
          </div>
          </div>
          </a>
      </div>

      <div class="column">
        <router-link to="/jobs/unassigned?everyone=true" class="box">
          <div class="level is-mobile">
          <div class="level-left">
            <div class="pending-item-icon has-text-info">
              <svg xmlns="http://www.w3.org/2000/svg" width="42" viewBox="0 0 24 24">
                <path
                  d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm0 4c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm6 12H6v-1.4c0-2 4-3.1 6-3.1s6 1.1 6 3.1V19z"
                ></path>
              </svg>
            </div>
            <div>
              <strong>Pending Assign</strong>
              <div class="help">To be assign to processors</div>
            </div>
          </div>
          <div class="level-right">
            <span class="title is-3">{{ stats.unassigned }}</span>
          </div>
        </div>
        </router-link>
      </div>
      <div class="column">
        <router-link to="/jobs/recent?status=assigned&everyone=true&defaultFilterType=all" class="box">
          <div class="level is-mobile">
          <div class="level-left">
            <div class="pending-item-icon has-text-success">
              <svg xmlns="http://www.w3.org/2000/svg" width="42" viewBox="0 0 24 24">
                <path
                  d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-2 14l-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z"
                ></path>
              </svg>
            </div>
            <div>
              <strong>Pending Submit</strong>
              <div class="help">Order creation in progress</div>
            </div>
          </div>
          <div class="level-right">
            <span class="title is-3"> {{ stats.assigned }}</span>
          </div>
        </div>
        </router-link>
      </div>
      <div class="column">
        <router-link to="/jobs/recent?status=blocked&everyone=true&defaultFilterType=all" class="box">
          <div class="level is-mobile">
          <div class="level-left">
            <div class="pending-item-icon has-text-danger">
              <svg xmlns="http://www.w3.org/2000/svg" width="42" viewBox="0 0 24 24">
                <path
                  d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM4 12c0-4.42 3.58-8 8-8 1.85 0 3.55.63 4.9 1.69L5.69 16.9C4.63 15.55 4 13.85 4 12zm8 8c-1.85 0-3.55-.63-4.9-1.69L18.31 7.1C19.37 8.45 20 10.15 20 12c0 4.42-3.58 8-8 8z"
                ></path>
              </svg>
            </div>
            <div>
              <strong>Blocked tasks</strong>
              <div class="help">Order to be hold</div>
            </div>
          </div>
          <div class="level-right">
            <span class="title is-3">{{ stats.blocked }}</span>
          </div>
        </div>
        </router-link>
      </div>
    </div>
      </div>
  </div>
</template>

<script>
import * as R from 'ramda'
import TableJobsheets from './../TableJobsheets.vue'
export default {
  components:{
    TableJobsheets,
  },
  data(){
    return {
      showInprogress: false,
    }
  },
  mounted(){
    this.loadData()
  },
  computed:{
    user(){
      return this.$store.state.login.user
    },
    openDropfiles(){
      return 10
    },
    jobsheetsInprogress(){
      const byMe = data => data.CreatedBy.staff_id == this.user.staff_id
      return R.pipe(R.values, R.filter(data => data.Status == 'OPEN' && byMe(data)))(this.$store.state.om.jobs)
    },
    stats(){
      return this.$store.state.om.stats
    }
  },
  methods:{
    showJobsheet(type){
      this.showInprogress = true
    },
    loadData(){
      this.$store.dispatch('om.jobs.load')
      this.$store.dispatch('om.stats.load')
    }
  }
}
</script>



<style>
.pending-item{
    padding:20px 20px;
    
}

.pending-item-icon{
    padding-right: 20px;
}

.pending-item-icon svg{
    fill: currentColor
}
</style>




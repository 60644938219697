<template>
    <div class="container" style="padding-top: 40px;">
        <div class="columns is-multiline">
            <div class="column is-12">
                <div class="level">
                    <div class="level-start">
                        <h1 class="title is-5">Dropfiles</h1>
                    </div>
                    <div class="level-end">
                        <input type="text" v-model="filterKeyword" class="input is-rounded" placeholder="Filter...">
                    </div>
                </div>
            </div>
            <div class="column is-12">
                <div class="tabs is-toggle is-toggle-rounded ">
                    <ul>
                        <li :class="{'is-active': isActive('OPEN')}"><a @click="setActive('OPEN')">Open<span style="margin-left:5px;" class=" is-pulled-right tag is-dark has-background-grey-darker"><animated-number :value="stats.OPEN" :round="true" /></span></a></li>
                        <li :class="{'is-active': isActive('ON_HOLD'), 'is-alert': hasOverdueHolds}" >
                            <a @click="setActive('ON_HOLD')">
                                <i v-if="hasOverdueHolds" class="icon is-paddingless">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path><line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12" y2="17"></line></svg>
                                </i>
                                On hold
                                <span style="margin-left:5px;" class=" is-pulled-right tag is-dark has-background-grey-darker"><animated-number :value="stats.ON_HOLD" :round="true" /></span>
                            </a>
                        </li>
                        <li :class="{'is-active': isActive('UPLOADED')}"><a @click="setActive('UPLOADED')">Created <span style="margin-left:5px;" class=" is-pulled-right tag is-dark has-background-grey-darker"><animated-number :value="stats.UPLOADED" :round="true" /></span></a></li>
                        <li :class="{'is-active': isActive('REJECTED')}"><a @click="setActive('REJECTED')">Rejected <span style="margin-left:5px;" class=" is-pulled-right tag is-dark has-background-grey-darker"><animated-number :value="stats.REJECTED" :round="true" /></span></a></li>
                        <li :class="{'is-active': isActive('TASK_COMPLETED')}"><a @click="setActive('TASK_COMPLETED')">Completed <span style="margin-left:5px;" class=" is-pulled-right tag is-dark has-background-grey-darker"><animated-number :value="stats.TASK_COMPLETED" :round="true" /></span></a></li>

                    </ul>
                    <ul class="is-right is-small" v-if="lastKey">
                        <li class="is-small"><a class="is-small button" @click="loadMore" :class="{'is-loading': isLoading}" >Load more</a></li>
                    </ul>
                </div>
            </div>
            <div class="column is-12">
                <div class="card">
                    <div v-if="nofile" class="card-content">
                        You have no order request yet
                    </div>
                    <table v-if="!nofile" class="table is-fullwidth is-hoverable">
                        <thead>
                            <tr>
                                <td colspan="6">
                                    <div class="level">
                                        <div class="level-start"></div>
                                        <div class="level-end">
                                            <sorter :data="filteredFiles" :sortfields="{
                                                'details.filename': 'filename',
                                                'CreatedBy.name':'Created by',
                                                'Status': 'Status',
                                                'createdAt': 'Uploaded date',
                                                'details.on_hold.duedate': 'Due date'
                                            }" defaultSort="createdAt" @sorted="onSorted" />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">Drop Request</td>
                                <td>Requestor</td>
                                <td>Created date</td>
                                <td>Status</td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="" v-for="(file, i) in sortedData" :key=i>
                                <td class="has-text-grey-lighter has-text-centered" style="padding-right:0">{{ i + 1}}</td>
                                <td>
                                    <span class="is-pulled-right">
                                        <debug-tool :content="{ PK: file.drop_id}" />
                                    </span>
                                    <a @click="downloadFile(file)">{{ file.details.subject }} </a>
                                    <span class="heading">{{ file.drop_id | shortKey }}</span>
                                </td>
                                <td>
                                    <span>{{ file.CreatedBy.name }}</span>
                                    <span class="heading">{{ file.CreatedBy.staff_id }}</span>
                                </td>
                                <td>
                                    <span class="tooltip" :data-tooltip="file.createdAt | formatDate">{{ file.createdAt | fromNow  }}</span>
                                </td>
                                <td>
                                    <drop-status :file="file" />
                                </td>
                                <td class="has-text-right">
                                    <!-- <reject-file v-if="file.Status == 'OPEN'" :file="file" @reject="handleReject" /> -->
                                    <router-link v-if="file.Status == 'UPLOADED' || file.Status == 'TASK_COMPLETED'" :to="jumpToJobsheet(file)"  class="button is-small" >View &raquo;</router-link>
                                    <div v-else>
                                        <reject-file class="dropdown-item" v-if="file.Status == 'OPEN'" :file="file" @reject="handleReject" />
                                        <hold-file class="dropdown-item" v-if="file.Status == 'OPEN'" :file="file" @onHold="handleOnHold" />
                                    </div>
                                    
                                    <div v-if="file.Status == 'ON_HOLD'" >
                                        <a @click="releaseHold(file)" class="button is-small">Release hold</a>
                                        <a @click="toggleModal(file.drop_id)" class="button is-small" style="margin-top: 4px;">Revise hold date</a>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="modal is-active" v-if="showModal">
            <div class="modal-background"></div>
            <div class="modal-content">
                <div class="card">
                    <div class="card-content">
                        <form @submit.prevent="submitRevise">
                            <div class="columns  has-text-left">
                                <div class="column">
                                    <div class="field">
                                        <label class="label">Revise on hold date until: {{ calendarData.selectedDate }}</label>
                                        <div class="control card calendar" :class="{'is-danger': errors.has('duedate')}">
                                            <FunctionalCalendar
                                                name="duedate"
                                                v-model="calendarData"
                                                v-validate="'required'"
                                                :disabledDates="['beforeToday']"
                                                :sundayStart="true"
                                                :date-format="'dd/mm/yyyy'"
                                                :is-date-picker="true"  
                                                :transition="true"
                                                :isModal="false"
                                                ref="Calendar"  
                                            ></FunctionalCalendar>
                                        </div>
                                    </div>        
                                </div>
                                <div class="column">
                                    <div class="field">
                                        <label class="label">Reason</label>
                                        <div class="control">
                                            <textarea v-model="reason" name="reason" v-validate="'required'" class="textarea" rows="10" :class="{'is-danger': errors.has('reason')}" placeholder="Explain why you put this request on hold"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="field">
                                <div class="control">
                                    <div class="buttons">
                                        <button type="submit" class="button is-dark">Revise</button>
                                        <a @click="showModal = showCal = false" class="button">Cancel</a>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <button class="modal-close is-large" aria-label="close" @click="showModal = false"></button>
        </div>
    </div>
</template>

<script>
import * as R from 'ramda'
import moment from 'moment'
import rejectFile from './RejectFile.vue'
import holdFile from './HoldFile.vue'
import Sorter from './../Tasks/Sorter.vue'
import DropStatus from './DropStatus.vue'
import AnimatedNumber from 'animated-number-vue'
import DebugTool from './../../../commons/DebugTool'
import { FunctionalCalendar } from 'vue-functional-calendar';

export default {
    beforeRouteEnter(to,from,next){
       next(vm => {
           if(!vm.$store.getters.om_role(['executives','project_manager','supervisors'])){
               vm.$router.replace('/')
           }
       })
    },
    components:{
        Sorter,
        holdFile,
        DebugTool,
        rejectFile,
        DropStatus,
        AnimatedNumber,
        FunctionalCalendar,
    },
    data(){
        return {
        //    files: [] 
            filterKeyword:'',
            sortedData: [],
            isLoading: false,
            activeTab: 'OPEN',
            showModal: false,
            reason: '',
            showCal: false,
            calendarData: '',
            today: new Date()
        }
    },
    mounted(){
        if(this.$route.query.status){
            // this.filterKeyword = `"${this.$route.query.status }"`
            this.setActive(this.$route.query.status)
        }
        
        this.$store.dispatch('isLoaded')
        // this.$store.dispatch('om.dropfile.resetKey')
        this.$store.dispatch('om.dropfile.all')
        this.$store.dispatch('om.dropfile.onholds')
    },
    methods:{  
        toggleModal(drop_id){
            this.drop_id = drop_id
            this.showModal = true
            this.calendarData = moment();
        },
        submitRevise(){
            this.$validator.validateAll().then(passed => {
                if(!passed){
                    return;
                }
                
                if (this.calendarData.selectedDate == null) {
                    alert('No date selected')
                    return
                }

                this.$store.dispatch('on.dropfile.hold', {
                    drop_id: this.drop_id,
                    reason: this.reason,
                    duedate: this.calendarData.selectedDate
                })

                // this.handleOnHold({this.drop_id,this.reason,this.calendarData.selectedDate})

                this.showModal = false
            })
        },
        releaseHold(file){
            if(!confirm('Are you sure want to release this file?')){
                return
            }

            this.$store.dispatch('om.dropfile.release', file).then(() => {
                alert("The file released")
            })
        },
        setActive(tab){
            this.activeTab = tab
        },
        isActive(tab){
            return this.activeTab == tab
        },
        loadMore(){
            this.isLoading = true
            this.$store.dispatch('om.dropfile.all').then(() => this.isLoading = false)
        },
        onSorted(sorted){
            this.sortedData = sorted
        },
        isRejected(file){
            return file.status == 'rejected'
        },
        isPending(file){
            return file.status == 'open'
        },
        async downloadFile(file){
            const url = await this.$store.dispatch('om.dropfile.download', {drop_id: file.drop_id})
            window.open(url, "_BLANK")
        },
        handleReject({drop_id, reason}){
            this.$store.dispatch('om.dropfile.reject', {
                drop_id,
                reason
            })
        },
        handleOnHold({ drop_id, reason, duedate}){
            this.$store.dispatch('on.dropfile.hold', {
                drop_id,
                reason,
                duedate
            })
        },
        
        jumpToJobsheet(file){
            const path = `/jobs/projects/${file.details.uploading.project_id}/${file.PK}`
            return path
        },
    },
    computed:{
        overDueHolds(){
            return R.pipe(
                R.filter(file => {
                    return file.Status == 'ON_HOLD' && file.details.on_hold.isOverdue
                })
            )(this.files)
        },
        hasOverdueHolds(){
            return R.keys(this.overDueHolds).length > 0
        },
        stats(){
            return R.pipe(
                R.values,
                R.groupBy(R.prop('Status')),
                R.map(data => data.length)
            )(this.files)
        },
        filteredFiles(){
            return R.pipe(
                R.filter( data => {
                    return data.Status == this.activeTab && (JSON.stringify(data).toLowerCase().indexOf(this.filterKeyword.toLowerCase()) > -1)
                }),
                R.values,
            )(this.files)
        },
        user(){
            return this.$store.state.login.user
        },
        files(){
            // console.log(this.$store.state.om.dropfile)
            return this.$store.state.om.dropfile.files
        },
        nofile(){
            return R.keys(this.files).length == 0
        },
        lastKey(){
            return this.$store.state.om.dropfile.lastKey
        }
    },
    filters:{
        fromNow(value){
            return moment(value).fromNow()
        },
        formatDate(value){
            return moment(value).format('DD MMM YYYY, hh:mm A')
        },
        shortKey(value){
            return R.take(12, value || '')
        },
        dropBy(file){
            return (file.CreatedBy || {}).name || file.GS2
        }
    }
}
</script>


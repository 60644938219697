<template>
    <div>
        <blockage v-if="showBlockage" @close="showBlockage = false" :selectedTask=task @blocked="onblocked" />
        <cancellation v-if="showCancellation" @close="showCancellation = false" :selectedTask=task @cancelled="oncancelled" :task=task />
        <patch-feedback v-if="showPatchFeedback" @close="showPatchFeedback = false" :selectedTask=task @onpatched="onpatched" :task=task />
        
        <div class="quickview" :class="{'is-active': task != false}">
            <header class="quickview-header">
                <p class="title">Quickview Task</p>
                <span class="delete" @click="$emit('close')" data-dismiss="quickview"></span>
            </header>

            <div class="quickview-body">
                <div class="tabs is-boxed is-fullwidth">
                    <ul>
                        <li :class="{'is-active' : activeTab == 0}"><a @click="activeTab = 0">Summary</a></li>
                        <li :class="{'is-active' : activeTab == 1}"><a @click="activeTab = 1">Comments &nbsp; <span class="tag is-dark">{{ comments.length }}</span></a></li>
                    </ul>
                </div>
                <div class="quickview-block" v-if="activeTab == 0">
                    <div class="card">
                        <div class="column">
                            <network-diagram-upload :resource="task" />
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <p class="card-header-title">PROCESSOR UPDATES</p>
                        </div>
                    </div>
                    <div class="card" v-for="(value,key) in (feedback || { })" :key="`update.${key}`">
                        <div class="card-content" >
                            <span class="heading">{{key}}</span>
                            <p>{{ mapValue(key, value) }}</p>
                        </div>
                    </div>
                    <div class="card" v-if="!task.feedback">
                        <div class="card-content has-text-grey-light" >
                            <i style=" is-size-7">No feedback yet</i>
                        </div>
                    </div>
                    
                    <div class="card">
                        <div class="card-header">
                            <p class="card-header-title">TASK DETAILS</p>
                        </div>
                    </div>
                    <div class="card" v-for="(value,key) in details" :key="key">
                        <div class="card-content" >
                            <span class="heading">{{key}}</span>
                            <p>{{ mapValue(key, value) }}</p>
                        </div>
                    </div>
                    <feature-flag scope="developer" :noDev="true">
                        <pre>{{ {PK: task.PK , SK: task.SK} }}</pre>
                    </feature-flag>
                    
                </div>

                <div class="quickview-block" v-if="activeTab == 1">
                    <div v-if="loading" class="level">
                        <div class="level-item">
                            <span class="button is-text is-loading"></span>
                        </div>
                    </div>
                    <div v-else>
                        <div v-if="comments.length == 0">
                            <div class="level">
                                <div class="level-item" style="padding-top: 20%;">
                                    <span class="title has-text-grey-lighter">No comment yet</span>
                                </div>
                            </div>
                        </div>
                        <div class="card-content">
                            <div class="card animated fadeInRight faster has-ribbon"  v-for="comment in comments" :key="comment.SK" style="margin-bottom: 20px;">
                                <div v-if="isBlocked(comment)" class="ribbon is-danger is-small">{{ comment.details.comment_type }}</div>
                                <div class="card-content">
                                    <div class="pre-formatted">{{ comment.details.message }}</div>
                                    <div class="is-size-7 has-text-grey" style="margin-top:20px;">Posted by <span class="has-text-weight-bold">{{ comment.CreatedBy.name }} </span><span class="tooltip" :data-tooltip="comment.createdAt | dateFormat">{{ comment.createdAt | diffNow }}</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer v-if="activeTab == 1" class="quickview-footer ">
                <div class="column">
                    <div class="" v-if="showCommentForm">
                        <div class="level">
                            <div class="level-left">
                                <span class="title is-6">Add comment</span>
                            </div>
                            <div class="level-right">
                                <a @click="showCommentForm = false" class="delete "></a>
                            </div>
                        </div>
                        <div class="field">
                            <div class="control">
                                <textarea ref="comment" v-model="comment" name="comment" class="textarea" placeholder="Comment here" ></textarea>
                            </div>
                        </div>
                        <div class="field">
                            <div class="control">
                                <button v-if="!isSaving" @click="postComment" class="button is-fullwidth is-primary">Post comment</button>
                                <button v-else class="button is-fullwidth is-primary is-loading">Post comment</button>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <a @click="showCommentForm = true" class="button is-text">Add comment</a>
                    </div>
                </div>
            </footer>


            <footer v-if="activeTab == 0" class="quickview-footer ">
                <div>
                    
                    <div v-if="hasAction" class="dropdown is-up" :class="{
                        'is-active': showActions
                    }">
                        <div class="dropdown-trigger">
                            <button class="button" aria-haspopup="true" aria-controls="dropdown-menu" @click="showActions = !showActions">
                            <span>Quick action</span>
                            </button>
                        </div>
                        <div class="dropdown-menu" id="dropdown-menu" role="menu" @click="showActions = false">
                            <div class="dropdown-content">
                                <a v-if="canBlock" @click="markAsBlocked" class="dropdown-item">
                                    Mark as blocked
                                </a>
                                 <a v-if="canCancel" @click="showCancellation = true" class="dropdown-item">
                                    Sales request to cancel
                                </a>

                                <a v-if="canReraise" @click="handleShowReraise" class="dropdown-item">
                                    Reraise Order
                                </a>

                                <a v-if="canUpdateFeedback" @click="showPatchFeedback = true" class="dropdown-item">Patch feedback</a>
                            </div>
                        </div>
                        </div>
                </div>
            </footer>
        </div>
    </div>
</template>


<script>
import * as R from 'ramda'
import moment from 'moment'
import Blockage from './Tasks/Blockage.vue'
import Cancellation from './Tasks/Cancellation.vue'
import NetworkDiagramUpload from './NetworkDiagramUpload.vue'
import TaskStates from './../domain/TaskStates.js'
import PatchFeedback from './Tasks/PatchFeedback.vue'

export default {
    components:{
        NetworkDiagramUpload,
        Blockage,
        Cancellation,
        PatchFeedback,
    },
    props:['task'],
    data(){
        return {
            activeTab: 0,
            comments: [],
            loading: true,
            comment: '',
            showCommentForm: false,
            isSaving: false,
            showActions: false,
            showBlockage: false,
            showCancellation: false,
            showReraise: false,
            showPatchFeedback: false,
            patched: false,
        }
    },
    watch:{
        task(value){
            this.loadComments(),
            this.comment = ''
            this.activeTab = 0
        },
        showCommentForm(value){
            if(value){
                this.comment = '';
                this.$nextTick(() => {
                    this.$refs.comment.focus()
                })
            }
        }
    },
    computed:{
        details(){
            return this.$store.getters.om_taskDetails(this.task.details)  
        },
        canBlock(){
            if(!this.task){
                return false
            }
            
            return this.$store.getters.om_role(['supervisors','processors', 'executives']) && TaskStates(this.task).canTransTo('BLOCKED')
        },
        canCancel(){
            if(!this.task){
                return false
            }
            
            return this.$store.getters.om_role(['supervisors','processors', 'executives']) && (TaskStates(this.task).canTransTo('CANCELLED') || TaskStates(this.task).canTransTo('CANCEL_SUBMITTED'))
        },
        canReraise(){
            if(!this.task){
                return false
            }

            return this.$store.getters.om_role(['processors']) && (TaskStates(this.task).canTransTo('RERAISED'))
        },
        canUpdateFeedback(){
            return this.$store.getters.om_canPatchFeedback(this.task)
        },
        hasAction(){
            return this.canBlock || this.canCancel || this.canReraise || this.canUpdateFeedback
        },
        feedback(){
            if(this.patched){
                return  R.omit(['logs'],this.patched.feedback)
            }

            return R.omit(['logs'],this.task.feedback)
        }
    },
    methods:{
        handleUpdateFeedback(){
            this.$router.push(`/tasks/${this.task.TaskID}/patch`)
        },
        handleShowReraise(){
            this.$router.push(`/tasks/${this.task.TaskID}`)
        },
        onpatched(data){
            this.showPatchFeedback = false
            this.patched = data
            
            this.$store.dispatch('om.comments.post', {
                PK: `${this.task.PK}.${this.task.SK}`,
                type: 'Feedback Patched',
                message: `Order no and service no patched.

Old order no : ${data.feedback.old_order_no}
New order no : ${data.feedback.order_no}

Old service ID : ${data.feedback.old_service_id}
New service ID : ${data.feedback.service_id}
`
            }).then(() => {
                this.loadComments(true)
            })

            alert('Data patched')


        },
        oncancelled(){
            this.$emit('blocked')
            this.$emit('close')
        },

        markAsBlocked(){
            this.showBlockage = true
        },

        onblocked(){
            this.$emit('blocked')
            this.$emit('close')
        },

        loadComments(noPreloader){
            if(!noPreloader){
                this.loading = true
            }
            if(!this.task.PK || !this.task.SK){
                return;
            }
            
            this.$store.dispatch('om.comments.get', `${this.task.PK}.${this.task.SK}`).then(comments => {
                this.loading = false
                this.comments = comments
            })
        },
        postComment(){
            this.isSaving = true
            this.$store.dispatch('om.comments.post', {
                PK: `${this.task.PK}.${this.task.SK}`,
                message: this.comment.trim()
            }).then(() => {
                this.isSaving = false
                this.showCommentForm = false
                this.loadComments(true)
            })
        },
        isBlocked(comment){
            return comment.details.comment_type && comment.details.comment_type != 'notes'
        },
        mapValue(key,value){
            const mappers = {
                project: (value) => {
                    return value.id
                },
                TaskID: (value) => {
                    return R.take(12,value)
                }
            }

            const mapper = mappers[key] || R.identity
            return mapper(value)
        }
    },
    filters: {
        diffNow(value){
            return moment(value).fromNow()
        },
        dateFormat(value){
            return moment(value).format('DD MMM YYYY, hh:mm A')
        }
    }
}
</script>

<style>
.quickview-block *{
    word-wrap: break-word;
}

.pre-formatted{
  white-space: pre-wrap;
}

</style>


<template>
    <div>
        <feature-flag scope="developer">
            <div class="is-pulled-right">
                <div class="popover is-popover-top " :class="{'is-popover-active': show}">
                    <span @click="show = !show" class="is-small is-text">
                        <i>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12" y2="8"></line></svg>
                        </i>
                    </span>
                    <div class="popover-content">
                        <pre>{{ this.content }}</pre>
                    </div>
                </div>
            </div>
        </feature-flag>
    </div>
</template>


<script>
import FeatureFlag from './../Mobile/webcomponent/common/FeatureFlag'

export default {
    props: ['content'],
    components:{
        FeatureFlag,
    },
    data(){
        return {
            show: false
        }
    }
}
</script>

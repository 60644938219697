<template>
    <div class="card">
        <div class="card-content">
            <div class="field" >
                <div class="file is-centered is-boxed is-fullwidth" @dragover.prevent="handleDropover" @drop.prevent="handleFileChange" @dragleave="handleDropleaves" :class="{
                        'dropzone is-info': dropzoneStatus == 'over',
                        'is-success': dropzoneStatus == 'uploaded',
                        'is-danger': dropzoneStatus == 'error'
                    }" >
                    <label class="file-label has-text-grey">
                    <input class="file-inputx" type="file" name="resume" @change="handleFileChange">
                    <span class="file-cta" style="min-height:160px;">
                        
                        <svg v-if="!dropzoneStatus" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 492.308 492.308" style="fill:currentColor" width="64">
                            <path d="M246.154 161.913L142.885 273.308l14.442 13.384 78.981-85.196V411.5H256V201.495l78.986 85.197 14.442-13.384z"/>
                            <path d="M242.168 63.726L181.447 0H0v492.308h492.308V63.726h-250.14zm230.447 408.889H19.692V19.692h153.322l60.721 63.726h238.88v389.197z"/>
                        </svg>

                        <svg v-if="dropzoneStatus == 'over'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 492.308 492.308" style="fill:currentColor" width="64">
                            <path d="M332.298 65.827L215.106 184.332 160 128.615l-14 13.847 69.106 69.87L346.298 79.673z"/>
                            <path d="M400.808 0H91.5L.567 257.365 0 258.952v233.356h492.308V258.957L400.808 0zM105.423 19.692h281.462l81.656 231.101H304.442v9.846c0 32.558-26.144 59.048-58.288 59.048s-58.288-26.49-58.288-59.048v-9.846H23.769l81.654-231.101zm367.192 452.923H19.692v-39.413h452.923v39.413zm0-59.105H19.692V270.486h149.087c4.817 38.788 37.673 68.894 77.375 68.894s72.558-30.106 77.375-68.894h149.087V413.51z"/>
                        </svg>
                    
                        <span v-if="dropzoneStatus == 'uploading'" class="button is-loading is-fullwidth is-text"></span>
                        
                        <span v-if="dropzoneStatus == 'error'">
                            <svg class="animated rollIn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="64" style="fill:currentColor">
                                <circle cx="15.5" cy="9.5" r="1.5"/>
                                <circle cx="8.5" cy="9.5" r="1.5"/>
                                <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm0-6c-2.33 0-4.32 1.45-5.12 3.5h1.67c.69-1.19 1.97-2 3.45-2s2.75.81 3.45 2h1.67c-.8-2.05-2.79-3.5-5.12-3.5z"/>
                            </svg>
                        </span>

                        <span v-if="dropzoneStatus == 'uploaded'">
                            <svg class="animated bounceIn faster" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="64" style="fill:currentColor;">
                                <path d="M14.72 8.79l-4.29 4.3-1.65-1.65a1 1 0 1 0-1.41 1.41l2.35 2.36a1 1 0 0 0 .71.29 1 1 0 0 0 .7-.29l5-5a1 1 0 0 0 0-1.42 1 1 0 0 0-1.41 0zM12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z"/>
                            </svg>
                        </span>
                        
                        <span  class="file-label has-text-centered" style="margin-top:10px;">
                            <span v-if="!dropzoneStatus">Upload or Drop <br>Order template file here</span>
                            <span v-if="dropzoneStatus == 'over'">Drop file here</span>
                            <span v-if="dropzoneStatus == 'uploading'">Uploading...</span>
                            <span v-if="dropzoneStatus == 'uploaded'">Uploaded</span>
                            <span v-if="dropzoneStatus == 'error'">{{ message }}</span>
                        </span>
                    </span>
                    
                    </label>

                </div>
            </div>

        </div>
        <div class="card-footer">
            <a @click="downloadTemplate" class="card-footer-item">
                Download template file (ver {{ tpl.version }})
            </a>
        </div>
    </div>
</template>


<script>
import * as R from 'ramda'

const delay = (dur) => {
    return new Promise((done) => {
        setTimeout(done, dur)
    })
}
export default {
    data(){
        return {
            dropzoneStatus: false,
            file: false,
            filename: false,
            message: ''
        }
    },
    computed:{
        tpl(){
            if(!this.$store.state.om.configs.template){
                return {
                    version: 0,
                    file: {
                        details: {}
                    }
                }
            }
            return {
                version: this.$store.state.om.configs.template.version,
                file: { 
                    SK:'attachment.template.template.config'  , 
                    details:{
                        filename: this.$store.state.om.configs['attachment.template.template.config'].filename
                    }
                }
            }
        }
    },
    methods:{
        async downloadTemplate(){
            const url = await this.$store.dispatch('om.attachments.download', this.tpl.file)
            window.open(url, "_BLANK")
        },
        handleDropover(){
            this.dropzoneStatus = 'over';
        },
        handleFileChange(ev){
            console.log("drop!")
            ev.stopPropagation()
            ev.preventDefault()

            if (ev.dataTransfer && ev.dataTransfer.files) {
                this.file = R.head(ev.dataTransfer.files)
                this.filename = this.file.name
            }

            if(!ev.dataTransfer && ev.target && ev.target.files ){
                this.file = R.head(ev.target.files)
                this.filename = this.file.name 
            }
            
            this.dropzoneStatus = false;
            this.dropzoneStatus = "uploading"
            this.$store.dispatch('om.dropfile.drop', {
                file: this.file
            }).then(() => {
                this.dropzoneStatus = 'uploaded'
                this.$emit('uploaded', this.file)
                return delay(1000)
            }).then(() => {
                this.dropzoneStatus = false
            }).catch(err => {
                this.message = `${err}`
                this.dropzoneStatus = 'error'
                return delay(2000).then(()=> {
                    this.dropzoneStatus = false
                })
                
            })
        },
        handleDropleaves(){
            this.dropzoneStatus = false;
        }

    },
}
</script>


<style>
.file-inputx{
    display: block;
    border: solid 2px red;
    height:100%;
    position: absolute;
    width:100%;
    opacity: 0;
}
</style>
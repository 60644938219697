<template>
    <div>
        <a @click="showModal = true" class="button is-small is-danger is-outlined is-fullwidth">Reject</a>

        <div class="modal is-active" v-if="showModal">
            <div class="modal-background"></div>
            <div class="modal-content">
                <div class="card">
                    <div class="card-content">
                        <form @submit.prevent="handleReject">
                            <div class="field">
                                <div class="control">
                                    <textarea v-model="reason" name="reason" v-validate="'required'" class="textarea" :class="{'is-danger': errors.has('reason')}" placeholder="Reason for rejection"></textarea>
                                </div>
                            </div>
                            <div class="field">
                                <div class="control">
                                    <div class="buttons">
                                        <button type="submit" class="button is-danger">Reject</button>
                                        <a @click="showModal = false" class="button is-text ">Cancel</a>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <button class="modal-close is-large" aria-label="close" @click="showModal = false"></button>
        </div>
    </div>
</template>


<script>
export default {
    props: ['file'],
    data(){
        return {
            showModal: false,
            reason: ''
        }
    },
    methods:{
        handleReject(){
            this.$validator.validateAll().then(passed => {
                if(!passed){
                    return;
                }

                this.$emit('reject', {
                    drop_id: this.file.drop_id,
                    reason: this.reason
                })
                this.showModal = false
            })
        }
    }
}
</script>

<template>
    <div class="field has-addons ">
        <div class="control">
            <span class="button is-static is-small">Sort </span>
        </div>
        <div class="control">
            <div class="select is-small">
                <select v-model="sortKey">
                    <option v-for="(field,i) in sortfields" :value="i" :key="i">{{ field }}</option>
                </select>
            </div>
        </div>
        <div class="control">
            <a v-if="sortBy == 'ascend'" @click="sort" class="button is-small">
                <span class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M17 11l-5-5-5 5M17 18l-5-5-5 5"/></svg>
                </span>
            </a>
            <a v-if="sortBy == 'descend'" @click="sort" class="button is-small">
                <span class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M7 13l5 5 5-5M7 6l5 5 5-5"/></svg>
                </span>
            </a>

        </div>
    </div>
</template>

<script>
import * as R from 'ramda'

export default {
    props: ['data', 'sortfields', 'defaultSort'],
    data(){
        return {
            sortBy: 'descend',
            sortKey: 'updatedAt'
        }
    },
    computed:{
        sortedPath(){
            return this.sortKey.split('.')
        },
        sortedData(){
            return R.sort(R[this.sortBy](R.view(R.lensPath(this.sortedPath)|| 99999)),this.data)
        },
        // sortFields(){
        //     return {
        //         'updatedAt': 'Update At',
        //         'details.TaskID': 'Task ID'
        //     }
        // }
    },
    mounted(){
        if(this.defaultSort){
            this.sortKey = this.defaultSort
        }
        this.updateData()
    },
    methods:{
        sort(){
            this.sortBy = this.sortBy == 'ascend' ? 'descend' : 'ascend'
        },
        updateData(){
            this.$emit('sorted',this.sortedData)
        }
    },
    watch:{
        sortedData(value){
            this.updateData()
        }
    }
}
</script>


<template>
    <div class="card-content">
        <div v-if="jobsheet && project" >
            <div class="level" :class="{'is-quickview': task}">
                <div>
                    <router-link :to="`/tasks/projects/${project.PK}`" class="heading"> &laquo; {{project.name}} Project</router-link>
                    <h1 class="title is-5 has-text-grey">{{ jobsheet.PK | shortKey }}</h1>
                    <span class="heading">{{ jobsheet.createdAt | formatDate}}</span>
                </div>
                <div>
                    <div class="field">
                        <input id="switchRoundedDefault" type="checkbox" name="switchRoundedDefault" class="switch is-rounded  is-rtl is-small"  v-model="show_all">
                        <label for="switchRoundedDefault" class="is-size-7" >Show all tasks</label>
                    </div>
                </div>
            </div>
            <div class="tabs is-boxed">
                <ul>
                    <li :class="{'is-active': tab == 'tasks'}">
                        <a @click="tab = 'tasks'">
                            <span>Tasks</span>
                        </a>
                    </li>
                    <li :class="{'is-active': tab == 'distributions'}">
                        <a @click="tab = 'distributions'">
                            <span>All Distributions</span>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="columns" :class="{'is-quickview': task}">
                <div class="column">
                    <div v-if="tab == 'distributions'">
                        <div class="card" style="margin-bottom: 50px;">
                            <table class="table is-fullwidth is-hoverable">
                                <thead>
                                    <tr>
                                        <th>Staff ID</th>
                                        <th>Name</th>
                                        <th>Total tasks</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr @click="selectedDistribution = staff_id" v-for="(distribution, staff_id) in distributions" :key="staff_id" :class="{
                                        'is-selected': selectedDistribution == staff_id
                                    }">
                                        <td>{{ staff_id }}</td>
                                        <td>{{ getProcessorName(staff_id)}}</td>
                                        <td>{{ (distribution||[]).length}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <span class="help" style="padding:10px;">Showing <b>{{filteredTasks.length}}/{{ totalTasks }}</b> tasks</span>
                    <div class="card" v-if="filteredTasks.length > 0">
                        <table-task :filteredTasks=filteredTasks :getProjectName=getProjectName :isViewed="noop" :selectedSessionTasks=noop :getProcessorName=getProcessorName :viewTask=viewTask />
                    </div>
                    <div class="card card-content" v-if="filteredTasks.length == 0 && tab == 'tasks'">
                        No task assigned to you
                    </div>
                </div>
                
                <quickview-task :task="task" @close="task = false" @blocked="loadData"/>
            </div>
        </div>
    </div>
</template>


<script>

import * as R from 'ramda'
import moment from 'moment'
import QuickviewTask from './../QuickviewTask.vue'
import AttachmentTag from './../AttachmentTag.vue'
import TableTask from './TableTask.vue'

export default {
    components:{
        QuickviewTask,
        AttachmentTag,
        TableTask,
    },
    data(){
        return {
            tab: 'tasks',
            task: false,
            show_all: false,
            selected_tasks: {},
            processor: false,
            isLoading: false,
            selectedDistribution: false,
        }
    },
    computed:{
        me(){
            return this.$store.state.login.user
        }, 
        project_id(){
            return this.$route.params.project_id
        },
        project(){
            return this.$store.state.om.projects[this.project_id]
        },
        jobsheet_id(){
            return this.$route.params.jobsheet_id
        },
        jobsheets(){
            return this.$store.state.om.jobs
        },
        jobsheet(){
            return this.jobsheets[this.jobsheet_id]
        },
        totalTasks(){
            return this.jobsheet.tasks.length
        },
        filteredTasks(){
            if(this.selectedDistribution && this.tab == 'distributions'){
                return this.distributions[this.selectedDistribution]
            }

            const filter = (this.show_all) ? R.identity : R.filter( task => task.GS1 == this.me.staff_id)

            if(this.tab == 'tasks'){
                return R.pipe(
                    R.sortBy(R.prop('TaskID')),
                    filter,
                )(this.jobsheet.tasks || [])
            }

            return []
        },
        total_selected(){
            return R.pipe(R.values,R.sum)(this.selected_tasks)
        },
        processors(){
            return this.$store.state.om.processors
        },
        processorsMapping(){
            return R.indexBy(R.prop('staff_id'), this.processors)
        },
        distributions(){
            return R.pipe(
                R.filter(task => task.Status != 'UNASSIGNED'),
                R.groupBy(R.prop('GS1'))
            )(this.jobsheet.tasks)
        }
    },
    methods:{
        noop(){
            
        },
        getProjectName(project_id){
            return this.$store.state.om.projects[project_id].name
        },
        getProcessorName(staff_id){
            return (this.processorsMapping[staff_id] || { name: ''}).name 
        },
        handleAssign(){
            if(this.isLoading){
                return
            }

            this.isLoading  = true
            this.$store.dispatch('om.processor.assign', {
                processor: this.processor,
                tasks: this.jobsheet.tasks.filter(task => this.selected_tasks[task.TaskID])
            }).then(() => {
                this.isLoading  = false
                this.loadData()
                this.selected_tasks = {}
                this.processor = ''
            })
        },
        selectAll(){
            this.$set(this, 'selected_tasks', R.pipe(
                R.indexBy(R.prop('TaskID')),
                R.map(() => true)
            )(this.filteredTasks))
        },
        clearSelection(){
            this.selected_tasks = {}  
        },
        isSelected(task){
            return this.show_all && this.selected_tasks[task.TaskID]
        },
        loadData(){
            return Promise.all([
                this.$store.dispatch('om.project.load', this.project_id),
                this.$store.dispatch('om.jobsheet.load', this.jobsheet_id)
            ]).then(() => {
                this.$store.dispatch('isLoaded')
            })
        },
        viewTask(task){
            this.task = task
        }
    },
    mounted(){
        this.loadData()
    },
    filters:{
        shortKey(value){
            return R.take(12, value)
        },
        formatDate(value){
            return moment(value).format('DD MMM YYYY')
        }
    }
}
</script>

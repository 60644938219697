<template>
    <div>
        <div v-if="$route.meta.blank">
            <slot />
        </div>
        <div v-else>
            <div class="hero is-fullheight is-dark " style="position:fixed; width:50px; z-index:2; background:#003399">
                <sidebar-menu  />
            </div>
            <div style="padding-left:50px; padding-top:50px;">
                <div class="columns is-gapless">
                    <div class="column">
                        <navbar @logout="$emit('logout')" />
                        <div>
                            <slot></slot>
                        </div>
                    </div>
                </div>
            </div>
        </div>     
    </div>
</template>


<script>
import Navbar from './Navbar.vue'
import SidebarMenu from './SidebarMenu.vue'
export default {
    components: {
        Navbar,
        SidebarMenu,
    }
}
</script>



<style lang="scss">
.sidebarmenu .menu-list .router-link-active{
    background-color:#00000033;

}

.sidebarmenu .menu-list  .navbar-item:hover{
    background-color:#00000033 !important;
}

.navbar-item.router-link-active svg{
    fill: #fff;//hsl(0, 0%, 29%) !important
}

.sidebarmenu .menu-list  .navbar-item svg{
    fill: #fff
}

</style>

<template>
    <div class="section">
        <div v-if="dropfile" class="">
            <div class="columns">
                <div class="column is-offset-2">
                    <div class="level">
                        <div class="level-left">
                            <h1 class="title is-4">{{ dropfile.TaskID }}</h1>
                        </div>
                        <div class="level-end">
                            <span class="tag" :class="{
                                'is-dark': dropfile.Status == 'OPEN',
                                'is-info': dropfile.Status == 'UPLOADED',
                                'is-danger tooltip is-tooltip-multiline is-preline': dropfile.Status == 'REJECTED',
                                'is-success': dropfile.Status == 'TASK_COMPLETED',
                            }" :data-tooltip="getRejectionReason(dropfile)" >{{ dropfile.Status }}</span>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="columns">
                <div class="column is-2">
                    <div class="cards" v-if="dropfile">
                        <div class="card-content">
                            <request-progress :dropfile=dropfile :jobsheet=jobsheet />
                            <hr>
                            <div v-if="dropfile.Status != 'UPLOADED' && dropfile.Status != 'TASK_COMPLETED'" @click="removeFile(drop_id)" class="button is-danger is-fullwidth">
                                Delete
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column is-10">
                    <div class="card" v-if="dropfile.Status != 'REJECTED'">
                        <table-task  :filteredTasks="tasks" :getProjectName=getProjectName :isViewed=isViewed :selectedSessionTasks=noop :getProcessorName=getProcessorName :viewTask=viewTask />
                    </div>
                    <div v-else class="">
                        <div class="notification">
                            <span class="title is-4">REJECTED</span>
                            <p>Reason:</p>
                            <p class="pre-formatted">{{ dropfile.details.rejection.reason }}</p>
                        </div>
                    </div>
                </div>

                
            </div>
        </div>
        <quickview-task :task="task" @close="task = false" />
    </div>
</template>


<script>
import * as R from 'ramda'
import TableTask from './../Tasks/TableTask.vue'
import QuickviewTask from './../QuickviewTask.vue'
import RequestProgress from './RequestProgress.vue'

export default {
    components:{
        TableTask,
        QuickviewTask,
        RequestProgress,
    },
    data(){
        return {
            task: false,
            users: []
        }
    },
    mounted(){
        this.loadData()
    },
    methods:{
        loaded(){
            this.$store.dispatch('isLoaded')
        },
        removeFile(drop_id){
            if(!confirm("Confirm to delete the file?")){
                return 
            }
            
            this.$store.dispatch('om.dropfile.remove', { drop_id }).then(() => {
                this.$router.push('/')
            })
        },
        loadData(){
            this.$store.dispatch('om.processors.list').then(users => {
                this.users = users
            })

            this.$store.dispatch('om.jobsheet.load', this.drop_id).then(() => {
                this.loaded()
            })

            this.$store.dispatch('om.dropfile.find', this.drop_id)
        },
        noop(){

        },
        isViewed(task){
            return this.task == task
        },
        getProjectName(project_id){
            return this.$store.state.om.projects[project_id].name
        },
        viewTask(task){
            this.task = task
        },
        getProcessorName(id){
            return R.pipe(R.find(data => data.staff_id == id), data =>  (data|| {}).name)(this.users)
        },
        getRejectionReason(file){
            if(file.Status != 'REJECTED'){
                return ''
            }
            return `${file.details.rejection.reason}

-${file.details.rejection.rejectedBy.name}-`
        },
    },
    computed:{
        drop_id(){
            return this.$route.params.PK
        },
        job(){
            return this.$store.state.om.jobs[this.drop_id]
        },
        jobsheet(){
            if(!this.job){
                this.$store.dispatch('om.jobsheet.load', this.drop_id)
                return false
            }
            return this.job
        },
        tasks(){
            return this.jobsheet.tasks || []
        },
        dropfile(){
            return this.$store.state.om.dropfile.files[this.drop_id]
        }
    }
}
</script>
<template>
    <div ref="mainwin">
        <div id="review-box" class="tags has-addons is-marginless is-paddingless animated fadeInUp faster" v-if="showFloat">
            <div class="tag is-dark">
                Record
            </div>
            <div class="tag is-info">
                {{ rid + 1 }} /  {{ records.length }}
            </div>
        </div>
        <div class="columns">
            <div class="column is-3">
                <h1 class="title is-6">SHEET SUMMARY</h1>
                <div class="content">
                    <p>
                        Total Records: {{ records.length }} <br>
                        Reviewed: {{  total_reviewed }}
                    </p>
                </div> 

                <SelectDropfile @selected="dropfileSelected" :jobsheet_id='jobsheet_id' />
                
                <a @click="handleAccept" class="button is-primary is-fullwidth" :class="{'is-loading': isLoading}" :disabled="(total_reviewed != records.length && !bypass) || !drop_id">
                    ACCEPT JOB SHEET
                </a>

                <label><input type="checkbox" v-model="bypass"> I have review and verified all records are correct</label>

                <div style="margin-top:20px;">
                <button @click="$emit('reject')" class="button is-small is-fullwidth">Re-upload Job Sheet</button>

                </div>
            </div>
            <div class="column">
                <nav class="pagination is-small" role="navigation" aria-label="pagination">
                <ul class="pagination-list">
                    <li><a @click="prevRecord" class="pagination-link">&laquo;</a></li>
                    <li v-for="(record, i) in records" :key="i" >
                        <a @click="rid = i" :class="{ 'is-current': i == rid  ,'has-background-warning': !checkHash(record), 'has-background-success' : isVerified[i] }" class="pagination-link" >{{ i + 1 }}</a>
                    </li>
                    <li><a @click="nextRecord" class="pagination-link">&raquo;</a></li>
                </ul>
                </nav>
                <hr>
                <div class="level">
                    <div class="level-start">
                        <div class="level-item">
                            <h2 class="title is-6">RECORD #{{rid + 1}}</h2>
                        </div>
                    </div>
                    <div class="level-end">
                        <div class="level-item">
                            <div class="field">
                                <div class="control">
                                    <label class="button is-rounded" :class="{'is-success': isVerified[rid]}">
                                        <input v-show="false" type="checkbox" v-model="isVerified[rid]"> 
                                        <span class="icon is-small">
                                            <svg v-if="isVerified[rid]" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" style="fill:currentColor;">
                                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/>
                                            </svg>
                                        </span>

                                        <span>Record Verified </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="card">
                    <table class="table is-fullwidth">
                        <tbody>
                            <tr :class="invalidRow(key,value)" v-for="(value,key) in displayedRecord" :key="key">
                                <th width="120">{{ key }}</th>
                                <td>{{ value }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div> 
            </div>
        </div>
        
    </div>    
</template>


<script>
import * as R from 'ramda'
import SelectDropfile from './SelectDropfile.vue'
export default {
    props: ['records','jobsheet_id'],
    components:{
        SelectDropfile
    },
    data(){
        return {
            rid: 0,
            isVerified: {

            },
            bypass: false,
            drop_id: false,
            isLoading: false,
        }
    },

    computed:{
        showFloat(){
            return this.scrollY > 100
        },
        scrollY(){
            return this.$store.state.scrollY
        },
        currentRecord(){
            return (this.records[this.rid] || {})
        },
        total_reviewed(){
            return R.pipe(R.values, R.sum)(this.isVerified)
        },
        noPrevRecord(){
            return this.rid == 0
        },
        noNextRecord(){
            return this.rid >= this.records.length - 1
        },
        dropfiles(){
            return this.$store.state.om.dropfile.files
        },
        currentRecordTemplate(){
            const fields = this.$store.getters.om_taskTemplate(this.currentRecord.RequestType)
            return R.pipe(
                R.indexBy(R.identity),
                R.map(() => '<MISSING>')
            )(fields)
        },
        displayedRecord(){
            return R.mergeAll([this.currentRecordTemplate, this.currentRecord])
        }
    },

    watch:{
        rid(){
            window.scrollTo(0, 0);
        }
    },

    mounted(){
        this.loadDropfiles()
        window.addEventListener('keyup', this.handleKeyevent)
    },
    beforeDestroy(){
        window.removeEventListener('keyup', this.handleKeyevent)
    },

    methods: {
        invalidRow(key,value){
            const excludedKeys = [
                'CustomerName',
                'TaskID',
                'RequestType'
            ]

            if(excludedKeys.indexOf(key) > -1){
                return "ok"
            }

            if(value == '<MISSING>'){
                return "has-background-warning"
            }

            if(!this.currentRecordTemplate[key]){
                return "has-background-warning"
            }

            return "ok"
        },
        checkHash(currentRecord){
            return this.$store.getters.om_taskCheck({TaskID:currentRecord.TaskID  , details: currentRecord})
        },  
        handleKeyevent(ev){
            if(ev.keyCode == 39){
                this.nextRecord()
            }

            if(ev.keyCode == 37){
                this.prevRecord()
            }
        },
        dropfileSelected(drop_id){
            this.drop_id = drop_id
        },
        loadDropfiles(){
            this.$store.dispatch('om.dropfile.all')
        },
        verified(id){
            this.$set(this.isVerified, id, true)
        },
        prevRecord(){
            this.rid--
            if(this.rid < 0){
                this.rid = 0
            }
        },
        nextRecord(){
            this.rid++
            if(this.rid >= this.records.length){
                this.rid = this.records.length - 1 
            }
        },
        handleAccept(){
            if((this.total_reviewed == this.records.length || this.bypass) && this.drop_id){
                this.isLoading = true
                this.$emit('accept', this.drop_id)
            }
        }
    },

    filters:{
        shortKey(value){
            return R.take(12,value)
        }
    }
}
</script>


<style scoped>
#review-box{
    position: fixed !important;
    bottom: 20px;
    right: 20px;
    z-index: 999;
}
</style>

<template>
    <div>
        <div class="timeline ">
            <header class="timeline-header">
                <div class="timeline-marker"></div>
                <span class="tag is-small is-success">Start</span>
            </header>
            <div v-for="(step,i) in steps" :key="i" >
                <div v-if="!step.isTag" class="timeline-item">
                    <div class="timeline-marker" :class="{
                        [step.class || 'is-success']: step.time
                    }"></div>
                    <div class="timeline-content">
                        <p :class="{
                            'tooltip is-tooltip-right': step.tooltip
                        }" :data-tooltip="step.tooltip">{{ step.label }}</p>
                        <p v-if="step.time" class="help tooltip is-tooltip-right" :data-tooltip="step.time | formatDate ">{{ step.time | fromNow }}</p>
                        <p v-else class="help">In progress</p>
                    </div>
                </div>
                <header v-else class="timeline-header" :class="{
                            'tooltip is-tooltip-right': step.tooltip
                        }" :data-tooltip="step.tooltip">
                    <div class="timeline-marker" ></div>
                    <span class="tag is-small" :class="{
                        [step.class || 'is-success']: step.time
                    }">{{ step.label }}</span>
                </header>
            </div>
            
        </div>

    </div>
</template>


<script>
import * as R from 'ramda'
import moment from 'moment'
export default {
    props:['dropfile','jobsheet'],

   
    computed:{
        jobsheetUploaded(){
            return this.jobsheet.tasks
        },
        tasks(){
            return this.jobsheet.tasks || []
        },
        totalTask(){
            return this.tasks.length
        },
        unassignedTasks(){
            return this.tasks.filter(data => data.Status == 'UNASSIGNED')
        },
        openTasks(){
            const isOpen = task => ['UNASSIGNED','ASSIGNED','BLOCKED'].indexOf(task.Status) > -1
            return this.tasks.filter(isOpen)
        },
        allAssigned(){
            return this.unassignedTasks.length == 0
        },
        lastTaskDistribution(){
            return R.pipe(R.sort(R.descend(R.prop('updatedAt'))),R.head, R.prop('updatedAt'))(this.tasks)
        },
        steps(){
            if(!this.dropfile){
                return []
            }

            let steps = [
                {
                    label: 'Email dropped',
                    tooltip: this.dropfile.details.filename,
                    time: this.dropfile.createdAt
                }
            ];

            if(this.dropfile.Status == 'REJECTED'){
                steps.push({
                    label: 'Rejected',
                    tooltip: this.dropfile.details.rejection.reason,
                    time: this.dropfile.updatedAt,
                    class: 'is-danger'
                })

                steps.push({
                    label: 'REJECTED',
                    isTag: true,
                    class:'is-danger',
                    time: this.dropfile.updatedAt,
                })

                return steps;
            }

            if(!this.jobsheetUploaded){
                steps.push({
                    label: 'Pending review'
                })

                return steps;
            }

            steps.push({
                label: 'Jobsheet Uploaded',
                tooltip: `${this.jobsheet.tasks.length} task uploaded`,
                time: this.jobsheet.createdAt
            })

            // all assigned?
            if(!this.allAssigned){
                steps.push({
                    label: 'Pending distribution',
                    tooltip: `${this.tasks.length - this.unassignedTasks.length}/${this.tasks.length} assigned`
                })

                return steps;
            }

            steps.push({
                label: 'Task distributed',
                tooltip: `${this.jobsheet.tasks.length} orders distributed`,
                time: this.lastTaskDistribution
            })
            
            if(this.openTasks.length > 0){
                steps.push({
                    label: 'Ordering in progress',
                    tooltip: `${this.openTasks.length}/${this.totalTask} tasks still in progress`
                })

                return steps;
            }

            steps.push({
                label: 'Tasks completed',
                tooltip: `${this.jobsheet.tasks.length} tasks completed`,
                time: this.jobsheet.updatedAt,
            })

            steps.push({
                label: 'Complete',
                isTag: true,
                time: this.jobsheet.updatedAt,
            })

            return steps;

            
        }
    },

    filters:{
        fromNow(value){
            return moment(value).fromNow()
        },
        formatDate(value){
            return moment(value).format('DD MMM YYYY, hh:mmA')
        },

    }
}
</script>


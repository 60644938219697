<template>
    <div style="height:150px; ">
        <div class="navbar is-fixed-bottom animated slideInUp faster" style="border-top: solid 1px #ccc; z-index:36;" >
            <div class="container">
                <div class="column">
                <div class="level">
                    <div class="level-left">
                        <div class="level-item">
                            <div><b>{{total_selected}}</b> items selected</div>
                        </div>
                        <div class="level-item">
                            <div class="buttons">
                                <a @click="selectAll" class="button is-text is-small">Select All</a>
                                <a @click="clearSelection" class="button is-text is-small">Clear all</a>
                            </div>
                        </div>
                    </div>
                    <div class="level-right">
                        <div class="level-item">
                            <a @click="showWorkload = true" class="button is-text">Workload distribution</a>
                        </div>
                        <div class="level-item">
                            <div class="select" placeholder="Select processor2">
                                <select v-model="processor_staff_id" name="processor" placeholder="Select processor">
                                    <option value="" disabled>Select processor</option>
                                    <option v-for="(processor) in activeProcessor" :key="processor.staff_id" :value="processor.staff_id">{{ processor.name }}</option>
                                </select>
                                
                            </div>
                        </div>
                        <div class="level-item">
                            <div class="buttons">
                                <a v-if="total_selected == 0 || !processor_staff_id" class="button is-primary" disabled>Assign</a>
                                <a v-else @click="handleAssign" class="button is-primary" :class="{'is-loading':isLoading}">Assign</a>
                                <a @click="$emit('close')" class="button is-text">Cancel</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>

        <div class="modal is-active" v-if="showWorkload">
            <div class="modal-background" @click="showWorkload = false"></div>
            <div class="modal-content">
                <div class="card">
                    <div class="card-content">
                        <workload @selected="handleWorkloadSelected" />
                    </div>
                </div>
            </div>
            <button class="modal-close is-large" aria-label="close" @click="showWorkload = false"></button>
        </div>
    </div>
</template>

<script>
import * as R from 'ramda'
import Workload from './Workload.vue'

export default {
    props: ['isLoading','total_selected','selectAll', 'clearSelection','processors'],
    components:{
        Workload,
    },
    data(){
        return {
            showWorkload: false,
            processor_staff_id: ''
        }
    },
    computed:{
        processor(){
            return  R.find(data => data.staff_id == this.processor_staff_id, this.processors)
        },
        activeProcessor(){
            return this.processors.filter(d => d.status === 'Active')
        }
    },
    methods:{
        handleWorkloadSelected(processor){
            this.processor_staff_id = processor.staff_id
            this.showWorkload = false
        },
        handleAssign(){
            return this.$emit('assign', this.processor)
        }
    }
}
</script>


<template>
    <div class="container">
        <div class="columns is-multiline">
            <div class="column is-3">
                <pending-tasks />
            </div>
            <div v-if="false" class="column is-7">
                <div class="columns">
                    <div class="column">
                        <order-perf title="Today Orders" :series="[1,3,1]" />
                    </div>
                    <div class="column">
                        <order-perf title="This Month Orders" :series="[12,23,3]" />
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <year-perf />
                    </div>
                </div>
            </div>
            <div class="column">
                <workload />
            </div>
        </div>  
    </div>
</template>

<script>
import PendingTasks from './../PendingTasks.vue'
import OrderPerf from './../OrderPerf.vue'
import YearPerf from './../YearPerf.vue'
import Workload from './../Workload.vue'

export default {
    components:{
        PendingTasks,
        OrderPerf,
        YearPerf,
        Workload,
    }
}
</script>

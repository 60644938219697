<template>
    <div class="card-content">
        <div >
            <div class="level" :class="{'is-quickview': task}">
                <div>
                    <h1 class="title is-5 has-text-grey">Unassigned Tasks</h1>
                </div>
                <div>
                    <div class="field is-grouped">
                        <div class="control">
                            <input id="switchRoundedDefault2" type="checkbox" name="switchRoundedDefault2" class="switch is-rounded  is-rtl" v-model="everyone">
                            <label for="switchRoundedDefault2" class="is-size-6">Everyone task</label>
                        </div>
												
												<div class="control">
                            <input id="switchRoundedDefault3" type="checkbox" name="switchRoundedDefault3" class="switch is-rounded  is-rtl" v-model="showBlocked">
                            <label for="switchRoundedDefault3" class="is-size-6">Blocked task</label>
                        </div>

                        <div class="control">
                            <input id="switchRoundedDefault" type="checkbox" name="switchRoundedDefault" class="switch is-rounded  is-rtl" v-model="assignment_mode">
                            <label for="switchRoundedDefault" class="is-size-6">Assign task</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="columns" :class="{'is-quickview': task}">
                <div class="column">
                    <div class="card" v-if="filteredTasks.length > 0">
                        <table-tasks defaultFilterType="all" @taskSelected="viewTask" :tasks="filteredTasks" :isSelected="isSelected" :processors="processorsMapping" :task="task" />
                    </div>

                    <div v-else class="columns is-centered">
                    <div class="column is-6 has-text-centered has-text-grey-lighter content " style="padding:40px;">
                        <p class="animated zoomIn faster">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 503.635 503.635" width="160" style="fill:currentColor;">
    <path d="M503.635 6.284l-150.846-.635-70.939 150.108c-9.755-1.774-19.744-2.858-29.994-2.858-10.273 0-20.286 1.087-30.062 2.868L150.837 5.649 0 6.284l107.797 228.103c-16.284 26.469-25.855 57.615-25.855 91.06 0 95.135 76.221 172.538 169.913 172.538s169.914-77.404 169.914-172.538c0-33.478-9.59-64.654-25.904-91.139L503.635 6.284zM365.231 25.399l107.385.442L382.873 215.7c-20.981-25.786-49.202-45.257-81.453-55.267l63.811-135.034zm-334.212.442l107.375-.442 63.825 135.052c-32.249 10.025-60.464 29.513-81.436 55.311L31.019 25.841zm371.058 299.606c0 84.279-67.385 152.846-150.221 152.846s-150.221-68.567-150.221-152.846c0-84.288 67.385-152.856 150.221-152.856s150.221 68.568 150.221 152.856z"/>
    <path d="M359.904 294.659l-72.519-13.827-35.529-66.173-35.519 66.173-72.529 13.827 50.663 54.49-9.5 74.587 66.885-32.24 66.885 32.24-9.5-74.587 50.663-54.49zM251.856 369.63l-42.769 20.625 6.077-47.76-32.683-35.164 46.76-8.923 22.615-42.115 22.625 42.115 46.75 8.923-32.683 35.164 6.077 47.76-42.769-20.625z"/>
</svg>

                        </p>
                        <p class="subtitle has-text-grey-light is-3 animated fadeInUp faster" style="animation-delay: 0.2s">No pending task for you :)</p>
                    </div>
                </div>
                </div>
                <quickview-task :task="task" @close="task = false" @blocked="loadData"/>
            </div>
        </div>
        <div v-if="assignment_mode" style="height:150px; ">
            <task-assignment :processors="processors" :isLoading="isLoading" :total_selected="total_selected" :selectAll="selectAll" :clearSelection="clearSelection" @assign="handleAssign" @close="assignment_mode = false"/>
        </div>
    </div>
</template>


<script>

import * as R from 'ramda'
import moment from 'moment'
import TableTasks from './../TableTasks.vue'
import QuickviewTask from './../QuickviewTask.vue'
import TaskSM from './../../domain/TaskStates.js'
import TaskAssignment from './TaskAssignment.vue'

export default {
    components:{
        TableTasks,
        QuickviewTask,
        TaskAssignment,
    },
    data(){
        return {
            tab: 'tasks',
            task: false,
            assignment_mode: false,
            selected_tasks: {},
            processor: false,
            isLoading: false,
            selectedDistribution: false,
            everyone: false,
						showBlocked: false,
        }
    },
    computed:{
        me(){
            return this.$store.state.login.user
        },
        tasks(){
            return this.$store.state.om.unassigned
        },
        filteredTasks(){
            return this.tasks.filter(task => {
							  if(!this.everyone){
                    return task.CreatedBy.staff_id == this.me.staff_id
                }
                return true
            }).filter(task => {
							if(!this.showBlocked){
								return task.Status == 'UNASSIGNED'
							}
							return true
						})
        },
        total_selected(){
            return R.pipe(R.values,R.sum)(this.selected_tasks)
        },
        processors(){
            return this.$store.state.om.processors
        },
        processorsMapping(){
            return R.indexBy(R.prop('staff_id'), this.processors)
        }
    },
    methods:{
        getProcessorName(staff_id){
            return (this.processorsMapping[staff_id] || { name: ''}).name 
        },
        handleAssign(processor){
            if(this.isLoading){
                return
            }

            this.isLoading  = true
            let tasks = this.tasks.filter(task => this.selected_tasks[task.TaskID])
            this.$store.dispatch('om.processor.assign', {
                processor: processor,
                tasks
            }).then((res) => {
                tasks.forEach(task => {
                    task.Status = task.Status == 'UNASSIGNED' ? 'ASSIGNED' : task.Status,
                    task.GS1 = processor.staff_id
                })
                this.isLoading  = false
                this.selected_tasks = {}
                this.processor = ''
            })
        },
        selectAll(){
            this.$set(this, 'selected_tasks', R.pipe(
                R.indexBy(R.prop('TaskID')),
                R.map(() => true)
            )(this.filteredTasks))
        },
        clearSelection(){
            this.selected_tasks = {}  
        },
        isSelected(task){
            return this.assignment_mode && this.selected_tasks[task.TaskID]
        },
        loadData(){
            return Promise.all([
                this.$store.dispatch('om.jobs.allunassigned'),
                this.$store.dispatch('om.processors.list')
            ])
        },
        canAssign(task){
            return TaskSM(task).canTransTo('ASSIGNED') || TaskSM(task).canTransTo('ASSIGNED_RERAISE')
        },
        
        viewTask(task){
            if(!this.assignment_mode){
                this.task = task
            }else{
                if(!this.canAssign(task)){
                    alert('You cannot assign this task')
                    return
                }
                this.$set(this.selected_tasks, task.TaskID, !this.selected_tasks[task.TaskID])
            }
        }
    },
    mounted(){
        this.loadData().then(() => {
            this.$store.dispatch('isLoaded')
            
            this.everyone = this.$route.query.everyone
        })
    },
    filters:{
        shortKey(value){
            return R.take(12, value)
        },
        formatDate(value){
            return moment(value).format('DD MMM YYYY')
        }
    }
}
</script>

<template>
    <div>
        <div class="field">
            <div class="control">
                <a @click="showModal = true" class="button is-fullwidth">
                    {{ label }}
                </a>
            </div>
        </div>
        <div class="modal" :class="{'is-active' : showModal }">
            <div class="modal-background"></div>
            <div class="modal-content" style="width:auto">
                <div class="card">
                    <div class="card-content">
                        <div class="field">
                            <label for="" class="label">Select dropfile</label>
                        </div>

                        <table class="table is-hoverable is-bordered is-fullwidth">
                            <tbody>
                                <tr @click="selectDropfile(dropfile)" v-for="dropfile in dropfiles" :key="dropfile.PK" class="is-clickable" >
                                    <!-- <td>
                                        <div class="level">
                                            <div class="level-left">
                                                <div class="level-item">
                                                    <p class="heading">Dropfile Id:</p>
                                                    <p>{{ dropfile.PK | shortKey }}</p>
                                                </div>
                                                <div class="level-item">
                                                    <p class="heading">Subject:</p>
                                                    <p>{{ dropfile.details.subject }}</p>
                                                </div>
                                            </div>
                                            <div class="level-right">
                                                <div class="level-item">
                                                    <p class="heading">Created By:</p>
                                                    <p>{{ dropfile.CreatedBy.name }}</p>
                                                </div> 
                                                <div class="level-item">
                                                    <p class="heading">Created At:</p>
                                                    <p>{{ dropfile.createdAt | fromNow }}</p>
                                                </div>                                               
                                            </div>
                                        </div>
                                    </td> -->
                                    <td>
                                         <div>
                                            <p class="heading">Dropfile Id:</p>
                                            <p>{{ dropfile.PK | shortKey }}</p>
                                        </div>
                                    </td>
                                    <td>
                                         <div>
                                            <p class="heading">Subject:</p>
                                            <p>{{ dropfile.details.subject }}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <p class="heading">Created By:</p>
                                            <p>{{ dropfile.CreatedBy.name }}</p>
                                        </div> 
                                    </td>
                                    <td>
                                         <div>
                                            <p class="heading">Created At:</p>
                                            <p>{{ dropfile.createdAt | fromNow }}</p>
                                        </div>   
                                    </td>
                                </tr>
                                <tr @click="selectDropfile(false)" class="is-clickable" >
                                    <td colspan="4">
                                        No associated dropfile
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <button class="modal-close is-large" aria-label="close" @click="showModal = false"></button>
        </div>
    </div>
</template>


<script>
import * as R from 'ramda'
import moment from 'moment'

export default {
    props:['jobsheet_id'],
    data(){
        return {
            showModal: false,
            label: 'Select dropfile'
        }
    },
    computed:{
        dropfiles(){
            const open = R.filter(file => file.Status == 'OPEN');
            return open(this.$store.state.om.dropfile.files)
        }
    },
    methods:{
        selectDropfile(dropfile){
            this.$emit('selected', dropfile ? dropfile.PK : this.jobsheet_id)
            this.label = dropfile ? R.take(12,dropfile.PK) : 'No associated dropfile'
            this.showModal = false
        }
    },
    filters:{
        shortKey(values){
            return R.take(12,values)
        },
        fromNow(value){
            return moment(value).fromNow()
        }
    }
}
</script>

<template>
    <div>
        <div class="modal is-active">
        <div class="modal-background"></div>
        <div class="modal-content animated fadeInUp faster">
            <div class="card ">
                <div class="card-header">
                    <p class="card-header-title">New project</p>
                    <a @click="$emit('close')" class="card-header-icon" aria-label="more options">
                    <span class="icon">
                        <i class="delete" aria-hidden="true"></i>
                    </span>
                    </a>
    
                </div>
                <div class="card-content">
                    <div class="field">
                        <div class="control">
                            <input :class="{'is-danger': errors.has('name')}"  v-validate="{required:true}" data-vv-as="Project name" class="input" type="text" placeholder="Project name" v-model="form.name" name="name" ref="name" >
                        </div>
                    </div>
                </div>
                <div class="card-content has-background-light">
                    <div class="level">
                        <div class="level-left">
                            
                        </div>
                        <div class="level-right">
                            <div class="buttons">
                                <a @click="$emit('close')" class="button is-text has-text-grey ">Cancel</a>
                                <a @click="handleSubmit" class="button is-primary">Create</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>


<script>
export default {
    data(){
        return {
            form: {
                name: ''
            }
        }
    },
    mounted(){
        this.$refs.name.focus()
    },
    computed:{
        
    },  
    methods:{
        handleSubmit(){
            this.$validator.validateAll().then((passed) => {
                if(!passed){
                    alert("Please check validation")
                    return
                }

                this.$emit('onsubmit', this.form)
                this.$emit('close')
            })
        }
    }
}
</script>




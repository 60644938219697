<template>
    <div class="container section">
        <div class="card">
            <div class="card-content">
                <form @submit.prevent="handleSearch()" >
                    <div class="field has-addons">
                        <div class="control is-expanded">
                            <input type="text" v-model="newkeyword" class="input is-rounded is-large " placeholder="Order ID">
                        </div> 
                        <div class="control">
                            <button type="submit" href="#" class="button is-link is-large is-rounded" :class="{'is-loading': isLoading }">Search</button>
                        </div>   
                    </div>
                    <div class="field is-grouped">
                        <div class="control">
                            <div class="field">
                                Search in records between <strong>{{ startDate.format('YYYY-MM-DD') }}</strong> to <strong>{{ endDate.format('YYYY-MM-DD') }}</strong>
                            </div>
                        </div>
                        <div class="control">
                            <div class="field">
                                <a @click="searchMore" class="button is-small is-primary">Search More</a>
                            </div>
                        </div>
                    </div>
                </form> 
            </div>
        </div>
        <div class="card">
            <table-task v-if="results.length > 0" :filteredTasks="results" :getProjectName=getProjectName :isViewed=isViewed :selectedSessionTasks=noop :getProcessorName=getProcessorName :viewTask=viewTask />
            <div v-if="results.length == 0" class="card-content">
                No result found
            </div>
        </div>
        <quickview-task :task="task" @close="task = false"  />
    </div>
</template>


<script>
const resultMap = require('./ResultMap')
import * as R from 'ramda'
import moment from 'moment'
import TableTask from './../Tasks/TableTask.vue'
import QuickviewTask from './../QuickviewTask.vue'
export default {
    components:{
        TableTask,
        QuickviewTask,
    },
    data(){
        return {
            isLoading: false,
            results: [],
            users: [],
            task: false,
            newkeyword: '',
            startDate: moment(),
            endDate: moment()
        }
    },
    mounted(){
        this.doSearch()
        this.$store.dispatch('om.processors.list').then(users => {
            this.users = users
        })
    },
    watch:{
        keyword(){
            this.doSearch()
        }
    },
    computed:{
        keyword(){
            return this.$route.query.keyword
        },
        newStartDate(){
            return this.$route.query.startDate 
        }
    },
    methods:{
        searchMore(){
            this.handleSearch(this.startDate.clone().subtract(2,'weeks').format('YYYY-MM-DD'))
        },
        handleSearch(startDate){
            console.log(startDate, "dasda")
            this.$store.dispatch('loading')
            this.$router.push(`?keyword=${this.newkeyword}&startDate=${startDate || ''}`)
            this.doSearch()
        },
        doSearch(){
            this.newkeyword = this.keyword
            console.log('searching...')
            //this.isLoading = true
            this.$store.dispatch('om.search', { keyword: this.keyword , startDate: this.newStartDate}).then(data => {
                this.isLoading = false
                this.results = data.results
                this.startDate = moment(data.startDate)
                this.endDate = moment(data.endDate)
                this.$store.dispatch('isLoaded')
            })
        },
        noop(){

        },
        isViewed(task){
            return this.task == task
        },
        getProjectName(project_id){
            return this.$store.state.om.projects[project_id].name
        },
        viewTask(task){
            this.task = task
        },
        getProcessorName(id){
            return R.pipe(R.find(data => data.staff_id == id), data =>  (data|| {}).name)(this.users)
        },
    }
}
</script>

<template>
    <div class="card-content" :style="$store.getters.secureBg">
        <div class="level" :class="{'is-quickview': isQuickview}">
            <div class="level-left">
                <h1 class="subtitle is-5 has-text-grey">{{ status }} tasks</h1>
            </div>
            <div class="level-right">
                <a v-if="!selectedTask && filteredTasks.length > 0 && !selectionMode"  @click="selectTasks" class="button is-primary">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill:currentColor;">
                        <path d="M8 5v14l11-7z"/>
                    </svg>
                    &nbsp;Start session</a>
                
                <a v-if="selectionMode"  @click="cancelSession" class="button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"  style="fill:currentColor;">
                        <path d="M6 6h12v12H6z"/>
                    </svg>

                    &nbsp;Cancel</a>

                <a v-if="selectedTask" @click="stopTask()" class="button is-danger">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill:currentColor;">
                        <path d="M6 6h12v12H6z"/>
                    </svg>
                    &nbsp;
                    End session
                </a>
            </div>
        </div>
        
        <div v-if="selectionMode" class="navbar is-fixed-bottom animated bounceInUp fast" style="border-top: solid 1px #00000030; z-index:36;" >
            <div class="container">
                <div class="column">
                    <div class="level">
                        <div class="level-left">
                            <div class="level-item">
                                <span><strong>{{ totalSelected }}</strong>&nbsp; tasks selected</span>
                                <div class="buttons" style="margin-left:20px;">
                                    <a @click="selectAll" class="button is-text is-small">Select all</a>
                                    <a @click="clearAll" class="button is-text is-small">Clear all</a>
                                </div>
                            </div>
                        </div>
                        <div class="level-right">
                            <div class="level-item">
                                <div class="buttons">
                                    <a @click="startSessionNow" class="button is-primary">Start now</a>
                                    <a @click="cancelSession" class="button is-text">cancel</a>

                                </div>
                            </div>
                        </div>
                    </div>     
                </div>
            </div>
        </div>

        <div class="columns" :class="{'is-quickview': isQuickview}">
            <div class="column"  v-if="!selectedTask" >
                <div class="card" v-if="filteredTasks.length > 0">
                        <table-task :filteredTasks=filteredTasks :getProjectName=getProjectName :isViewed=isViewed :selectedSessionTasks=selectedSessionTasks :getProcessorName=getProcessorName :viewTask=viewTask />
                    </div>
                    <div class="columns is-centered" v-else>
                        <div class="column is-6 has-text-centered has-text-grey-lighter content " style="padding:40px;">
                            <p class="animated zoomIn faster">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 503.635 503.635" width="160" style="fill:currentColor;">
                                    <path d="M503.635 6.284l-150.846-.635-70.939 150.108c-9.755-1.774-19.744-2.858-29.994-2.858-10.273 0-20.286 1.087-30.062 2.868L150.837 5.649 0 6.284l107.797 228.103c-16.284 26.469-25.855 57.615-25.855 91.06 0 95.135 76.221 172.538 169.913 172.538s169.914-77.404 169.914-172.538c0-33.478-9.59-64.654-25.904-91.139L503.635 6.284zM365.231 25.399l107.385.442L382.873 215.7c-20.981-25.786-49.202-45.257-81.453-55.267l63.811-135.034zm-334.212.442l107.375-.442 63.825 135.052c-32.249 10.025-60.464 29.513-81.436 55.311L31.019 25.841zm371.058 299.606c0 84.279-67.385 152.846-150.221 152.846s-150.221-68.567-150.221-152.846c0-84.288 67.385-152.856 150.221-152.856s150.221 68.568 150.221 152.856z"/>
                                    <path d="M359.904 294.659l-72.519-13.827-35.529-66.173-35.519 66.173-72.529 13.827 50.663 54.49-9.5 74.587 66.885-32.24 66.885 32.24-9.5-74.587 50.663-54.49zM251.856 369.63l-42.769 20.625 6.077-47.76-32.683-35.164 46.76-8.923 22.615-42.115 22.625 42.115 46.75 8.923-32.683 35.164 6.077 47.76-42.769-20.625z"/>
                                </svg>
                            </p>
                            <p class="subtitle has-text-grey-light is-3 animated fadeInUp faster" style="animation-delay: 0.2s">No pending task for you :)</p>
                        </div>
                    </div>
            </div>
            <div class="column" v-if="selectedTask">
                <div class="columns">
                    <div class="column">
                        <task-details :selectedTask=selectedTask class="animated fadeInUp faster" :key="selectedTask.TaskID"></task-details>
                        <task-card-float :loading="isLoading" @completeTask=completeTask @blocked=blocked @nextTask="nextTask" @prevTask="prevTask" :monitor="monitor" :selectedTask=selectedTask @close="stopTask" />
                        
                        <blockage  v-if="showBlock" :config="config" :selected-task="selectedTask"  @close="showBlock = false" @blocked="onblocked" />
                    </div>
                    <div class="column is-4 ">
                        <network-diagram-upload :resource="selectedTask" />
                        <div class="level" style="margin-top:20px;">
                            <div class="">
                                <h3 class="subtitle has-text-grey is-5">Comments</h3>
                            </div>
                            <div class="">
                                <a @click="showCommentForm = true" class="button is-small is-text has-text-grey">Post comment</a>
                            </div>
                        </div>
                        <comments :showForm="showCommentForm" @close="showCommentForm = false"  :resource=selectedTask></comments>
                        <div style="height:200px;"></div>
                    </div>
                </div>
            </div>
        </div>
        <quickview-task :task="task" @close="task = false" @blocked="loadData"/>
    </div>
</template>


<script>
import moment from 'moment-timezone'
import TaskCard from './../Jobs/TaskCard.vue'
import TaskCardFloat from './TaskCardFloat.vue'
moment.tz.setDefault('Asia/Kuala_Lumpur')
import TaskDetails from './TaskDetails.vue'
import * as R from 'ramda'
import Comments from './Comments.vue'
import QuickviewTask from './../QuickviewTask.vue'
import NetworkDiagramUpload from './../NetworkDiagramUpload.vue'
import AttachmentTag from './../AttachmentTag.vue'
import ExportExcel from './../ExportExcel.vue'
import TableTask from './TableTask.vue'
import Blockage from './Blockage.vue'

export default {
    props: ['status'],
    beforeRouteEnter(to,from,next){
       next(vm => {
           if(!vm.$store.getters.om_role(['processors'])){
               vm.$router.replace('/')
           }
       })
    },
    components:{
        Comments,
        TaskCard,
        TaskDetails,
        TaskCardFloat,
        QuickviewTask,
        NetworkDiagramUpload,
        AttachmentTag,
        ExportExcel,
        TableTask,
        Blockage,
    },
    data(){
        return {
            isLoading: false,
            showCommentForm: false,
            selectedTask: false,
            currentForm: {
                qoute_no: '',
                order_no: '',
                service_no: '',
                billing_no: '',
            },
            submitting: false,
            users: [],
            monitor: {
                startTime: false,
                initialTotal: false,
                totalCompleted: 0,
                idx: 0
            },
            showBlock: false,
            task: false,
            selectionMode : false,
            selectedSessionTasks : {}
        }
    },
    watch: {
        selectedTask(){
            this.currentForm = {
                qoute_no: '',
                order_no: '',
                service_no: '',
            }

            this.$store.dispatch('isLoaded')
            
        },
        TaskID(value){
            if(!value){
                this.selectedTask = false
            }else{
                this.selectedTask = this.tasks.find(task => task.TaskID == value)
            }

            window.scrollTo(0, 0);
        },
    },
    mounted(){
        this.loadData()
    },
    computed:{
        sessionTasks(){
            return R.values(this.selectedSessionTasks).filter(data => data)
        },
        totalSelected(){
            return this.sessionTasks.length
        },
        isQuickview(){
            return this.task !== false
        },
        config(){
            return this.$store.state.om.configs
        },
        staff_id(){
            return this.$store.state.login.user.staff_id
        },
        tasks(){
            const maps = {
                Open: ['ASSIGNED', 'ASSIGNED_RERAISE'],
                Blocked: ['BLOCKED']
            }
            const OPENTASKS =  maps[this.status] || []
            return R.filter(task => OPENTASKS.indexOf(task.Status) > -1 || task.TaskID == this.TaskID , this.$store.state.om.mytasks)
        },
        filteredTasks(){
            return this.tasks
        },
        isCompleted(){
            return this.monitor.initialTotal == this.monitor.totalCompleted
        },
        TaskID(){
            if (this.$route.params.task_id)
                console.log("TASK ID ", this.$route.params.task_id)
                
            return this.$route.params.task_id
        }
    },
    methods:{
        cancelSession(){
            this.selectionMode = false;
            this.selectedSessionTasks = {}
        },
        startSessionNow(){
            this.task = false
            this.selectionMode = false;
            this.startTask(0)
        },
        selectAll(){
            this.selectedSessionTasks = R.pipe(R.indexBy(R.prop('task_id')))(this.tasks)
        },
        clearAll(){
            this.selectedSessionTasks = {}
        },
        selectTasks(){
            this.selectionMode = true
        },
        isViewed(task){
            return this.task == task
        },
        getProjectName(project_id){
            return this.$store.state.om.projects[project_id].name
        },
        viewTask(task){
            if(this.selectionMode){
                this.$set(this.selectedSessionTasks,task.task_id, !this.selectedSessionTasks[task.task_id] ? task : false)
            }else{
                }
                this.task = task
        },
        blocked(){
            this.showBlock = true
        },
        onblocked(){
            this.$store.dispatch('om.processors.mytasks')
            this.monitor.totalCompleted++
            if(this.isCompleted){
                this.$router.push('/tasks')
            }else{
                this.nextTask() 
            }
        },
        completeTask(feedback){
            this.isLoading = true
            this.$validator.validateAll().then(passed => {

                if(!passed){
                    this.isLoading = false
                    return 
                }

                this.$store.dispatch('om.tasks.update', {
                    data : feedback, //this.selectedTask,
                    task_id: this.selectedTask.task_id
                }).then(res => {
                    this.$store.dispatch('om.processors.mytasks')
                    this.monitor.totalCompleted++
                    if(this.isCompleted){
                        this.$router.push('/tasks')
                    }else{
                        this.nextTask() 
                    }
                }).catch(err => {
                    // alert(err)
                }).then(() => {
                    this.isLoading =false;
                })
            })
        },
        startTask(idx){
            this.monitor.startTime = moment()
            this.monitor.initialTotal = this.totalSelected
            this.monitor.totalCompleted = 0
            // this.selectedTask = this.tasks[idx]
            this.monitor.idx = idx
            this.$router.push(`/tasks/${this.sessionTasks[idx].TaskID}`)
        },  
        stopTask(){
            this.selectedSessionTasks = {}
            this.$router.push('/tasks')  
        },
        nextTask(){
            this.monitor.idx++
            const total = (this.sessionTasks).length;
            if(this.monitor.idx >= total ){
                this.monitor.idx = 0
            }

            const TaskID = this.sessionTasks[this.monitor.idx].TaskID
            this.$router.push(`/tasks/${TaskID}`)
        },
        prevTask(){
            this.monitor.idx--
            const total = (this.sessionTasks).length;
            if(this.monitor.idx < 0 ){
                this.monitor.idx = total - 1
            }

            const TaskID = this.sessionTasks[this.monitor.idx].TaskID
            this.$router.push(`/tasks/${TaskID}`)
        },
        loadData(){
            this.$store.dispatch('om.processors.mytasks').then(() => {
                this.$store.dispatch('isLoaded')
            }).then(() => {
                if(this.TaskID){
                    this.selectedTask = R.find(data => data.TaskID == this.TaskID, this.tasks)
                    this.$set(this.selectedSessionTasks , this.TaskID, this.selectedTask)
                    this.startSessionNow()
                }
            })

            this.$store.dispatch('om.processors.list').then(users => {
                this.users = users
            })
            
            
        },
        getProcessorName(id){
            return R.pipe(R.find(data => data.staff_id == id), data =>  (data|| {}).name)(this.users)
        },
        moreInfo(task){
            this.$router.push(`/tasks/${task.task_id}`)
        },
        copyToCb(value){
            this.$copyText(value).then(function (e) {
                alert('Copied')
                console.log(e)
            }, function (e) {
            alert('Can not copy')
                console.log(e)
            })
            
        }
    },
    filters:{
        diffNow(value){
            return moment(value).fromNow()
        },
        shortKey(value){
            return R.take(12, value)
        },
        formatDate(str){
            return moment(str).format('DD MMM YYYY')
        },
        formatTime(str){
            return moment(str).format('hh:mm A')
        }
    }
}
</script>


<style scoped>
.card-hover:hover, .card-selected{
    background-color: hsl(212, 100%, 97%);
    cursor: pointer;
}
/* 
.is-quickview{
    padding-right: 420px;
} */

</style>

<template>
    <div>
        <div class="section">
            <div class="columns is-centered">
                <div class="column is-2">
                    <div class="box has-text-centered">
                        DOWNLOAD DATA <export-excel :raw="records" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import ExportExcel from './../ExportExcel.vue'

export default {
    components:{
        ExportExcel
    },
    data(){
        return {
            records: []
        }
    },
    mounted(){
        this.$store.dispatch('isLoaded')
        this.loadData()
    },
    methods:{
        loadData(){
            return this.$store.dispatch('om.reports.load').then(data => {
                this.records = data
            })
        }
    }
}
</script>

import axios from 'axios'
const hash = require('sha1')
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment-timezone'
moment.tz.setDefault('Asia/Kuala_Lumpur')
import *  as R from 'ramda'
import Vue from 'vue'
import store_dropfile from './storeDropfile'

let mode = 'staging'

const api = (path) => {
    if (mode == 'production') {
        return `${process.env.API_URL_PROD}${path}`
    }

    return `${process.env.API_URL}${path}`
}

const jobMapper = (data) => {
    return {
        ...data,
        //Status: data.SK.split('_')[1],
        CreatedDate: moment(data.CreatedDate, 'YYYYMMDD'),
    }
}

let store = {
    modules: {
        dropfile: store_dropfile
    },
    state: {
        stats: {},
        configs: false,
        current: {
            file: '',
            tasks: false,
            project: false,
        },
        ui: {
            project: {
                newForm: false
            }
        },
        loadings:{
            projects: false
        },
        jobs:{},
        projects: {},
        unassigned: [],
        mytasks: {},
        comments: {},
        processors: [],
        user_roles: false,
        currentUserRoles: false,
        currentUserActiveRole: false,
        attachments: {},
        
    },
    getters: {
        om_taskCheck(state, getters) {
            return (task) => {
                const data = R.pipe(getters.om_taskDetails, R.omit(['RequestType', 'project', 'TaskID']), R.map(data => data == "" ? undefined : data))(task.details)
                return task.TaskID.replace(/\..*/, '') == hash(JSON.stringify(data))
            }  
        },
        om_requestTypes(state) {
            return state.configs[`template.columns.${(state.configs.template || {}).version}`]
        },
        om_taskTemplate(state) {
            return (RequestType) => {
                const version = state.configs.template.version
                return R.view(R.lensPath(['configs', `template.columns.${version}`, RequestType]), state) || []
            }  
        },
        om_taskDetails(state) {
            return (details) => {
                if (!details) {
                    return []
                }
                const version = state.configs.template.version
                const template = R.view(R.lensPath(['configs', `template.columns.${version}`, details.RequestType]), state) || []
                const keys = R.concat(['TaskID', 'RequestType'],template)
               
                // console.log('Keys from Active Template', version, template )
                if (!keys) {
                    return details;
                }

                return R.mergeAll(
                    keys.map(key => ({
                        [key] : details[key] 
                    }))
                )
            }  
        },
        'om_comments'(state) {
            return (resource_type, resource_id) => {
                return R.pipe(
                    R.filter(comment => comment.resource_id == resource_id && comment.resource_type == resource_type),
                    R.values,
                    R.sort(R.descend(R.prop('createdAt')))
                )(state.comments)
            }
        },
        'om_assignedProjects'(state) {
            return R.pipe(
                R.flatten,
                R.groupBy(R.prop('GS2')),
                R.map(R.length)
            )(state.mytasks || [])
        },
        'om_role'(state) {
            return (role) => {
                return R.flatten([role]).indexOf(state.currentUserActiveRole) > -1
            }
        },
        'om_has_role'(state) {
            return (role) => {
                return R.intersection(R.flatten([role]), state.currentUserRoles).length > 0
            }
        },
        om_canPatchFeedback(state, getters) {
            return (task) => {
                const TaskStates = require('./../domain/TaskStates')(task)
                return (TaskStates.canTransTo('CANCEL_SUBMITTED') || TaskStates.isIn('CANCEL_SUBMITTED')) && getters.om_role(['supervisors'])
            }  
        },
        api(state) {
            return (path) => api(path)
        }
    },
    mutations: {
        SET_STATS(state, stats) {
            state.stats = stats  
        },
        UPDATE_UI(state, data) {
            state.ui = Object.assign({}, state.ui, data)
        },
        UPDATE_PROJECTS(state, projects) {
            state.projects = projects
        },
        CURRENT_PROJECT(state, project) {
            state.proj.project = project
        },
        SET_UNASSIGNED(state, tasks) {
            state.unassigned = tasks
        },
        MY_TASKS(state, tasks) {
            state.mytasks = tasks//R.indexBy(R.prop('TaskID'))(tasks)
        },
        ADD_COMMENTS(state, comments) {
            state.comments = R.mergeAll([
                state.comments,
                R.indexBy(R.prop('CommentID'), comments)
            ])
        },
        SET_PROCESSORS(state, processors) {
            state.processors = processors
        },
        SET_JOB(state, job) {
            state.jobs = R.mergeAll([state.jobs, { [job.request_id] : job}])
        },
        SET_JOBS(state, jobs) {
            state.jobs = R.indexBy(R.prop('PK'), jobs)
        },
        START_LOADING(state, key) {
            state.loadings[key] = true
        },
        STOP_LOADING(state, key) {
            state.loadings[key] = false
        },
        SET_USERROLE(state, roles) {
            state.user_roles = Object.assign({
                'processors': {},
                'executives': {},
                'supervisors': {},
                'project_manager': {},
                'sales': {}
            }, roles)
        },
        ADD_USERROLE(state, { role, user }) {
            state.user_roles[role][user.staff_id] = user
        },
        REMOVE_USERROLE(state, { role, staff_id }) {
            state.user_roles[role] = R.omit([staff_id], state.user_roles[role])
        },
        SET_CURRENTUSER_ROLES(state, roles) {
            state.currentUserRoles = roles
        },
        SET_CURRENTUSER_ROLE(state, role) {
            state.currentUserActiveRole = role
        },
        SET_CONFIG(state, config) {
            state.configs = config
        },
        SET_ATTACHMENT(state, {
            attachment_type,
            key,
            attachments
        }) {
            const path = R.lensPath([attachment_type, key])
            state.attachments = R.set(path, attachments, state.attachments)
        },
    },
    actions: {
        'om.stats.load'(context) {
            return axios.get(api(`/obase/stats`)).then(res => {
                context.commit('SET_STATS', res.data.stats)
                return res.data.stats
            })
        },
        'om.reports.load'(context) {
            return axios.get(api('/obase/reports')).then(res => res.data.data)  
        },
        'om.init'(context) {
            context.dispatch('om.project.list')  
            context.dispatch('om.processors.list')
            context.dispatch('om.config.load')
        },
        'om.search'(context, {keyword, startDate }) {
            return axios.post(api('/obase/search'), { keyword, startDate }).then(res => {
                return res.data
            })  
        },
        'om.config.load'(context, force = false) {
            if (!context.state.configs || force) {
                context.commit('SET_CONFIG',
                    axios.get(api('/obase/config')).then(res => {
                        context.commit('SET_CONFIG', res.data.configs)
                    })  
                )
            }
        },
        'om.config.update'(context, { type, values}) {
            return axios.post(api('/obase/config'), {
                type: type,
                values: values
            }).then(() => {
                return context.dispatch('om.config.load', true)
            })
        },
        'om.ui.update'(context, data) {
            context.commit('UPDATE_UI', data)  
        },
        'om.jobsheet.delete'(context, jobsheet_id) {
            return axios.delete(api(`/obase/jobs/${jobsheet_id}`))
        },
        'om.jobsheet.load'(context, jobsheet_id) {
            return context.dispatch('om.jobs.find', jobsheet_id)
        },
        'om.jobsheet.getHashId'(context, data) {
            return uuidv4()
        },
        'om.jobsheet.checkDuplicate'(context, data) {
            const hash_id = hash(JSON.stringify(data.records))
            return context.dispatch('om.jobs.find', hash_id).then(jobsheet => {
                if (jobsheet) {
                    throw `Jobsheet has been uploaded`
                }
                return hash_id
            })
        },
        async 'om.jobsheet.accept'(context, data) {
            // upload file to s3
            const s3Url = await context.dispatch('om.jobsheet.archive', data.file)
            
            // create records
            return context.dispatch('om.job.create', {
                job_id: data.drop_id || hash(JSON.stringify(data.records)),
                records: data.records,
                project_id: data.project_id,
                dropfile: data.dropfile,
                file: s3Url
            })            
        },

        'om.jobsheet.archive'(context, file) {
            console.log("UPLOAD FILE TO S3", file)
        },

        'om.job.create'(context, { job_id, records, project_id, file, dropfile }) {
            return axios.post(api('/obase/jobs'), {
                job_id,
                records,
                project_id,
                file,
                dropfile
            }).then(res => {
                context.dispatch('om.jobs.load')
                return res.data.jobsheet
            })
        },
        'om.jobs.load'(context) {
            return axios.get(api('/obase/jobs')).then(res => {
                context.commit('SET_JOBS', res.data.jobs)
                return res.data.jobs
                // return res.data.jobs.map(jobMapper)
            })
            // .then((list) => {
            //     console.log("JOB LOADED", list)
            //     context.state.jobs = R.indexBy(R.prop('PK'), list)
            // })
        },
        'om.jobs.unassigned'(context) {
            return axios.get(api('/obase/jobs/unassigned')).then(res => {
                context.commit('SET_UNASSIGNED', res.data.tasks.filter(data => data.Status == 'UNASSIGNED'))
            })  
        },
				'om.jobs.allunassigned'(context) {
            return axios.get(api('/obase/jobs/unassigned')).then(res => {
                context.commit('SET_UNASSIGNED', res.data.tasks.filter(data => data.Status == 'UNASSIGNED' || data.Status == 'BLOCKED'))
            })  
        },
        'om.jobs.find'(context, job_id) {
            return axios.get(api(`/obase/jobs/${job_id}`)).then(res => {
                if (!res.data.job) {
                    return;
                }
                context.commit('SET_JOB', res.data.job)
                return res.data.job
            }).catch(err => {
                if (err.response && err.response.status == 404) {
                    return false
                }
                
                throw err
            })
        },
        'om.roles.currentUser'(context, staff_id) {

            if (context.state.currentUserRoles) {
                return Promise.resolve(context.state.currentUserRoles)
            }
            
            const promise = axios.get(api(`/obase/roles/${staff_id}`)).then(res => {
                context.commit('SET_CURRENTUSER_ROLES', res.data.roles)

            })

            context.commit('SET_CURRENTUSER_ROLES', promise)

            return promise
        },
        'om.roles.addUser'(context, data) {
            if (!data.user.staff_id) {
                return;
            }
            // context.commit('ADD_USERROLE', data)
            // return axios.post(api('/obase/roles'), data)

            const addRecord = axios.post(api('/obase/roles'),data).then(res => {
                const promise = axios.get(api('/obase/roles')).then(res => {
                    context.commit('SET_USERROLE', res.data.roles)
                    return res.data.roles
                })
                context.commit('SET_USERROLE', promise)
            })
            return addRecord

        },
        'om.roles.removeUser'(context, data) {
            context.commit('REMOVE_USERROLE', data) 
            return axios.delete(api(`/obase/roles?staff_id=${data.staff_id}&role=${data.role}`))
        },
        'om.roles.changeStatus'(context,data) {
            const updateRecord = axios.put(api('/obase/roles'),data).then(res => {
                const promise = axios.get(api('/obase/roles')).then(res => {
                    context.commit('SET_USERROLE', res.data.roles)
                    return res.data.roles
                })
                context.commit('SET_USERROLE', promise)
            })
            return updateRecord
        },
        'om.roles.load'(context, force = false) {
            if (context.state.user_roles && !force) {
                return Promise.resolve(context.state.user_roles)
            }

            const promise = axios.get(api('/obase/roles')).then(res => {
                context.commit('SET_USERROLE', res.data.roles)
                return res.data.roles
            })  

            context.commit('SET_USERROLE', promise)

            return promise
        },
        'om.roles.switch'(context, role) {
            if (context.state.currentUserRoles.indexOf(role) == -1) {
                role = R.head(context.state.currentUserRoles) 
            }
            context.commit('SET_CURRENTUSER_ROLE',role)  
        },
        'om.processors.recentTasks'(context) {
              return axios.get(api('/obase/processors/recent-tasks')).then(res => {
                  context.commit('MY_TASKS', res.data.tasks)
                  return res.data.tasks
              })
        },
        'om.processors.list'(context) {
            return context.dispatch('om.roles.load').then(() => {
                context.commit('SET_PROCESSORS', R.values(context.state.user_roles.processors))
                return context.state.processors
            })
        },
        'om.processors.workload'(context, staff_id) {
            return axios.get(api(`/obase/processors/${staff_id}`)).then(res => {
                return res.data.tasks
            })
        },
        'om.processor.assign'(context, { processor, tasks }) {
            return axios.post(api('/obase/processors/assign'), { processor, tasks }).then(res => {
                alert(`${tasks.length} task(s) assigned to ${processor.name}`)
                return res
            })
        },
        'om.processors.mytasks'(context, staff_id) {
            // TODO: make 2 calls... partial and full

            axios.get(api('/obase/processors/mytasks')).then(res => {
                context.commit('MY_TASKS', res.data.tasks)
                return res.data.tasks
            })

            return axios.get(api('/obase/processors/mytasks?limit=100')).then(res => {
                context.commit('MY_TASKS', res.data.tasks)
                return res.data.tasks
            })
        },
        'om.tasks.recent'(context) {
            return axios.get(api('/obase/tasks/recent')).then(res => {
                return res.data.tasks
            })
        },
        'om.tasks.load'(context, task_id) {
            return axios.get(api(`/obase/tasks/${task_id}`)).then(res => {
                return res.data.task
            })
        },
        'om.tasks.blockage'(context, { task, blockage }) {
            if (!context.getters.om_role(['supervisors', 'processors', 'executives'])) {
                alert("You are not allowed to block")
                return
            }
            
            const TaskStates = require('./../domain/TaskStates')(task)
            if (!TaskStates.canTransTo('BLOCKED')) {
                alert(`${task.Status} cannot be block`)
                return
            }

            return Promise.all([
                context.dispatch('om.comments.post', {
                    PK: `${task.PK}.${task.SK}`,
                    message: blockage.remarks,
                    type: blockage.blockage_type
                }),
                axios.put(api(`/obase/tasks/${task.task_id}`), blockage)
            ]).then(([comment, task]) => {
                return task.data.task
            })
        },
        'om.tasks.patch'(context, data) {
            if (!context.getters.om_canPatchFeedback(data.task)) {
                alert("Unauthorized")
                return
            }

            return axios.put(api(`/obase/tasks/${data.task.task_id}`), {
                type: 'patch',
                data: data.data
            }).then(res => {

                return res.data.task
            })
        },
        'om.tasks.cancel'(context, { task, cancellation }) {
            if (!context.getters.om_role(['supervisors', 'processors', 'executives'])) {
                alert("You are not allowed to cancel")
                return
            }

            const cancelStatus = cancellation.type == 'cancellation' ? 'CANCELLED' : 'CANCEL_SUBMITTED'

            const TaskStates = require('./../domain/TaskStates')(task)
            if (!TaskStates.canTransTo(cancelStatus)) {
                alert(`${task.Status} cannot be ${cancelStatus}`)
                return
            }

            return Promise.all([
                context.dispatch('om.comments.post', {
                    PK: `${task.PK}.${task.SK}`,
                    message: cancellation.remarks,
                    type: 'Cancellation'
                }),
                axios.put(api(`/obase/tasks/${task.task_id}`), cancellation)
            ]).then(([comment, task]) => {
                return task.data.task
            })
        },
        'om.tasks.update'(context, data) {
            return axios.post(api(`/obase/tasks/${data.task_id}`), data.data).then(res => {
                return res.data.task
            })
        },
        'om.projects.updateTitle'(context, { project_id, title }) {
            return axios.put(api(`/obase/projects/${project_id}`), {
                title
            }).then(res => {
                context.dispatch('om.project.load', project_id)
            })
        },
        'om.projects.delete'(context, project_id) {
            return axios.delete(api(`/obase/projects/${project_id}`)).then(res => {
                  return res.data
              })
        },
        'om.project.create'(context, data) {
            return axios.post(api(`/obase/projects`), data).then(res => {
                context.dispatch('om.project.list')
                return res.data.project
            })
        },
        'om.project.list'(context) {
            context.commit('START_LOADING', 'projects');
            return axios.get(api(`/obase/projects`)).then(res => {
                context.commit('STOP_LOADING', 'projects');
                context.commit('UPDATE_PROJECTS', R.indexBy(R.prop('PK'), res.data.projects))
                return res.data.projects
            })
        },
        'om.project.load'(context, project_id) {
            return axios.get(api(`/obase/projects/${project_id}`)).then(res => {
                context.commit('UPDATE_PROJECTS', R.mergeAll([
                    context.state.projects,
                    {[res.data.project.PK] : res.data.project}
                ]) )
                
                return res.data.project
            })
        },

        'om.migrate'(context, data) {
            return axios.get(api('/obase/migrate')).then(res => {
                console.log(res.data)
                alert(res.data.message)
            })
        },

        'om.comments.get'(context, PK) {
            return axios.get(api(`/obase/comments/${PK}`)).then(res => {
                return res.data.comments
            })
        },

        'om.comments.post'(context, data) {
            return axios.post(api(`/obase/comments/${data.PK}`), {
                message: data.message,
                time: moment().unix(),
                type: data.type || 'notes'
            }).then(res => {
                return res.data
            })
        },
        'om.comments.latest'(context, list) {
            return axios.post(api(`/obase/comments/latest`), {
                ids: list
            }).then(res => {
                return res.data.comments
            })
        },

        'om.attachments.get'(context, { resource, attachment_type }) {
            return axios.get(api(`/obase/attachments/${resource.PK}?SK=${resource.SK}&attachment_type=${attachment_type}`)).then(res => {
                context.commit('SET_ATTACHMENT', {
                    attachment_type,
                    key: resource.SK,
                    attachments: res.data.attachments
                })
                return res.data.attachments
            })
        },
        'om.attachments.download'(context, attachment) {
            return axios.post(api('/obase/attachments/download'), attachment).then(res => res.data.signed_url)
        },
        
        async 'om.attachments.upload'(context, { file, resource, attachment_type }) {
            const Authorization = axios.defaults.headers.common["Authorization"];
            
            if (!attachment_type) {
                attachment_type = moment().unix()
            }
            
            const contentType = file.type

            const {signed_url, key} = await axios.post(api(`/obase/attachments`), {
                PK: resource.PK,
                SK: resource.SK,
                attachment_type,
                contentType
            }).then(res => {
                return res.data
            })
            
            delete axios.defaults.headers.common["Authorization"];
            
            console.log("GET SIGNED URL")
            return axios.put(signed_url, file, {
                headers: {
                    'Content-Type': contentType
                }
            }).catch(err => {
                return { error: err}
            }).then(async (res) => {
                //restore auth 
                axios.defaults.headers.common["Authorization"] = Authorization
                
                if (res.error) {
                    throw res.error
                }

                // success
                const attachment =  await axios.post(api(`/obase/attachments/${resource.PK}`), {
                    key,
                    contentType,
                    attachment_type,
                    filename: file.name,
                }).then(res => res.data.attachment)

                context.commit('SET_ATTACHMENT', {
                    attachment_type,
                    key: resource.SK,
                    attachments: [attachment]
                })

                return attachment
            })
        }
    }
}

export default store
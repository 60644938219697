<template>
    <div>
        <div class="box is-fullwidth" style="overflow:hidden;">
            <span class="heading" style="margin-bottom: 20px;">Network Design</span>
            <div v-if="uploadedFile" class="animated zoomIn faster">
                <a @click="downloadFile" class="box">
                    <div class="level">
                        <div class="level-item">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 492.308 492.308" width="64" style="fill:currentColor;">
                                    <path d="M0 0v492.308h492.308V0H0zm472.615 472.615H19.692V19.692h452.923v452.923z"/>
                                    <path d="M260.837 59.587v344.851h187.99V59.587h-187.99zm168.298 325.158H280.529V79.279h148.606v305.466zM50.462 59.587v69.933h187.99V59.587H50.462zm168.298 50.24H70.154V79.279H218.76v30.548zM50.462 162.337v69.938h187.99v-69.938H50.462zm168.298 50.245H70.154v-30.553H218.76v30.553zM50.462 265.091v69.933h187.99v-69.933H50.462zm168.298 50.241H70.154v-30.548H218.76v30.548z"/>
                                    <path d="M298.535 324.352h112.502v19.692H298.535zM298.535 256.197h112.502v19.692H298.535zM298.535 188.062h112.502v19.692H298.535zM298.535 119.975h112.502v19.692H298.535z"/>
                                </svg>
                            </div>
                            <div style="padding:5px 20px; overflow:hidden;">
                                <p>
                                    <span>Download Network Diagram</span><br>
                                    <span class="is-size-7 has-text-grey long-text break-word">{{ uploadedFile.details.filename }}</span><br>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="notification is-size-7">
                        Uploaded by <span>{{ uploadedFile.CreatedBy.name}}</span> {{ uploadedFile.createdAt | diffNow }}
                    </div>
                </a>
                <div class="level">
                    <div class="level-left"><span class="is-size-7">or</span><a @click="reUpload" class="button is-small is-text">Upload new file</a></div>
                </div>
            </div>

            <div v-if="!uploadedFile" class="field">
                <div v-if="isUploading == 'checking'" class="has-text-centered">
                    <span class="button is-fullwidth is-loading is-text"></span>
                    <span>Checking...</span>

                </div>
                <div v-else  class="file is-centered is-boxed has-name is-fullwidth" @dragover.prevent="handleDropover" @drop.prevent="handleFileChange" @dragleave="handleDropleaves" :class="{
                        'dropzone is-info': inDropzone,
                        'is-success': isUploading == 'uploaded',
                        'is-danger': isUploading == 'error'
                    }">
                    <label class="file-label has-text-grey">
                    <input class="file-inputx" type="file" name="resume" @change="handleFileChange">
                    <span class="file-cta">
                        <span class="file-icons" v-if="!isUploading">
                            <svg v-if="!inDropzone" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 492.308 492.308" style="fill:currentColor" width="64">
                                <path d="M246.154 161.913L142.885 273.308l14.442 13.384 78.981-85.196V411.5H256V201.495l78.986 85.197 14.442-13.384z"/>
                                <path d="M242.168 63.726L181.447 0H0v492.308h492.308V63.726h-250.14zm230.447 408.889H19.692V19.692h153.322l60.721 63.726h238.88v389.197z"/>
                            </svg>

                            <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 492.308 492.308" style="fill:currentColor" width="64">
                                <path d="M332.298 65.827L215.106 184.332 160 128.615l-14 13.847 69.106 69.87L346.298 79.673z"/>
                                <path d="M400.808 0H91.5L.567 257.365 0 258.952v233.356h492.308V258.957L400.808 0zM105.423 19.692h281.462l81.656 231.101H304.442v9.846c0 32.558-26.144 59.048-58.288 59.048s-58.288-26.49-58.288-59.048v-9.846H23.769l81.654-231.101zm367.192 452.923H19.692v-39.413h452.923v39.413zm0-59.105H19.692V270.486h149.087c4.817 38.788 37.673 68.894 77.375 68.894s72.558-30.106 77.375-68.894h149.087V413.51z"/>
                            </svg>
                        </span>
                        <span v-else>
                            <span v-if="isUploading == 'uploading'" class="button is-loading is-fullwidth is-text"></span>
                            <span v-if="isUploading == 'error'">
                                <svg class="animated rollIn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="64" style="fill:currentColor">
                                    <circle cx="15.5" cy="9.5" r="1.5"/>
                                    <circle cx="8.5" cy="9.5" r="1.5"/>
                                    <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm0-6c-2.33 0-4.32 1.45-5.12 3.5h1.67c.69-1.19 1.97-2 3.45-2s2.75.81 3.45 2h1.67c-.8-2.05-2.79-3.5-5.12-3.5z"/>
                                </svg>
                            </span>
                            <span v-if="isUploading == 'uploaded'">
                                <svg class="animated bounceIn faster" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="64" style="fill:currentColor;">
                                    <path d="M14.72 8.79l-4.29 4.3-1.65-1.65a1 1 0 1 0-1.41 1.41l2.35 2.36a1 1 0 0 0 .71.29 1 1 0 0 0 .7-.29l5-5a1 1 0 0 0 0-1.42 1 1 0 0 0-1.41 0zM12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z"/>
                                </svg>
                            </span>
                        </span>
                        
                        <span v-if="!isUploading" class="file-label has-text-centered">
                            <span v-if="!inDropzone">Upload Network Design</span>
                            <span v-else>Drop file here</span>
                        </span>

                        <span v-else class="file-label has-text-centered">
                            <span v-if="isUploading != 'uploaded'">Uploading...</span>
                            <span v-else>Uploaded!</span>
                        </span>
                    </span>
                    
                    <span class="file-name">
                        {{ filename }}
                    </span>
                    </label>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as R from 'ramda'
import moment from 'moment'

const delay = (dur) => {
    return new Promise(res => {
        setTimeout(res, dur)
    })
}

export default {
    props: ['resource'],
    data(){
        return {
            inDropzone: false,
            filename: '',
            file: false,
            isUploading: 'checking',
            uploadedFile: false,
        }
    },
    mounted(){
        this.reset()
        this.getAttachment()
    },
    watch:{
        resource(){
            this.reset()
            this.getAttachment()
        }
    },
    methods:{
        reset(){
            this.uploadedFile = false
            this.inDropzone = false
            this.filename = ''
            this.file = false
            this.isUploading = 'checking'
            
        },
        async downloadFile(){
            const url = await this.$store.dispatch('om.attachments.download', this.uploadedFile)
            window.open(url, '_BLANK')
        },
        getAttachment(){
            if(!this.resource){
                return;
            }
            this.$store.dispatch('om.attachments.get', {
                resource: this.resource,
                attachment_type: 'network-diagram'
            }).then(attachments => {
                this.uploadedFile = R.head(attachments)
                this.isUploading = false
            })
        },
        async getUrl(contentType){
            const url = await this.$store.dispatch('om.attachments.generate', {
                resource: this.resource,
                contentType
            })
            this.signed_url = url
            return url
        },
        reUpload(){
            this.uploadedFile = false
        },
        async handleFileChange(ev){
            ev.stopPropagation()
            ev.preventDefault()

            if (ev.dataTransfer && ev.dataTransfer.files) {
                this.file = R.head(ev.dataTransfer.files)
                this.filename = this.file.name
            }

            if(!ev.dataTransfer && ev.target && ev.target.files ){
                this.file = R.head(ev.target.files)
                this.filename = this.file.name 
            }
            
            this.inDropzone = false
            this.isUploading = 'uploading'

            this.$store.dispatch('om.attachments.upload',{
                file: this.file,
                resource: this.resource,
                attachment_type: 'network-diagram'
            }).then((attachment) => {
                this.isUploading = 'uploaded'
                return delay(1000).then(() => {
                    this.uploadedFile = attachment
                })
            }).catch(err => {
                this.isUploading = 'error'
                this.uploadedFile = false
                
                this.$store.dispatch('showError', "Failed to upload file")
                
                return delay(2000).then(() => {
                    this.isUploading = false
                })

            })
        },
        handleDropover(ev){
            this.inDropzone = true
        },
        handleDropleaves(ev){
            this.inDropzone = false
        }
    },
    filters:{
        diffNow(value){
            return moment(value).fromNow()
        }
    }
}
</script>



<style>
.file-inputx{
    display: block;
    border: solid 2px red;
    height:100%;
    position: absolute;
    width:100%;
    opacity: 0;
}

.break-word, .break-word * {
  word-wrap: break-word !important;
}
</style>
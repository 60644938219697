<template>
    <div>
        <div class="card">
            <div class="column">
                <div class="level">
                    <div class="level-start">
                        <h1 class="title is-5">Workload Distribution</h1>
                    </div>
                    <div class="level-end">
                        <a @click="asc = !asc" class="button is-small">
                            <svg v-if="asc" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M6 9l6 6 6-6"/></svg>
                            <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M18 15l-6-6-6 6"/></svg>
                        </a>
                    </div>
                </div>
            </div>
            <div class="hero" v-if="processors.length == 0">
                <div class="hero-body is-fullwidth">
                    <span class="button is-loading is-fullwidth is-white is-large"></span>
                </div>
            </div>

            <table v-else class="table is-fullwidth">
                <tbody>
                    <tr v-for="(processor) in sortedWorkload" :key="processor.staff_id">
                        <td>
                            <router-link :to="`/jobs/processors/${processor.staff_id}`" class="has-text-weight-bold">{{ processor.name }}</router-link>
                            <span class="heading">{{ processor.staff_id}}</span>
                        </td>
                        <td>
                            <div v-if="processor.loaded" class="level">
                                <div class="level-item">
                                    <div class="has-text-centered">
                                        <span class="has-text-info heading">Assg.</span>
                                        <span class="has-text-info has-text-weight-bold is-size-5">{{ processor.assigned }}</span>
                                    </div>
                                </div>
                                <div class="level-item">
                                    <div class="has-text-centered">
                                        <span class="has-text-danger heading">Blck.</span>
                                        <span class="has-text-danger has-text-weight-bold is-size-5">{{ processor.blocked }}</span>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <div class="button is-loading is-white is-fullwidth"></div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import * as R from 'ramda'
export default {
    data(){
        return {
            filterKeyword: '',
            workload: {

            },
            asc: true,
        }
    },
    computed:{
        processors(){
            return this.$store.state.om.processors
        },
        sortedWorkload(){
            const sorter = this.asc ? R.ascend : R.descend
            return R.pipe(
                R.map(processor => {
                    const loaded = !!this.workload[processor.staff_id];
                    const workload = this.workload[processor.staff_id]||{}
                    const blocked = workload.BLOCKED  || 0
                    const assigned = (workload.ASSIGNED || 0) + (workload.ASSIGNED_RERAISE||0)
                    return {
                        name: processor.name,
                        staff_id: processor.staff_id,
                        blocked,
                        assigned,
                        total: blocked + assigned,
                        loaded,
                    }
                }),
                R.sort(sorter(R.prop('assigned'))),
                R.filter(data => JSON.stringify(data).indexOf(this.filterKeyword) > -1)
            )(this.processors)
        },
    },
    mounted(){
        this.loadData()
    },
    methods:{
        getTooltip(processor){
            return `Assigned: ${processor.assigned} | Blocked: ${processor.blocked}`
        },
        loadData(){
            return this.$store.dispatch('om.processors.list').then(() => {
                this.processors.forEach(processor => {
                    this.$store.dispatch('om.processors.workload', processor.staff_id).then(tasks => {
                        const grouped = R.pipe(
                            R.groupBy(R.prop('Status')),
                            R.map(data => data.length),
                        )(tasks)
                        this.$set(this.workload, processor.staff_id, grouped)
                    })
                })
            })
        }
    }
}
</script>

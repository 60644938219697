var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"popover is-popover-top is-popover-active",on:{"mouseover":function($event){_vm.showPopover = true},"mouseleave":function($event){_vm.showPopover = false}}},[_c('span',{staticClass:"tag popover-trigger",class:{
            'is-dark': _vm.file.Status == 'OPEN',
            'is-info': _vm.file.Status == 'UPLOADED',
            'is-danger': _vm.file.Status == 'REJECTED' || (_vm.file.Status == 'ON_HOLD' && _vm.file.details.on_hold.isOverdue),
            'is-success': _vm.file.Status == 'TASK_COMPLETED',
            'is-warning': _vm.file.Status == 'ON_HOLD' && !_vm.file.details.on_hold.isOverdue,
        }},[_vm._v(_vm._s(_vm.file.Status))]),(_vm.file.Status == 'ON_HOLD')?_c('span',{staticClass:"help"},[_c('p',[_vm._v("Due on "+_vm._s(_vm.file.details.on_hold.duedate))]),_vm._v(" Hold by "+_vm._s(_vm.file.details.on_hold.holdBy.name)+" ")]):_vm._e(),(_vm.showPopover && _vm.file.details.rejection)?_c('div',{staticClass:"popover-content"},[_c('div',{staticClass:"content pre-formatted"},[_c('p',[_c('strong',[_vm._v("REASON:")]),_vm._v("\n"+_vm._s(_vm.file.details.rejection.reason))]),_c('p',[_vm._v("-"+_vm._s(_vm.file.details.rejection.rejectedBy.name))])])]):(_vm.showPopover && _vm.file.details.on_hold)?_c('div',{staticClass:"popover-content"},[_c('div',{staticClass:"content pre-formatted"},[_c('p',[_c('strong',[_vm._v("REASON:")]),_vm._v("\n"+_vm._s(_vm.file.details.on_hold.reason))])])]):(_vm.showPopover && _vm.file.Status != 'OPEN')?_c('div',{staticClass:"popover-content"},[(!_vm.tasks)?_c('span',{staticClass:"button is-text is-loading"}):_c('table',{staticClass:"table is-fullwidth"},[_c('tbody',_vm._l((_vm.tasks),function(value,key){return _c('tr',{key:key},[_c('th',[_vm._v(_vm._s(key))]),_c('td',[_vm._v(_vm._s(value))])])}),0)])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }
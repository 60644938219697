<template>
  <div>
    <div class="card" style="height:460px;">
      <div class="card-content">
        <div class="hero-bosdy">
          <span class="heading">{{ title }}</span>
            <apexchart type=donut :options="chartOptions" :series="series" height="300" />
        </div>
      </div>
      <div class="card-footer">
            <div class="card-footer-item ">
                <div class="has-text-centered ">
                    <span class="heading has-text-success"> < 1 day </span>
                    <span class="title is-3 has-text-success">{{ series[0] }}</span>
                </div>
            </div>
            <div class="card-footer-item">
                <div class="has-text-centered">
                    <span class="heading has-text-info"> < 3 days </span>
                    <span class="title is-3 has-text-info">{{ series[1] }}</span>
                </div>
            </div>
            <div class="card-footer-item">
                <div class="has-text-centered">
                    <span class="heading has-text-danger"> > 3 days </span>
                    <span class="title is-3 has-text-danger">{{ series[2] }}</span>
                </div>
            </div>
        </div>
    </div>
    
  </div>
</template>


<script>
import apexchart from 'vue-apexcharts'
export default {
    props: ['title', 'series'],
    components:{
        apexchart
    },
    data(){
        return {
            chartOptions: {
                labels: [" < 1 day"," < 3 days"," > 3 days"],
                colors:['#64CC6D', '#4B9CE7', '#EB4C64'],
                legend:{
                    show: false
                },
                plotOptions: {
                    
                    pie: {
                        donut: {
                            labels:{
                                show:true,
                                name:{
                                    offsetY:5
                                },
                                value:{
                                    show:true,
                                    offsetY: 35,
                                    fontSize: '41px'
                                },
                                total:{
                                    show: true,
                                    label: "Total orders",
                                }
                            }
                        }
                    }
                },
                
            }
        }
    }
}
</script>

const states = {
    UNASSIGNED: ['ASSIGNED','CANCELLED','BLOCKED'],
    ASSIGNED: ['ASSIGNED','SUBMITTED','CANCELLED', 'BLOCKED'],
    BLOCKED: ['SUBMITTED', 'CANCELLED', 'ASSIGNED', 'BLOCKED'],
    CANCELLED: [],
    SUBMITTED: ['CANCEL_SUBMITTED'],
    CANCEL_SUBMITTED: ['RERAISED', 'ASSIGNED_RERAISE'],
    RERAISED: ['CANCEL_SUBMITTED'],
    ASSIGNED_RERAISE: ['RERAISED', 'CANCEL_SUBMITTED'],

}

module.exports = (task) => {
    const allowedNextState = states[task.Status] || ['ASSIGNED'];
    return {
        canTransTo(newState) {
            return allowedNextState.indexOf(newState) > -1
        },
        isIn(state) {
            return task.Status
        }
    }    
}

<template>
    <div>
        <div class="popover is-popover-top is-popover-active" @mouseover="showPopover = true" @mouseleave="showPopover = false">
            <span class="tag popover-trigger"  :class="{
            'is-dark': file.Status == 'OPEN',
            'is-info': file.Status == 'UPLOADED',
            'is-danger': file.Status == 'REJECTED' || (file.Status == 'ON_HOLD' && file.details.on_hold.isOverdue),
            'is-success': file.Status == 'TASK_COMPLETED',
            'is-warning': file.Status == 'ON_HOLD' && !file.details.on_hold.isOverdue,
        }" >{{ file.Status }}</span>
        <span v-if="file.Status == 'ON_HOLD'" class="help"> <p>Due on {{ file.details.on_hold.duedate }}</p> Hold by {{ file.details.on_hold.holdBy.name }} </span>
  
            <div v-if="showPopover && file.details.rejection" class="popover-content" style="">
                <div class="content pre-formatted">
                    <p><strong>REASON:</strong>
{{ file.details.rejection.reason }}</p>
                    <p>-{{file.details.rejection.rejectedBy.name}}</p>
                </div>
            </div>

            <div v-else-if="showPopover && file.details.on_hold" class="popover-content" style="">
                <div class="content pre-formatted">
                    <p><strong>REASON:</strong>
{{ file.details.on_hold.reason }}</p>

                    <!-- <p><strong>Hold until {{ file.details.on_hold.duedate }}</strong></p> -->
                    <!-- <p>-{{file.details.on_hold.holdBy.name}}</p> -->
                </div>
            </div>

            <div v-else-if="showPopover && file.Status != 'OPEN'" class="popover-content">
                <span v-if="!tasks" class="button is-text is-loading"></span>
                <table v-else class="table is-fullwidth">
                    <tbody>
                        <tr v-for="(value, key) in tasks" :key="key">
                            <th>{{ key }}</th>
                            <td>{{ value }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<style lang="scss">

@import url('~bulma-popover/css/bulma-popover.css');

</style>


<script>
import * as R from 'ramda'
export default {
    props: ['file'],

    data(){
        return {
            showPopover: false
        }
    },
    
    computed:{
        job(){
            return this.$store.state.om.jobs[this.file.PK]
        },
        tasks(){
            if(!this.job){
                this.$store.dispatch('om.jobsheet.load', this.file.PK)
                return false
            }
            const stats = R.pipe(R.pluck('Status'),R.groupBy(R.identity),R.map(R.length))
            return stats(this.job.tasks)
        }
    },

    methods:{
        getRejectionReason(file){
            if(file.Status != 'REJECTED'){
                return ''
            }
            return `${file.details.rejection.reason}

-${file.details.rejection.rejectedBy.name}-`
        },
    }
}
</script>


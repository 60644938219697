<template>
    <div>
        <div class="modal is-active" v-if="showForm">
        <div class="modal-background"></div>
        <div class="modal-content">
            <div class="card">
                <div class="card-header">
                    <p class="card-header-title">Add comment</p>
                </div>
                <form @submit.prevent="handleSubmit" class="form">
                <div class="card-content">
                        <div class="field">
                            <label class="label">message</label>
                            <div class="control ">
                                <textarea v-validate="{required: true}" v-model="form.message" name="message" cols="30" class="textarea" :class="{'is-danger animated flash faster' : errors.has('message')}" placeholder="Your message..." ref="focus"></textarea>
                            </div>
                        </div>
                </div>
                <div class="card-content has-background-light">
                    <div class="level">
                        <div>
                        </div>
                        <div class="buttons">
                            <a @click="$emit('close')" class="button is-text">Cancel</a>
                            <button type="submit" class="button is-primary">Post comment</button>
                        </div>
                    </div>
                </div>
                </form>
            </div>
        </div>
        <button  @click="$emit('close')" class="modal-close is-large" aria-label="close"></button>
        </div>
        <div v-for="(comment,i) in comments" :key="comment.SK"  class="card animated fadeInRight faster has-ribbon" style="margin-bottom:10px;" :style="{'animation-delay' : `${i*0.1}s`}">
            <div v-if="isBlocked(comment)" class="ribbon is-danger is-small">{{ comment.details.comment_type }}</div>
            <div class="card-content">
                <div class="content"> 
                    <p class="pre-formatted">{{ comment.details.message }}</p>
                </div>
            </div>
            <div class="has-background-light" style="padding:10px;">
                <p class="is-size-7 has-text-grey-light">Posted by <b>{{comment.CreatedBy.name}}</b> {{ comment.createdAt | toTime }}</p>
            </div>
        </div>
        <div v-if="comments.length == 0" class="notification has-text-centered">
            <h1 class="subtitle has-text-grey-light">NO COMMENT YET</h1>
        </div>
    </div>
</template>


<script>
import moment from 'moment'

export default {
    props: ['showForm', "resource"],
    data(){
        return {
            form: {
                message: ''
            },
            comments: [],
            loading: false,
        }
    },
    computed:{
        user(){
            return this.$store.state.login.user
        },
        commentID(){
            return `${this.resource.PK}.${this.resource.SK}`
        }
    },
    mounted(){
        this.loadComments()
    },
    watch:{
        resource(){
            this.loadComments()
        },
        showForm(value){
            if(value){
                this.form.message = ''
                this.$nextTick(() => {
                    this.$refs.focus.focus()
                })
            }
        }
    },
    methods:{
        isBlocked(comment){
            return comment.details.comment_type && comment.details.comment_type != 'notes'
        },
        loadComments(){
            console.log("load comments")
            this.$store.dispatch('om.comments.get', this.commentID).then(comments => {
                this.loading = false
                this.comments = comments
            })
        },
        handleSubmit(){
            this.$validator.validateAll().then(passed => {
                if(!passed){
                    return
                }

                this.$store.dispatch('om.comments.post', {
                    PK: `${this.resource.PK}.${this.resource.SK}`,
                    message: this.form.message,
                }).then(() => {
                    this.loadComments(true)
                })

                this.$emit('close')
            })
        }
    },
    filters:{
        toTime(value){
            if(!value){
                return 'just now...'
            }

            return moment(value).fromNow()
        }
    }

}
</script>


<style>
.pre-formatted{
  white-space: pre-wrap;
}
</style>

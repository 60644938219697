<template>
    <div class="card-content">
       <div v-if="project">
           <div class="level">
            <div class="level-left">
                <div class="level-item">
                    <h1 v-if="!isEditTitle" class="title is-5 has-text-grey" @dblclick="editTitle" data-tooltip="Double click to edit" :class="{'is-clickable tooltip': isEditable}">
                    {{ project.name }}
                    <span v-if="isEditable" class="is-parent-hovered">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="width:16px;">
                                <path d="M22 7.24a1 1 0 0 0-.29-.71l-4.24-4.24a1 1 0 0 0-.71-.29 1 1 0 0 0-.71.29l-2.83 2.83L2.29 16.05a1 1 0 0 0-.29.71V21a1 1 0 0 0 1 1h4.24a1 1 0 0 0 .76-.29l10.87-10.93L21.71 8a1.19 1.19 0 0 0 .22-.33 1 1 0 0 0 0-.24.7.7 0 0 0 0-.14zM6.83 20H4v-2.83l9.93-9.93 2.83 2.83zM18.17 8.66l-2.83-2.83 1.42-1.41 2.82 2.82z"/>
                            </svg>
                    </span>
                </h1>
                <div class="field has-addons" v-else>
                    <div class="control">
                        <input @keyup.enter="saveNewTitle" type="text" v-model="newTitle" class="input" style="min-width:30vw;">
                    </div>
                    <div class="control">
                        <a @click="saveNewTitle" class="button" :class="{'is-loading': isLoading}">Save</a>

                        <a @click="cancelEdit" class="button is-text" :class="{'is-loading': isLoading}">Cancel</a>
                    </div>
                </div>
                </div>
            </div>
            <div class="level-right">
                <router-link :to="`/jobs/projects/${project_id}/upload`" class="button is-rounded is-small is-primary">Upload Jobsheet</router-link>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <div class="card" v-if="jobsheets && jobsheets.length > 0">
                    <table-jobsheets :jobsheets="jobsheets" />
                </div>
                <div v-else class="columns is-centered">
                    <div class="column is-6 has-text-centered has-text-grey-lighter content" style="padding:40px;">
                        <p>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 492.308 492.308" width="160" style="fill:currentColor;">
                                <path d="M316.038 254.298l-14.442-13.385-55.437 59.799-55.438-59.799-14.442 13.385 56.454 60.894-56.454 60.895 14.442 13.384 55.438-59.798 55.437 59.798 14.442-13.384-56.454-60.895z"/>
                                <path d="M242.173 63.731L181.452 0H0v492.308h492.308V63.731H242.173zm230.442 408.884H19.692v-312.23h452.923v312.23zm0-331.923H19.692v-121H173.01l60.721 63.731h238.885v57.269z"/>
                            </svg>
                        </p>
                        <p class="subtitle has-text-grey-light is-3">No jobsheet uploaded yet in this project</p>
                        <p>
                            <router-link :to="`/jobs/projects/${project_id}/upload`" class="button is-rounded is-medium is-primary">Upload Jobsheet</router-link>
                        </p>
                        <p v-if="isEditable">
                            or <br>
                            <a @click="deleteProject" class="button is-text is-small" :class="{'is-loading':isLoading}">DELETE THIS PROJECT</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
       </div>
    </div>
</template>


<script>
import * as R from 'ramda'
import moment from 'moment'
import TableJobsheets from './../TableJobsheets.vue'
export default {
    components:{
        TableJobsheets
    },
    data(){
        return {
            isEditTitle: false,
            newTitle: '',
            isLoading: false,
        }
    },
    computed:{
        project_id(){
            return this.$route.params.project_id
        },
        project(){
            return this.$store.state.om.projects[this.project_id]
        },
        jobsheets(){
            return this.project.jobsheets
        },
        isEditable(){
            return this.$store.getters.om_has_role(['project_manager','supervisors'])
        }
    },
    methods:{
        cancelEdit(){
            this.isEditTitle = false
        },
        saveNewTitle(){
            if(this.isLoading){
                return;
            }

            this.isLoading = true;
            this.$store.dispatch('om.projects.updateTitle', {
                project_id: this.project.PK,
                title: this.newTitle
            }).catch(err => {

            }).then(() => {
                this.isLoading = false;
                this.isEditTitle = false;
            })
        },
        editTitle(){
            if(this.isEditable){
                this.newTitle = this.project.name;
                this.isEditTitle = true;
            }
        },
        loadData(){
            return this.$store.dispatch('om.project.load', this.project_id).then(() => {
                this.$store.dispatch('isLoaded')
            })
        },
        deleteProject(){
            this.isLoading = true
            this.$store.dispatch('loading')
            return this.$store.dispatch('om.projects.delete', this.project_id).then(() => {
                this.$store.dispatch('om.project.list')
                this.$router.push('/jobs')
                this.$store.dispatch('om.dropfile.all')
            })
        }
    },
    mounted(){
        this.loadData()
    },
    watch:{
        project_id(value){
            this.loadData()
        }
    },
    filters:{
        shortKey(value){
            return R.take(12, value)
        },
        formatDate(str){
            return moment(str).format('DD MMM YYYY')
        },
        formatTime(str){
            return moment(str).format('hh:mm A')
        }
    }
}
</script>


<style>
.is-parent-hovered{
    display: none;
}

*:hover > .is-parent-hovered{
    display: inline-block;
}
</style>


var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"timeline"},[_vm._m(0),_vm._l((_vm.steps),function(step,i){return _c('div',{key:i},[(!step.isTag)?_c('div',{staticClass:"timeline-item"},[_c('div',{staticClass:"timeline-marker",class:{
                    [step.class || 'is-success']: step.time
                }}),_c('div',{staticClass:"timeline-content"},[_c('p',{class:{
                        'tooltip is-tooltip-right': step.tooltip
                    },attrs:{"data-tooltip":step.tooltip}},[_vm._v(_vm._s(step.label))]),(step.time)?_c('p',{staticClass:"help tooltip is-tooltip-right",attrs:{"data-tooltip":_vm._f("formatDate")(step.time)}},[_vm._v(_vm._s(_vm._f("fromNow")(step.time)))]):_c('p',{staticClass:"help"},[_vm._v("In progress")])])]):_c('header',{staticClass:"timeline-header",class:{
                        'tooltip is-tooltip-right': step.tooltip
                    },attrs:{"data-tooltip":step.tooltip}},[_c('div',{staticClass:"timeline-marker"}),_c('span',{staticClass:"tag is-small",class:{
                    [step.class || 'is-success']: step.time
                }},[_vm._v(_vm._s(step.label))])])])})],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"timeline-header"},[_c('div',{staticClass:"timeline-marker"}),_c('span',{staticClass:"tag is-small is-success"},[_vm._v("Start")])])
}]

export { render, staticRenderFns }
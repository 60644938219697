<template>
    <div class="card-content">
        <quickview-task :task="task" @close="task = false" @blocked="loadData" />
        <div class="card">
            <table-task  :filteredTasks="tasks" :getProjectName=getProjectName :isViewed=isViewed :selectedSessionTasks=noop :getProcessorName=getProcessorName :viewTask=viewTask />
        </div>
    </div>
</template>


<script>
import * as R from 'ramda'
import TableTask from './../Tasks/TableTask.vue'
import QuickviewTask from './../QuickviewTask.vue'

export default {
    components:{
        TableTask,
        QuickviewTask, 
    },
    data(){
        return {
            tasks: [],
            users: [],
            task: false,
        }
    },
    computed:{
        processor_id(){
            return this.$route.params.staff_id
        }
    },
    methods:{
        loadData(){
            this.$store.dispatch('om.processors.list').then(users => {
                this.users = users
            })
            this.$store.dispatch('om.processors.workload', this.processor_id).then((tasks) =>{
                this.tasks = tasks;
                this.$store.dispatch('isLoaded')
            })
        },
        noop(){

        },
        isViewed(task){
            return this.task == task
        },
        getProjectName(project_id){
            return this.$store.state.om.projects[project_id].name
        },
        viewTask(task){
            this.task = task
        },
        getProcessorName(id){
            return R.pipe(R.find(data => data.staff_id == id), data =>  (data|| {}).name)(this.users)
        },
    },
    mounted(){
        this.loadData()
    },
    watch:{
        processor_id(){
            this.loadData()
        }
    }
}
</script>

<template>
    <div class="column">
        <div class="level">
            <div>
                <span class="heading">USER ACCOUNT</span>
                <span class="help">CI/CD Test</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    mounted(){
        this.$store.dispatch('isLoaded')
    }
}
</script>


<template>
    <div class="modal is-active" >
        <div class="modal-background"></div>
        <div class="modal-content">
            <div class="card">
                <div class="card-content" >
                    
                    <div class="notification" v-if="hasOrder">
                        <table class="table is-fullwidth">
                            <tbody>
                                <tr>
                                    <th c>Order No:</th>
                                    <td>{{task.feedback.order_no}} </td>
                                </tr>
                                <tr>
                                    <th>Service Id:</th>
                                    <td>{{task.feedback.service_id}} </td>
                                </tr>
                                <tr v-if="task.feedback['OD Number']">
                                    <th>OD No:</th>
                                    <td><a :href="task.feedback['OD Link']">{{task.feedback['OD Number']}}</a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    
                    <div class="field">
                        <div class="control">
                            <label class="label">Cancellation Remarks</label>
                            <textarea v-validate="{required:true}" v-model="cancellation.remarks" name="remarks" class="textarea" placeholder="Notes of cancellation request" :class="{'is-danger': errors.has('remarks')}"></textarea>
                            <span class="help">Good description will help future you and others understand the reason.</span>
                        </div>
                    </div>
                </div>
                <div class="card-content has-background-light">
                    <div class="level">
                        <div class="level-left"></div>
                        <div class="level-right">
                            <div class="buttons">
                                <a @click="$emit('close')" class="button is-text ">close</a>
                                <a @click="submitcancellation" class="button is-danger" :class="{
                                    'is-loading': isLoading
                                }">{{ cancelWhat }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TaskStates from './../../domain/TaskStates.js'
export default {
    props:['selectedTask', 'task'],
    data(){
        return {
            isLoading: false,
            cancellation: {
                remarks: '',
                cancellation_type: 'Sales request',
                type: ''
            },
        }
    },
    computed:{
        hasOrder(){
            return this.task.Status == 'SUBMITTED' || this.task.Status == 'RERAISED'
        },
        cancelWhat(){
            return this.hasOrder ? 'ORDER CANCELLATION SUBMITTED' : 'CANCEL THIS TASK'
        }
    },
    methods:{
        submitcancellation(){
            
            this.cancellation.type = this.task.Status == 'SUBMITTED' || this.task.Status == 'RERAISED' || this.task.Status == 'ASSIGNED_RERAISE' ? 'cancel_order' : 'cancellation'
            this.$validator.validateAll().then(passed => {
                if(!passed){
                    return
                }
                this.isLoading = true

                const cancelStatus = this.cancellation.type == 'cancellation' ? 'CANCELLED' : 'CANCEL_SUBMITTED'

                if(!TaskStates(this.selectedTask).canTransTo(cancelStatus)){
                    alert(`${this.selectedTask.Status } task cannot be ${cancelStatus}`)
                    return
                }
 
                this.$store.dispatch('om.tasks.cancel', {
                    task: this.selectedTask,
                    cancellation: this.cancellation,
                }).then(res => {
                    this.isLoading = false
                    this.$emit('cancelled')
                    this.$emit('close')
                }).catch(() => {
                    this.isLoading = false
                })

            })
        },
    },
}
</script>


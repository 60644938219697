<template>
    
        <div @click="$emit('toggleSelectTask',task)" class="box task_box" :class="{ 'pointer': task.Status == 'UNASSIGNED', 'task-selected' : selectedTasks[task.task_id]}">
            <div class="level">
                <div class="level-start">
                    <div class="level-item">
                        <div >
                            <div class="tags has-addons">
                                <span class="tag" :class="{
                                        'is-info': task.Status == 'ASSIGNED',
                                        'is-success' : task.Status == 'SUBMITTED' || task.Status == 'COMPLETED',
                                    }">{{ task.Status }}</span>
                                <span @click="handleUnassigned(task)" v-if="task.GS1 != 'UNASSIGNED'" class="tag">{{ getStaffName(task.GS1)}}</span>
                            </div>
            
                            <span class="title is-4">{{ task.CustomerName}}</span>
                        </div>
                    </div>
                </div>
                <div class="level-end">
                    <div class="level-item">
                        <div class="hidden">
                            <div class="field has-addons">
                                <p class="control">
                                    <a @click.prevent.stop="moreInfo(task)" class="button is-small">
                                    <span>More info</span>
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="level">
                <div class="level-start">
                    <div class="level-item">
                        <div class="level-item">
                            <div>
                                <div class="field is-grouped is-grouped-multiline" >
                                    <div class="control">
                                        <span class="tags has-addons">
                                        <span class="tag is-dark">SEGMENT/UNIT</span>
                                        <span class="tag is-info">{{ task['Segment/Unit'] }}</span>
                                    </span>
                                    
                                    </div>

                                    <div class="control">
                                        <span class="tags has-addons">
                                        <span class="tag is-dark">Product/Service</span>
                                        <span class="tag is-info">{{ task['Product/Service'] || "-" }}</span>
                                    </span>
                                    </div>
                                </div>
                                <span class="heading has-text-grey-light">{{ task.TaskID}}</span>
                            </div>
                        </div>
                        
                    </div>
                </div>

                <div class="level-end">
                    <div class="level-item">
                        <div class="level is-mobile">
                            <div class="level-item">
                            <div class="card" style="width:100%;">
                                <div style="padding:5px 10px; ">
                                <span class="is-size-7 has-text-grey-light">Date</span>
                                <p class="has-text-weight-bold">{{ task['Date'] || "-"}}</p>
                                </div>
                            </div>
                            </div>
                            <div class="level-item">
                            <div class="card" style="width:100%;">
                                <div style="padding:5px 10px; ">
                                <span class="is-size-7 has-text-grey-light">RFS/RFT</span>
                                <p class="has-text-weight-bold">{{ task['RFS/RFT'] || "-" }}</p>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
</template>


<script>
export default {
    props: ['task','selectedTasks','getStaffName','moreInfo'],
    methods:{
        handleUnassigned(task){
            if(confirm("Unassigned this task?")){
                alert("released")
            }
        }
    }
}
</script>

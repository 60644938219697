<template>
    <div class="modal is-active">
        <div class="modal-background"></div>
        <div class="modal-content">
            <div class="modal-body">
                <div class="box">
                    <h2 class="title is-4">Patch feedback</h2>
                    <div class="field">
                        <label class="label">Order no.</label>
                        <div class="control">
                            <input type="text" class="input" v-model="form.order_no">
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Service ID</label>
                        <div class="control">
                            <input type="text" class="input" v-model="form.service_id">
                        </div>
                    </div>
                    <div class="buttons">
                        <button @click="handlePatch" class="button is-primary">Patch</button>
                        <button @click="$emit('close')" class="button is-text">Cancel</button>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
export default {
    props:['task'],
    data(){
        return {
            form : {
                order_no: '',
                service_id: ''
            }
        }
    },
    computed:{
        
    },
    mounted(){
        this.copyData()
    },
    methods:{
        copyData(){
            this.form.order_no = this.task.feedback.order_no
            this.form.service_id = this.task.feedback.service_id
        },
        noChanges(){
            return this.task.feedback.order_no == this.form.order_no && this.task.feedback.service_id == this.form.service_id
        },
        handlePatch(){
            if(this.noChanges()){
                alert('No changes made. Nothing to save')
                return
            }

            this.$store.dispatch('om.tasks.patch', {
                task: this.task,
                data: this.form
            }).then((task) => {
                console.log(task)
                this.$emit('onpatched', task)
            })

        }
    }
}
</script>


<template>
    <div>
        <div ref="spreadsheet" ></div>
    </div>
</template>

<script>
import jexcel from 'jexcel'
import 'jexcel/dist/jexcel.css'

export default {
    props: ['data', 'columns' ],
    data(){
        return {
            spreadsheet: false,
            options :{
                data: this.data || [[]],
                // allowToolbar:true,
                // tableOverflow: true,
                // tableHeight: '200px',
                // tableWidth: '90vw',
                // fullscreen: true,
                // search: true,
                // pagination: 30,
                columns: [
                    { type: 'text', title: 'Car', width: '120px' },
                    { type: 'text', title: 'Car', width: '120px' },
                    { type: 'dropdown', title: 'Make', width: '250px', source: [ 'Alfa Romeo', 'Audi', 'Bmw','Honda' ] },
                    { type: 'text', title: 'Car', width: '120px' },
                    { type: 'calendar', title: 'Available', width: '250px'},
                    { type: 'text', title: 'Car', width: '120px' },
                    { type: 'text', title: 'Car', width: '120px' },
                    { type: 'text', title: 'Car', width: '120px' },
                    { type: 'text', title: 'Car', width: '120px' },
                    { type: 'text', title: 'Car', width: '120px' },
                    { type: 'text', title: 'Car', width: '120px' },
                    { type: 'text', title: 'Car', width: '120px' },
                    { type: 'text', title: 'Car', width: '120px' },
                    { type: 'dropdown', title: 'Make', width: '250px', source: [ 'Alfa Romeo', 'Audi', 'Bmw','Honda' ] },
                    { type: 'calendar', title: 'Available', width: '250px'},
                    { type: 'checkbox', title: 'Stock', width: '80px' },
                    { type: 'numeric', title: 'Price', width: '100px', mask: '$ #,###.00', decimal: '.' },
                    { type: 'color', title: 'last', width: '100px', render: 'square' },
                ]
            }
        }
    },
    computed:{
        columnsx(){
            // return this.$store.state.om.configs
            return this.$store.getters.om_requestTypes
        }
    },
    mounted(){
        this.spreadsheet = jexcel(this.$refs.spreadsheet, this.options)
    }
}
</script>


<style>
html{
    overflow:visible !important;
}

td.dropdown{
    display: table-cell !important;
}


.jexcel{
    /* table-layout: auto */
}

</style>
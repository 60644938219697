<template>
    <div class="section">
       <div v-if="step == 1 " class="columns">
           <div class="column is-5 is-offset-3">
                <div class="card">
                    <div class="card-header">
                        <div class="card-header-title">
                            <h1 class="title is-6">New Job</h1>
                        </div>
                    </div>
                    <div class="card-content" >
                        <label class="label">Job sheet</label>
                        <div class="file has-name is-boxed">
                            <label class="file-label">
                                <input class="file-input" type="file" name="jobsheet" ref="jobsheet" @change="handleFileChange"> 
                                <span class="file-cta">
                                <span class="file-icon">
                                    <i class="fas fa-upload"></i>
                                </span>
                                <span class="file-label">
                                    Select Job Sheet
                                </span>
                                </span>
                                <span class="file-name">
                                    {{ filename }}
                                </span>
                            </label>
                        </div>
                    </div>
                    <div class="card-footer">
                        <a class="card-footer-item button is-radiusless is-primary" @click="processJobSheet">
                            NEXT
                        </a>
                    </div>
                </div>
           </div>
       </div>

        <div v-if="step == 2" class="columns">
            <div class="column is-12">
                <div class="card">
                    <div class="card-header">
                        <div class="card-header-title">
                            REVIEW RECORDS
                        </div>
                    </div>
                    <div class="card-content">
                        <sheet-review :jobsheet_id="jobsheet_id" @reject="step = 1" @accept="acceptJobSheet" :records="records"></sheet-review>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import * as R from 'ramda'
import ExcelReader from '../services/ExcelReader'
import SheetReview from './new/reviews.vue'

export default {
    components: {
        SheetReview
    },
    mounted(){
        this.$store.dispatch('isLoaded')
    },
    data(){
        return {
            file: false,
            filename: '',
            records: [],
            processing: false,
            step: 1,
            jobsheet_id: false
        }
    },
    computed:{
        project_id(){
            return this.$route.params.project_id
        }
    },
    methods:{
        handleClose(){
            this.$router.push('/jobs')
        },
        handleFileChange(ev){
            ev.stopPropagation()
            ev.preventDefault()
            
            this.file = R.head(ev.target.files)
            this.filename = this.file.name 
        },
        processJobSheet(){
            this.processing = true
            ExcelReader.read(this.file).then(records =>{
                this.processing = false
                this.$store.dispatch('om.jobsheet.checkDuplicate', { records }).then(jobsheet_id => {
                    this.jobsheet_id = jobsheet_id
                    this.records = records
                    this.step = 2
                }).catch(err => {
                    console.log(err)
                    alert(err)
                })
            })
        },
        acceptJobSheet(drop_id){
            let dropfile = this.$store.state.om.dropfile.files[drop_id]
            this.$store.dispatch('om.jobsheet.accept', {
                drop_id,
                file: this.file,
                records: this.records,
                project_id: this.project_id,
                dropfile: dropfile.details
            }).then(jobsheet => {
                this.$router.push(`/jobs/projects/${this.project_id}/${jobsheet.PK}`)
            })
        }
    }
}
</script>

<template>
    <div>
        <div class="column" style="border-bottom: solid 1px #ccc;">
            <div class="level">
                <div class="level-start">
                    <div class="level-item">
                        <div class="field is-grouped">
                            <div class="control">
                                <input v-model="filterKeyword" type="text" class="input is-rounded" placeholder="Filter">
                            </div>
                            <div class="control">
                                <span class="help">Showing <strong>{{ filteredTasksByKeyword.length }}</strong> records</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="level-end">
                    <div class="field is-grouped">
                        <div class="control">
                            <div class="field has-addons">
                                <div class="control">
                                    <a @click="updateDiff(-1)" class="button is-small">&laquo;</a>
                                </div>
                                <div class="control">
                                    <div class="button is-small is-static">{{dateRangeDis}}</div>
                                </div>
                                
                                <div class="control">
                                    <div class="select is-small">
                                        <select v-model="filterType">
                                            <option value="all">All</option>
                                            <option value="day">Daily</option>
                                            <option value="week">Week</option>
                                            <option value="month">Month</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="control">
                                    <a @click="filterDiff = 0" class="button is-small">Reset</a>
                                </div>
                                <div class="control">
                                    <a :disabled="filterDiff >= 0" @click="updateDiff(1)" class="button is-small">&raquo;</a>
                                </div>
                            </div>
                        </div>
                        <div class="control">
                            <sorter :data="filteredTasksByKeyword" @sorted="onSorted" :sortfields="{
                                'updatedAt': 'Updated At',
                                'details.TaskID': 'Task ID',
                                'details.project.id': 'Project',
                                'details.Customer Name': 'Customer Name',
                                'details.dropfile.Region': 'Region',
                                'CreatedBy.name': 'Created by',
                                'PK': 'Jobsheet',
                                'GS1': 'Processor',
                                'Status': 'Status',
                            }" />
                        </div>
                        <div class="control">
                            <export-excel :data=sortedTasks />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <table class="table is-fullwidth is-hoverable">
            <thead>
                <tr>
                    <th width="2">#</th>
                    <th>Task</th>
                    <th>Project</th>
                    <th>Customer</th>
                    <th>Request</th>
                    <th>Ref</th>
                    <th>Jobsheet</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                <tr @click="viewTask(task)" v-for="(task,i) in sortedTasks" :key="i" :class="{'is-selected': isSelected(task)}">
                    <td><span class="has-text-grey-lighter">{{ i+1 }}</span> </td>
                    <td>
                        <p class="has-text-weight-semibold">{{ task.TaskID | shortKey}}</p>
                        <span class="tag is-info">{{ task.details.dropfile['Request Type'] }}</span>
                    </td>
                    <td>
                        {{ getProjectName(task.details.project.id)}}
                    </td>
                    <td>
                        <div>
                            <span class="has-text-weight-semibold">{{ task.details['Customer Name'] }}</span><br>
                            <span class="heading">Name</span>
                        </div>                 
                        
                        <div class="headings" v-if="task.details.dropfile.Region">
                            <span>{{ task.details.dropfile.Region }}</span>
                            <span class="heading">Region</span>
                        </div> 
                    </td>
                     <td>
                        <div v-if="task.details.dropfile['Short Description on Request']">
                            <span>{{ task.details.dropfile['Short Description on Request'] }}</span>
                            <span class="heading">Description</span>
                        </div>                       

                        <div class="headings" v-if="task.details['Product/Requirement']">
                            <span>{{ task.details['Product/Requirement'] }}</span>
                            <span class="heading">Product/Requirement</span>
                        </div>   

                        <div class="headings" v-if="task.details['Request Type']">
                            <span>{{ task.details['Request Type'] }}</span>
                            <span class="heading">Request Type</span>
                        </div>   

                        <div class="headings" v-if="task.details['Sub-Order Type (if any)']">
                            <span>{{ task.details['Sub-Order Type (if any)'] }}</span>
                            <span class="heading">Sub-Order Type</span>
                        </div>  
                    </td>
                    <td>
                        <div class="headings" v-if="task.details.dropfile['Sales Ref Number']">                            
                            <span>{{ task.details.dropfile['Sales Ref Number'] }}</span>
                            <span class="heading">Sales Ref No.</span>
                        </div>
                        
                        <div class="headings" v-if="task.details.dropfile['BCS Req ID (if applicable)']">
                            <span>{{ task.details.dropfile['BCS Req ID (if applicable)'] }}</span>
                            <span class="heading">BCS Request No.</span>
                        </div>

                        <div v-if="odNumber(task)">
                            <a :href="odLink(task)" target="_blank" rel="noopener">{{ odNumber(task) }}</a>
                             <span class="heading">OD No.</span>
                        </div>                        
                    </td>
                    <!-- <td>
                        <div v-if="task.RequestType == 'All'">
                            <a :href="odLink(task)" v-if="odLink" target="_blank" rel="noopener">{{ odNumber(task) }}</a>
                        </div>                                             
                    </td> -->
                    <td>
                        <div class="headings">
                            <span>{{ task.PK | shortKey}}</span>
                            <span class="heading">Jobsheet ID</span>
                        </div>

                        <div class="headings">
                            <span>{{ task.CreatedBy.name }}</span>
                            <span class="heading">Created By</span>
                        </div>

                        <div class="headings">
                            <span>{{ task.createdAt | diffNow}}</span>
                            <span class="heading">{{ task.createdAt | formatDate}}</span>
                        </div>
                    </td>
                    <td>
                        <task-status :task="task" />
                        <div v-if="getProcessorName(task.GS1)">
                            <span class="help">{{getProcessorName(task.GS1)}}</span>
                            <span class="heading">Assigned To</span>
                        </div>                        
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import moment from 'moment'
import * as R from 'ramda'
import AttachmentTask from './AttachmentTag.vue'
import TaskStatus from './TaskStatus.vue'
import ExportExcel from './ExportExcel.vue'
import TaskSM from './../domain/TaskStates.js'
import Sorter from './Tasks/Sorter.vue'
const hash = require('sha1')

export default {
    components:{
        AttachmentTask,
        ExportExcel,
        TaskStatus,
        Sorter,
    },
    props:['tasks','isSelected', 'processors', 'task', 'defaultFilter', 'defaultFilterType'],
    data(){
        return {
            filterKeyword: this.defaultFilter || '',
            sortedTasks: [],
            filterType: this.defaultFilterType || 'all',
            filterDiff: 0,
        }
    },
    computed:{       
        jobsheets(){
            return this.$store.state.om
        }, 
        filteredTasksByKeyword(){
            return this.tasks
            .filter(data => {
                if(this.filterType == 'all'){
                    return true
                }
                return this.dateRange[0].isSameOrBefore(data.updatedAt, 'day') && 
                this.dateRange[1].isAfter(data.updatedAt, 'day')
            })
            .filter(data => JSON.stringify(R.omit(['details'],data)).toLowerCase().indexOf(this.filterKeyword.toLowerCase()) > -1)
        },
        dateRange(){
            return [
                moment().startOf(this.filterType).add(this.filterDiff , this.filterType),
                moment().startOf(this.filterType).add(this.filterDiff + 1 , this.filterType),
            ]
        },
        dateRangeDis(){
            switch(this.filterType){
                case "day":
                    return this.dateRange[0].format('DD MMM YYYY')
                case "week":
                    return R.pipe(
                        R.map(date => date.format('DD MMM YYYY')),
                        R.join(' → ')
                    )(this.dateRange)
                case "month":
                    return this.dateRange[0].format('MMMM YYYY')
                case 'all':
                    return "All"
            }            
        }
    },
    mounted(){
       
    },
    methods:{
        odNumber(task){
            if (task.feedback)
                return task.feedback['OD Number']

            if (task.details['OD Number'])
                return task.details['OD Number']

            return 'Not Specified'
        },
        odLink(task){
            if (task.feedback)
                return task.feedback['OD Link']            
                
            if (task.details['OD Link'])               
             return task.details['OD Link']

        return null            
        },
        onSorted(sorted){
            this.sortedTasks = sorted
        },  
        canAssign(task){
            return TaskSM(task).canTransTo('ASSIGNED') || TaskSM(task).canTransTo('ASSIGNED_RERAISE')
        },
        viewTask(task){
            this.$emit('taskSelected', task )
        },
        getProcessorName(staff_id){
            return (this.processors[staff_id] || { name: ''}).name 
        },
        getProjectName(project_id){
            return (this.$store.state.om.projects[project_id] || { name: project_id }).name
        },
        updateDiff(diff){
            if(diff == 1 && this.filterDiff < 0){
                this.filterDiff++
            }else if(diff == -1 ){
                this.filterDiff--
            }
        },
    },
    filters:{
        diffNow(value){
            return moment(value).fromNow()
        },
        shortKey(value){
            return R.take(12, value)
        },
        formatDate(value){
            return moment(value).format('DD MMM YYYY, hh:mm A')
        },
        fromNow(value){
             return moment(value).fromNow()
        }
    }
}
</script>

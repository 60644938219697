<template>
  <div>
    <div class="card">
      <div class="card-content">
        <h2 class="title is-4">2019 Monthly Performance</h2>
        <apexchart type="bar" height="350" :options="chartOptions" :series="series"/>
      </div>
      <div class="card-footer">
          <div class="card-footer-item">
              <div class="has-text-centered">
                  <span class="heading has-text-success"> < 1 day</span>
                  <span class="title is-2 has-text-success">{{ perf[0]}}%</span>
              </div>
          </div>
          <div class="card-footer-item">
              <div class="has-text-centered">
                  <span class="heading has-text-info"> < 3 days</span>
                  <span class="title is-2 has-text-info">{{ perf[1]}}%</span>
              </div>
          </div>
          <div class="card-footer-item">
              <div class="has-text-centered">
                  <span class="heading has-text-danger"> > 3 days</span>
                  <span class="title is-2 has-text-danger">{{ perf[2]}}%</span>
              </div>
          </div>
      </div>
    </div>
  </div>
</template>


<script>
import * as R from 'ramda'
import apexchart from "vue-apexcharts";
export default {
  components: {
    apexchart
  },
  methods:{
      randomize(m, min, max){
          const randomNum = () => Math.round(Math.random() * (max - min)) + min
          return [...Array(12).keys()].map((i) => i < m ? randomNum() : 0)
      }
  },
  computed:{
      perf(){
          const datas = R.pluck('data', this.series).map( data => R.sum(data))
          const total = R.sum(datas)
        
          return datas.map( data => Math.round(data / total * 100) )
      },
      series(){
          return [
            {
                name: " < 1 day",
                data: this.randomize(6, 10, 30)
            },
            {
                name: " < 3 days",
                data: this.randomize(6, 60,120)
            },
            {
                name: " > 3 days",
                data: this.randomize(6, 0, 10)
            }
        ]
      }
  },
  data() {
    return {
      chartOptions: {
        colors:['#64CC6D', '#4B9CE7', '#EB4C64'],
        chart: {
          height: 350,
          type: "bar",
          stacked: true,
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: "top" // top, center, bottom
            },
            endingShape:'rounded',
            distributed: false,
            columnWidth:'20%'
          }
        },
        dataLabels: {
          enabled: false,
          formatter: function(val) {
            return val + "%";
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"]
          }
        },

        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
          ],
          position: "top",
          labels: {
            offsetY: -18
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5
              }
            }
          },
          tooltip: {
            enabled: true,
            offsetY: -35
          }
        },
        // fill: {
        //   gradient: {
        //     shade: "light",
        //     type: "horizontal",
        //     shadeIntensity: 0.25,
        //     gradientToColors: undefined,
        //     inverseColors: true,
        //     opacityFrom: 1,
        //     opacityTo: 1,
        //     stops: [50, 0, 100, 100]
        //   }
        // },
        yaxis: {
          axisBorder: {
            show: true
          },
          axisTicks: {
            show: true
          },
          labels: {
            show: true,
            // formatter: function(val) {
            //   return val + "%";
            // }
          },
          title:{
              text: "Orders"
          }
        },
      }
    };
  }
};
</script>

<template>
    <div class="container">
        <div class="columns">
            <div class="column is-12">
                <h1 class="title is-6">Instructions</h1>
                <article class="message is-link">
                    <div class="message-body">
                        <div class="columns">
                            <div class="column content">
                                <ul>
                                    <li>Fill up the submission form. Download here <a @click="downloadTemplate">{{tpl.file.details.filename }}</a></li>
                                    <li>Attached the form in your communication with <strong>ODM Team</strong>. Please ensure the filename starts with <em>om_submission</em> </li>
                                    <li>Include <em>SIMI's</em> email address <a class="tag tooltip has-text-weight-medium" :data-tooltip="'Copy'" @click="copyText('om_submission@simi.tm.com.my', $event)" >om_submission@simi.tm.com.my</a></li>
                                    <li>Wait for acknowledgment/rejection email</li>
                                </ul>
                            </div>
                            <div class="column is-narrow">
                                <figure class="image">
                                    <img src="../../public/om_submission.png" alt="Outlook snapshot">
                                </figure>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        </div>
        <div class="tabs is-boxed">
            <ul>
                <li :class="{'is-active': tab == 'my'}" @click="tab = 'my'"><a>My Request</a></li>
                <li :class="{'is-active': tab == 'my-region'}" @click="tab = 'my-region'"><a>My Team Request <span class="tag is-warning" style="margin-left:1rem">New !</span></a></li>
            </ul>
        </div>
        <div class="columns">
            <div class="column is-12" v-if="tab == 'my'">
                <article class="message" v-if="nofile">
                    <div class="message-body">
                        You have no order request yet

                        <p @click="hack">...</p>
                    </div>
                </article>
                
                <div v-if="!nofile">
                    <div class="level">
                        <div class="level-start">
                            <input type="text" v-model="filterKeyword" class="input is-rounded" placeholder="filter">
                        </div>
                    </div>
                </div>
                <table v-if="!nofile" class="table is-fullwidth is-hoverable">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Drop ID</th>
                            <th>Details</th>
                            <th>Region</th>
                            <th>Request Type</th>
                            <th>Ref #</th>
                            <th @click="sortTable('createdAt')" class="is-clickable"><span v-if="sortKey == 'createdAt'" class="icon is-pulled-right" v-html="sortIcon"></span><abbr title="Sort by this">Requested At</abbr></th>
                            <th @click="sortTable('Status')" class="is-clickable"><span v-if="sortKey == 'Status'" class="icon is-pulled-right" v-html="sortIcon"></span><abbr title="Sort by this">Status</abbr></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="" v-for="(file, i) in filteredFiles" :key=i>
                            <td width="5" class="has-text-grey-lighter" style="padding-right:0;">
                                {{ i + 1 }}
                            </td>
                            <td>
                                <a class="tag tooltip" :data-tooltip="'Copy'" @click="copyData(file, $event)" >{{ file.drop_id | shortKey }}</a>
                            </td>
                            <td>
                                <div>
                                    <a style="display:block; width: 400px;" @click="downloadFile(file)">{{ file.details.subject }} </a>
                                    <span class="heading">Subject</span>
                                </div>
                                

                                <div v-if="file.details['Short Description on Request']">
                                    <span>{{ file.details['Short Description on Request'] }}</span>
                                    <span class="heading">Description</span>
                                </div>     
                            </td>
                            <td>
                                {{file.details.Region}}
                            </td>
                            <td>
                                {{file.details['Request Type']}}
                            </td>
                            <td>
                                <div class="headings" v-if="file.details['Sales Ref Number']">
                                    <span>{{ file.details['Sales Ref Number']}}</span>
                                    <span class="heading">Sales Ref No.</span>
                                </div>
                                
                                <div class="headings" v-if="file.details['BCS Req ID (if applicable)']">
                                    <span>{{ file.details['BCS Req ID (if applicable)']}}</span>
                                    <span class="heading">BCS Request No.</span>
                                </div>
                            </td>
                            <td>
                                <span class="tooltip" :data-tooltip="file.createdAt | formatDate">{{ file.createdAt | fromNow  }}</span>
                                <span class="heading">By {{file.CreatedBy.name}}</span>
                            </td>
                            <td>
                                <drop-status :file="file" />
                            </td>
                            <td>
                                <!-- <a v-if="file.Status != 'UPLOADED'" @click="removeFile(i)" class="delete"></a> -->
                                <router-link :to="`/dropfiles/${file.PK}`">View</router-link>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div v-if="lastKey">
                    <a @click="loadDropfiles" class="button is-fullwidth" :class="{'is-loading': isLoading}">Load More</a>
                </div>
            </div>
            <div class="column is-12" v-if="tab == 'my-region'">
                <article class="message" v-if="nofile">
                    <div class="message-body">
                        You have no order request yet

                        <p @click="hack">...</p>
                    </div>
                </article>
                
                <div v-if="!nofile">
                    <div class="level">
                        <div class="level-start">
                            <input type="text" v-model="filterKeyword" class="input is-rounded" placeholder="filter">
                        </div>
                    </div>
                </div>
                <table v-if="!nofile" class="table is-fullwidth is-hoverable">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Drop ID</th>
                            <th>Details</th>
                            <th>Region</th>
                            <th>Request Type</th>
                            <th>Ref #</th>
                            <th @click="sortTable('createdAt')" class="is-clickable"><span v-if="sortKey == 'createdAt'" class="icon is-pulled-right" v-html="sortIcon"></span><abbr title="Sort by this">Requested At</abbr></th>
                            <th @click="sortTable('Status')" class="is-clickable"><span v-if="sortKey == 'Status'" class="icon is-pulled-right" v-html="sortIcon"></span><abbr title="Sort by this">Status</abbr></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="" v-for="(file, i) in filteredFilesTeam" :key=i>
                            <td width="5" class="has-text-grey-lighter" style="padding-right:0;">
                                {{ i + 1 }}
                            </td>
                            <td>
                                <a class="tag tooltip" :data-tooltip="'Copy'" @click="copyData(file, $event)" >{{ file.drop_id | shortKey }}</a>
                            </td>
                            <td>
                                <div>
                                    <a style="display:block; width: 400px;" @click="downloadFile(file)">{{ file.details.subject }} </a>
                                    <span class="heading">Subject</span>
                                </div>
                                

                                <div v-if="file.details['Short Description on Request']">
                                    <span>{{ file.details['Short Description on Request'] }}</span>
                                    <span class="heading">Description</span>
                                </div>     
                            </td>
                            <td>
                                {{file.details.Region}}
                            </td>
                            <td>
                                {{file.details['Request Type']}}
                            </td>
                            <td>
                                <div class="headings" v-if="file.details['Sales Ref Number']">
                                    <span>{{ file.details['Sales Ref Number']}}</span>
                                    <span class="heading">Sales Ref No.</span>
                                </div>
                                
                                <div class="headings" v-if="file.details['BCS Req ID (if applicable)']">
                                    <span>{{ file.details['BCS Req ID (if applicable)']}}</span>
                                    <span class="heading">BCS Request No.</span>
                                </div>
                            </td>
                            <td>
                                <span class="tooltip" :data-tooltip="file.createdAt | formatDate">{{ file.createdAt | fromNow  }}</span>
                                <span class="heading">By {{file.CreatedBy.name}}</span>
                            </td>
                            <td>
                                <drop-status :file="file" />
                            </td>
                            <td>
                                <!-- <a v-if="file.Status != 'UPLOADED'" @click="removeFile(i)" class="delete"></a> -->
                                <router-link :to="`/dropfiles/${file.PK}`">View</router-link>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div v-if="lastKey">
                    <a @click="loadDropfiles" class="button is-fullwidth" :class="{'is-loading': isLoading}">Load More</a>
                </div>
            </div>
            <!-- <div class="column is-3">
                <order-request-uploader @uploaded="handleUpload" />
            </div> -->
        </div>
    </div>
</template>


<script>
import * as R from 'ramda'
import moment from 'moment'
import OrderRequestUploader from './OrderRequestUploader.vue'
import DropStatus from './../../Dropfiles/DropStatus.vue'

const delay = (dur) => new Promise(done => setTimeout(done, dur))

export default {
    components:{
        OrderRequestUploader,
        DropStatus,
    },
    data(){
        return {
        //    files: [],
            filesTeam: [],
            isLoading: false,
            filterKeyword: '',
            sortKey: 'createdAt',
            sortDescend: true,
            form: {},
            templateFilename: 'om_submission_template.xlsx',
            tab: 'my',
        }
    },
    async mounted(){
        this.$store.dispatch('om.init')
        this.$store.dispatch('om.dropfile.resetKey')
        this.loadDropfiles()
    },
    methods:{ 
        async downloadTemplate(){
            const url = await this.$store.dispatch('om.attachments.download', this.tpl.file)
            window.open(url, "_BLANK")
        },
        hack(){
            this.$router.push('/dropfiles')
        },
        sortTable(prop){
            if (this.sortKey == prop)
                this.sortDescend = !this.sortDescend            
            else
                this.sortKey = prop
        },
        async downloadFile(file){
            const url = await this.$store.dispatch('om.dropfile.download', {drop_id: file.drop_id})
            window.open(url, "_BLANK")
        },
        async loadDropfiles(){
            this.$store.dispatch('om.dropfile.list')
            this.$store.dispatch('om.dropfile.listteam', this.user.staff_id)
        },
        // loadDropfilesTeam(){
        //     this.$store.dispatch('om.dropfile.list', this.user.staff_id)
        // },
        handleUpload(file){
            this.loadDropfiles()
        },
        removeFile(drop_id){
            if(!confirm("Confirm to delete the file?")){
                return 
            }
            
            this.$store.dispatch('om.dropfile.remove', { drop_id }).then(() => {
                this.loadDropfiles()
            })
        },
        copyData(file, ev){
            this.$copyText(R.take(12,file.PK))
            ev.toElement.dataset.tooltip = "Copied"
            delay(1000).then(() => {
                ev.toElement.dataset.tooltip = "Copy"
            })
            
        },
        copyText(text, ev){
            this.$copyText(text)
            ev.toElement.dataset.tooltip = "Copied"
            delay(1000).then(() => {
                ev.toElement.dataset.tooltip = "Copy"
            })
        },
        getRejectionReason(file){
            if(file.Status != 'REJECTED'){
                return ''
            }
            return `${file.details.rejection.reason}

-${file.details.rejection.rejectedBy.name}-`
        },
    },
    computed:{
        sortDirection(){
            return (this.sortDescend) ? 'descend' : 'ascend';            
        },
        sortIcon(){
            if (this.sortDescend)
                return `<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-sort-descending" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <line x1="4" y1="6" x2="13" y2="6" />
                            <line x1="4" y1="12" x2="11" y2="12" />
                            <line x1="4" y1="18" x2="11" y2="18" />
                            <polyline points="15 15 18 18 21 15" />
                            <line x1="18" y1="6" x2="18" y2="18" />
                        </svg>`
            
            return `<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-sort-ascending" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <line x1="4" y1="6" x2="11" y2="6" />
                        <line x1="4" y1="12" x2="11" y2="12" />
                        <line x1="4" y1="18" x2="13" y2="18" />
                        <polyline points="15 9 18 6 21 9" />
                        <line x1="18" y1="6" x2="18" y2="18" />
                    </svg>`
        },
        lastKey(){
            return this.$store.state.om.dropfile.lastKey  
        },
        user(){
            return this.$store.state.login.user
        },
        filteredFiles(){
            let filtered = this.files.filter(data => JSON.stringify(data).toLowerCase().indexOf(this.filterKeyword) > -1 && data.CreatedBy.name == this.user.name)
            return R.sort(R[this.sortDirection](R.prop(this.sortKey)), filtered)
        },
        filteredFilesTeam(){
            // this.filesTeam.push(this.teamfiles)
            // console.log(this.filesTeam)
            let filtered = this.teamfiles.filter(data => JSON.stringify(data).toLowerCase().indexOf(this.filterKeyword) > -1)
            return filtered
            // return R.sort(R[this.sortDirection](R.prop(this.sortKey)), filtered)
        },
        files(){
            return R.pipe(
                R.values
            )(this.$store.state.om.dropfile.files)
        },
        teamfiles(){
            return R.pipe(
                R.values
            )(this.$store.state.om.dropfile.teamfiles)
        },
        nofile(){
            return R.keys(this.files).length == 0
        },
        tpl(){
            // console.log(this.$store.state.om.configs)
            // if(!this.$store.state.om.configs){
            //     console.log('default')
            // } else {
            //     console.log('from store')
            //     this.templateFilename = this.$store.state.om.configs['attachment.template.template.form'].filename
            // }

            return {
                file: { 
                    SK:'attachment.template.template.form' , 
                    details:{
                        filename: this.templateFilename
                    }
                }
            }
        },
    },
    filters:{
        fromNow(value){
            return moment(value).fromNow()
        },
        formatDate(value){
            return moment(value).format('DD MMM YYYY, hh:mm A')
        },
        shortKey(value){
            return R.take(12, value)
        },
    }
}
</script>

const mappers = {
    order(data) {
        return 
    }
}

module.exports = (data) => {
    const mapper = mappers[data.type]
    if (!mapper) {
        return null
    }

    return mapper(data)
}
<template>
    <div>
        <div class="card-content">
            <h1 class="title">Masterlist</h1>
        </div>
        <div class="">
            <template-tabs />
            <iframe src="/#/masterlist/sheet" style="width:100%; height:calc(100vh - 190px);"></iframe>
        </div>
    </div>
</template>

<script>
import TemplateTabs from './TemplateTabs'
export default {
    components:{
        TemplateTabs,
    },
    computed:{
        templates(){
            return this.$store.getters.om_requestTypes
        },
        columns(){
            
        }
    }
}
</script>
<template>
<div>
<div style="padding-bottom:280px;" class="">
<div class="navbar is-fixed-bottom animated fadeInUp faster ">
    <div class="card is-fullwidth" style="width: 100%;">
        <div class="card-header">
            <div class="card-header-title">
                <div class="field has-addons">
                    <p class="control">
                        <a @click="$emit('prev-task')" class="button">
                        <span class="icon is-small">
                            &laquo;
                        </span>
                        </a>
                    </p>
                    <p class="control">
                        <a @click="$emit('next-task')" class="button">
                        <span class="icon is-small">
                            &raquo;
                        </span>
                        </a>
                    </p>
                </div>
            </div>
            <span href="#" class="card-header-icon" aria-label="more options">
                <a @click="$emit('close')" class="delete"></a>
            </span>
        </div>
        <div class="columns is-gapless has-background-light">
            <div class="column is-2">
                <div class="card-content ">
                <div >
                    <span class="heading">PROGRESS</span>
                    <p class="title is-1 has-text-centered animated zoomIn faster" :key="monitor.totalCompleted">{{ monitor.totalCompleted }} <span class="is-size-7">/ {{ monitor.initialTotal }}</span></p>
                </div>
            </div>
            </div>
            <div class="column has-background-white">
                <div class="card-content">
                    <div class="columns">
                    <div class="column">
                        <div class="card-contents">
                            <!-- <div class="columns is-mobile">
                                <div class="field column">
                                    <div class="control">
                                        <label  class="label">Order No.</label>
                                        <input  type="text" class="input" v-model="form.order_no" name='order_no' :class="{
                                            'is-danger animated shake faster': errors.has('order_no')
                                        }">
                                    </div>
                                </div>
                                <div class="field column">
                                    <div class="control">
                                        <label  class="label">Service ID</label>
                                        <input type="text" class="input" v-model="form.service_id" name='service_id' :class="{
                                            'is-danger animated shake faster': errors.has('service_id')
                                        }">
                                    </div>
                                </div>
                                <div class="field column">
                                    <div class="control">
                                        <label  class="label">Qoute No.</label>
                                        <input type="text" class="input" v-model="form.qoute_no" name='qoute_no' :class="{
                                            'is-danger animated shake faster': errors.has('qoute_no')
                                        }">
                                    </div>
                                </div>
                                <div class="field column">
                                    <div class="control">
                                        <label  class="label">Billing No.</label>
                                        <input type="text" class="input" v-model="form.billing_no" name='billing_no' :class="{
                                            'is-danger animated shake faster': errors.has('billing_no')
                                        }">
                                    </div>
                                </div>
                            </div>
                            <div class="columns is-mobile">
                                <div class="column">
                                    <div class="field column">
                                        <div class="control">
                                            <label  class="label">Sphere ID</label>
                                            <input type="text" class="input" v-model="form['Sphere ID']" name='sphere_id' :class="{
                                                'is-danger animated shake faster': errors.has('sphere_id')
                                            }">
                                        </div>
                                    </div>
                                </div>
                                <div class="column">
                                    <div class="field column">
                                        <div class="control">
                                            <label  class="label">RFS Date</label>
                                            <input type="text" class="input" v-model="form['RFS Date']" name='rfs_date' :class="{
                                                'is-danger animated shake faster': errors.has('rfs_date')
                                            }">
                                        </div>
                                    </div>
                                </div>
                                <div class="column">
                                    <div class="field column">
                                        <div class="control">
                                            <label  class="label">RFT Date</label>
                                            <input type="text" class="input" v-model="form['RFT Date']" name='rft_date' :class="{
                                                'is-danger animated shake faster': errors.has('rft_date')
                                            }">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="columns is-mobile">
                                <div class="column">
                                    <div class="field column">
                                        <div class="control">
                                            <label  class="label">OD Number</label>
                                            <input type="text" class="input" v-model="form['OD Number']" name='od_number' :class="{
                                                'is-danger animated shake faster': errors.has('od_number')
                                            }">
                                        </div>
                                    </div>
                                </div>
                                <div class="column">
                                    <div class="field column">
                                        <div class="control">
                                            <label  class="label">OD Link</label>
                                            <input type="text" class="input" v-model="form['OD Link']" name='od_link' :class="{
                                                'is-danger animated shake faster': errors.has('od_link')
                                            }">
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="columns is-multiline">
                                <div class="field column">
                                    <div class="control">
                                        <label  class="label">Order No.</label>
                                        <input  type="text" class="input" v-model="form.order_no" name='order_no' :class="{
                                            'is-danger animated shake faster': errors.has('order_no')
                                        }">
                                    </div>
                                </div>
                                <div class="field column">
                                    <div class="control">
                                        <label  class="label">Service ID</label>
                                        <input type="text" class="input" v-model="form.service_id" name='service_id' :class="{
                                            'is-danger animated shake faster': errors.has('service_id')
                                        }">
                                    </div>
                                </div>
                                <div class="field column">
                                    <div class="control">
                                        <label  class="label">Qoute No.</label>
                                        <input type="text" class="input" v-model="form.qoute_no" name='qoute_no' :class="{
                                            'is-danger animated shake faster': errors.has('qoute_no')
                                        }">
                                    </div>
                                </div>
                                <div class="field column">
                                    <div class="control">
                                        <label  class="label">Billing No.</label>
                                        <input type="text" class="input" v-model="form.billing_no" name='billing_no' :class="{
                                            'is-danger animated shake faster': errors.has('billing_no')
                                        }">
                                    </div>
                                </div>
                                <div class="field column">
                                    <div class="control">
                                        <label  class="label">Sphere ID</label>
                                        <input type="text" class="input" v-model="form['Sphere ID']" name='sphere_id' :class="{
                                            'is-danger animated shake faster': errors.has('sphere_id')
                                        }">
                                    </div>
                                </div>
                                <div class="field column">
                                    <div class="control">
                                        <label  class="label">RFS Date</label>
                                        <input type="text" class="input" v-model="form['RFS Date']" name='rfs_date' :class="{
                                            'is-danger animated shake faster': errors.has('rfs_date')
                                        }">
                                    </div>
                                </div>
                                <div class="field column">
                                    <div class="control">
                                        <label  class="label">RFT Date</label>
                                        <input type="text" class="input" v-model="form['RFT Date']" name='rft_date' :class="{
                                            'is-danger animated shake faster': errors.has('rft_date')
                                        }">
                                    </div>
                                </div>
                                <div class="field column">
                                    <div class="control">
                                        <label  class="label">OD Number</label>
                                        <input type="text" class="input" v-model="form['OD Number']" name='od_number' :class="{
                                            'is-danger animated shake faster': errors.has('od_number')
                                        }">
                                    </div>
                                </div>
                                <div class="field column">
                                    <div class="control">
                                        <label  class="label">OD Link</label>
                                        <input type="text" class="input" v-model="form['OD Link']" name='od_link' :class="{
                                            'is-danger animated shake faster': errors.has('od_link')
                                        }">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="column is-2">
                        <div class="card-contents">
                            <div class="buttons">
                                <a @click="handleComplete" class="button is-primary  is-fullwidth" :class="{'is-loading': loading}">
                                    COMPLETE
                                </a>
                                <a @click="$emit('blocked')" class="button is-text is-fullwidth has-text-danger">
                                    Mark as blocked
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
</div>
</template>


<script>
import * as R from 'ramda'
export default {
    props: ['monitor', 'selectedTask', 'loading'],
    data(){
        return {
            form: {
                order_no: '',
                service_id: '',
                qoute_no: '',
                billing_no: '',
                "Sphere ID": '',
                "RFS Date": '',
                "RFT Date" : '',
                "OD Number" : '',
                "OD Link" : '',
                logs: []
            }
        }
    },
    mounted(){
        this.resetForm()
    },
    watch:{
        selectedTask(value){
            console.log("change task")
            this.resetForm()
        }
    },
    methods:{
        resetForm(){
            this.form = {
                order_no: this.selectedTask.details["Order Number"] || '',
                service_id: '',
                qoute_no: '',
                billing_no: '',
                "Sphere ID": this.selectedTask.details["Sphere ID"] || '',
                "RFS Date": this.selectedTask.details["RFS Date"] || '',
                "RFT Date" : this.selectedTask.details["RFT Date"] || '',
                "OD Number" : this.selectedTask.details["OD Number"] || '',
                "OD Link" : this.selectedTask.details["OD Link"] || '',
                logs: []
            }
            
            if(this.selectedTask.feedback){
                
                
                R.keys(this.form).forEach(key => {
                    this.form[key] = this.selectedTask.feedback[key]? this.selectedTask.feedback[key] : this.form[key]
                })
                
                this.$set(this.form, 'old_order_no', this.selectedTask.feedback.order_no)
                this.$set(this.form, 'old_service_id', this.selectedTask.feedback.service_id)
                
                this.form.logs.push(R.mergeAll([
                    this.selectedTask.feedback,
                    {
                        date: this.selectedTask.updatedAt
                    }
                ]))
            }
        },
        handleComplete(){
            this.$validator.validateAll().then(passed => {
                if(passed){
                    const omits = ['logs']
                    this.$emit('completeTask', R.omit(omits, this.form))
                }
            })
        }
    }
}
</script>

<template>
    <div>
        <form @submit.prevent="handleSubmit" class="form form-horizontal">
            <div class="notification">
                <p>Current template:  <a @click="downloadTemplate">{{tpl.file.details.filename }}</a></p>
                <p>Current version: {{ tpl.version }}</p>
            </div>
            <div class="field has-addons">
                <div class="control">
                    <div class="button is-static">
                        Version
                    </div>
                </div>
                <div class="control">
                    <input type="text" class="input" :placeholder="tpl.version" name="version" v-model="version" v-validate="'required'" :class="{
                        'is-danger': errors.first('version')
                    }">
                </div>
                <div class="control">
                    <div class="file" :class="{'is-danger': errors.first('file')}">
                        <label class="file-label">
                            <input class="file-input" type="file" name="file" v-validate="'required'" @change="analyzeFile">
                            <span class="file-cta">
                                <span class="file-label">
                                    {{ filename || 'Select the template'}}
                                </span>
                            </span>
                        </label>
                    </div>
                </div>
            </div>

            <div class="field">
                <div class="buttons">
                    <button class="button is-primary" type="submit" :class="{'is-loading': isSaving}">Upload template</button>
                </div>
            </div>
        </form>
    </div>
</template>


<script>

import * as R from 'ramda'
import ExcelReader from './../services/ExcelReader.js'

export default {
    data(){
        return {
            version: '',
            filename: '',
            file: false,
            headers: false,
            currentFile: false,
            isSaving: false,
        }
    },
    computed:{
        tpl(){
            if(!this.$store.state.om.configs.template){
                return {
                    version: 0,
                    file: {
                        details: {}
                    }
                }
            }
            return {
                version: this.$store.state.om.configs.template.version,
                file: { 
                    SK:'attachment.template.template.config' , 
                    details:{
                        filename: this.$store.state.om.configs['attachment.template.template.config'].filename
                    }
                }
            }
        }
    },
    methods:{
        async downloadTemplate(){
            const url = await this.$store.dispatch('om.attachments.download', this.tpl.file)
            window.open(url, "_BLANK")
        },
        handleSubmit(){
            this.$validator.validateAll().then(passed => {
                if(!passed){
                    return
                }

                this.isSaving = true
                this.uploadFile().then(() => {

                    this.$store.dispatch('om.config.update',{
                        type: `template`,
                        values: {
                            version: this.version
                        },
                    }).then(() => {
                        this.isSaving = false
                    })
    
                    this.$store.dispatch('om.config.update',{
                        type: `template.columns.${this.version}`,
                        values: this.headers,
                    }).then(() => {
                        this.isSaving = false
                        this.$store.dispatch('om.config.load', true)
                        alert('New template uploaded!')
                    })
                })


            })
        },
        uploadFile(){
            return this.$store.dispatch('om.attachments.upload',{
                file: this.file,
                resource: { PK: 'config', SK: `template`},
                attachment_type: 'template'
            }).then((attachment) => {
                this.currentFile = attachment
            }).catch(err => {
                this.$store.dispatch('showError', "Failed to upload file")
            })
        },
        analyzeFile(ev){
            ev.stopPropagation()
            ev.preventDefault()
            
            this.file = R.head(ev.target.files)
            this.filename = this.file.name 

            const validate = (headers) => {
                if(!headers || headers.Sheet1){
                    alert(`Invalid template uploaded`)
                }

                return headers
            }

            const excludeOutputTemplates = headers => {
                return R.pipe(
                    R.keys,
                    R.filter(data => data.indexOf("_") == 0),
                    excludes => R.omit(excludes, headers)
                )(headers)
            }

            const setHeaders = headers => this.headers = headers

            ExcelReader.headers(this.file)
                .then(validate)
                .then(excludeOutputTemplates)
                .then(setHeaders)
        },
    }
}
</script>


import hash from 'sha1'
import ExcelReader from './../webcomponent/services/ExcelReader'
import moment from 'moment'

const validate = async (file) => {
    //TODO: validate the excel here
    
    if (file.type.indexOf('spreadsheetml') == -1) {
        throw "Invalid file format"
    }

    const data = JSON.stringify([ExcelReader.read(file),file.name, moment().unix()])
    const dropId = `${hash(data)}.${moment().unix()}`

    console.log(dropId)

    return {
        filename: file.name,
        dropId,
        status: 'open'
    }
}

export default {
    validate
}
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tags is-right"},[_c('span',{staticClass:"tag",class:{
        'is-info' : _vm.task.Status == 'ASSIGNED' || _vm.task.Status == 'ASSIGNED_RERAISE',
        'is-warning' : _vm.task.Status == 'UNASSIGNED',
        'is-danger' : _vm.task.Status == 'BLOCKED',
        'is-dark' : _vm.task.Status == 'CANCELLED' || _vm.task.Status == 'CANCEL_SUBMITTED',
        'is-success' : _vm.task.Status == 'SUBMITTED' || _vm.task.Status == 'COMPLETED' || _vm.task.Status == 'RERAISED',
    }},[_vm._v(_vm._s(_vm.task.Status))]),_c('attachment-task',{attrs:{"task":_vm.task}}),(!_vm.checkHash(_vm.task))?_c('span',{},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"19","height":"19","viewBox":"0 0 24 24","fill":"none","stroke":"#d0021b","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"arcs"}},[_c('path',{attrs:{"d":"M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"}}),_c('line',{attrs:{"x1":"12","y1":"9","x2":"12","y2":"13"}}),_c('line',{attrs:{"x1":"12","y1":"17","x2":"12","y2":"17"}})])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div class="modal is-active" >
        <div class="modal-background"></div>
        <div class="modal-content">
            <div class="card">
                <div class="card-content">
                    <div class="field">
                        <div class="control">
                            <label class="label">Blockage type</label>
                            <input type="hidden" name="blockage_type" v-model="blockage.blockage_type" v-validate="{required:true}">
                            <div class="select" :class="{'is-danger': errors.has('blockage_type')}">
                                <select  v-model="blockage.blockage_type" placeholder="select type">
                                    <option value="" di>Select</option>
                                    <option v-for="(type,i) in blockages" :key="i" :value="type">{{type}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="control">
                            <label class="label">Remarks</label>
                            <textarea v-validate="{required:true}" v-model="blockage.remarks" name="remarks" class="textarea" placeholder="Describe the blockage" :class="{'is-danger': errors.has('remarks')}"></textarea>
                            <span class="help">Good description will help future you and others understand the reason</span>
                        </div>
                    </div>
                </div>
                <div class="card-content has-background-light">
                    <div class="level">
                        <div class="level-left"></div>
                        <div class="level-right">
                            <div class="buttons">
                                <a @click="$emit('close')" class="button is-text ">Cancel</a>
                                <a @click="submitBlockage" class="button is-warning">UPDATE BLOCKAGE</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as R from 'ramda'
import TaskStates from './../../domain/TaskStates.js'
export default {
    props:['selectedTask'],
    data(){
        return {
            blockage: {
                blockage_type: '',
                remarks: '',
                type: 'blockage'
            },
        }
    },
    methods:{
        submitBlockage(){
            this.$validator.validateAll().then(passed => {
                if(!passed){
                    return
                }

                if(!TaskStates(this.selectedTask).canTransTo('BLOCKED')){
                    alert(`${this.selectedTask.Status } task cannot be block`)
                    return
                }
 
                this.$store.dispatch('om.tasks.blockage', {
                    task: this.selectedTask,
                    blockage: this.blockage,
                }).then(res => {
                    this.$emit('blocked')
                    this.$emit('close')
                })

            })
        },
    },
    computed:{
        blockages(){
            return R.values(this.$store.state.om.configs.blockages)
        }
    }
}
</script>


<template>
    <div id="om-app">
        <div v-if="isOffline && !requireLogin" class="navbar is-fixed-top has-background-danger animated slideInDown faster" style="z-index:9999; ">
            <div class="navbar-start">
                <div class="navbar-item has-text-white">
                    <span v-if="$store.state.isOffline == true">Offline. You have no internet connection <a @click="refresh" class="button is-small is-white is-outlined" style="margin-left:10px;">Refresh</a></span>
                    <span v-else>{{ $store.state.isOffline }}</span>
                </div>
            </div>
            <div class="navbar-end">
                <div class="navbar-item">
                    <a @click="$store.dispatch('dismissError')" class="delete is-pulled-right"></a>
                </div>
            </div>
        </div>
        <login v-if="requireLogin" />
        <div v-else>
            <div class="modal" :class="{
                'is-active': isLoading
            }">
                <div class="modal-background has-background-white" style="opacity:0.9"></div>
                <div class="modal-content is-centered has-text-centered">
                    <span class="title button is-loading is-text"></span><br>
                    <span class="has-text-grey-light" style="position:relative; top:-20px;">Loading...</span>
                </div>
            </div>
            <layout v-if="ready" @logout="handleLogout">
                <router-view :store="$store"></router-view>
            </layout>
        </div>
    </div>
</template>



<script>
require('animate.css')
import * as R from 'ramda'
import Layout from './Layout.vue'
require('./../../utils/styles/tm.scss')
import LocalStorage  from './../../utils/LocalStorage.js'
import Login from "./../../Authentication/webcomponent/Login.vue" 

export default {
    components: {
        Login,
        Layout,
    },
    data(){
        return {
            ready: false
        }
    },
    created(){
        this.$store.dispatch('requiresAuth', true)
        this.$store.dispatch('login.init')
        this.$store.dispatch('om.init')
        this.$store.dispatch('om.roles.currentUser', (this.$store.state.login.user || {}).staff_id).then(() => {
            this.loadLocalStorage()
            this.ready = true
        })
    },
    watch:{
        currentRole(value){
            LocalStorage.set('om.current_role', value)
        }
    },
    computed:{
        currentRole(){
            return this.$store.state.om.currentUserActiveRole 
        },
        isOffline(){
            return this.$store.state.isOffline
        },
        requireLogin(){
            return this.$store.getters.show_login
        },
        isLoading(){
            return this.$store.state.isLoading
        }
    },
    methods:{
        loadLocalStorage(){
            this.$store.dispatch('om.roles.switch', LocalStorage.get.item('om.current_role', R.head(this.$store.state.om.currentUserRoles)))
        },
        handleLogout(){
            this.logout()
        },
        logout(){
            this.$store.dispatch('login.logout')
        },
        refresh(){
            window.location.reload(true)
        }
    }
}

</script>

<style lang="scss">
$grey-lighter : hsl(0, 0%, 86%);
@import "./../../utils/styles/tm.scss";
@import "~bulma-tooltip";
@import "~bulma-switch/src/sass/index.sass";
@import '~bulma-quickview/src/sass/index.sass';
@import '~bulma-badge/src/sass/index.sass';
@import '~bulma-pageloader/src/sass/index.sass';
@import '~bulma-ribbon/src/sass/index.sass';
@import '~bulma-timeline/src/sass/index.sass';

#om-app, body, html{
    min-height: 100vh;
}

.is-clickable{
    cursor:pointer !important;
}

.pre-formatted{
  white-space: pre;
}

.is-preline.is-tooltip-multiline::before{
      white-space: pre-line !important;
}
</style>
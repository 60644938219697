<template>
    <div>
        <div class="level">
            <div class="level-start">
                <span class="title is-5">Workload</span>
            </div>
            <div class="level-end">
                <input type="text" v-model="filterKeyword" placeholder="filter" class="input">
            </div>
        </div>
        <div v-for="processor in sortedWorkload" :key="processor.staff_id" class="card">
            <div class="">
                <div class="level">
                    <div class="level-left">
                        <div class="column">
                            <a @click="$emit('selected', processor)" class="tooltip" data-tooltip="Select this processor">{{ processor.name }}</a>
                            <span class="heading">{{ processor.staff_id }}</span>
                        </div>
                    </div>
                    <div class="level-right">
                        <div class="level-item">
                            <div class="column has-text-centered">
                                <span class="heading">Assigned</span>
                                <span class="title is-4">{{ processor.assigned }}</span>
                            </div>
                        </div>
                        <div class="level-item">
                            <div class="column has-text-centered">
                                <span class="heading">Blocked</span>
                                <span class="title is-4">{{ processor.blocked }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import * as R from 'ramda'
export default {
    data(){
        return {
            filterKeyword: '',
            workload: {

            }
        }
    },
    computed:{
        processors(){
            return this.$store.state.om.processors
        },
        sortedWorkload(){
            return R.pipe(
                R.map(processor => {
                    const blocked = (this.workload[processor.staff_id]||{}).BLOCKED || 0
                    const assigned = (this.workload[processor.staff_id]||{}).ASSIGNED || 0
                    return {
                        name: processor.name,
                        staff_id: processor.staff_id,
                        blocked,
                        assigned,
                        total: blocked + assigned,
                    }
                }),
                R.sort(R.ascend(R.prop('assigned'))),
                R.filter(data => JSON.stringify(data).indexOf(this.filterKeyword) > -1)
            )(this.processors)
        },
    },
    mounted(){
        this.loadData()
    },
    methods:{
        getTooltip(processor){
            return `Assigned: ${processor.assigned} | Blocked: ${processor.blocked}`
        },
        getWorkload(staff_id){
            return this.workload[staff_id] || {}
        },
        loadData(){
            return this.$store.dispatch('om.processors.list').then(() => {
                this.processors.forEach(processor => {
                    this.$store.dispatch('om.processors.workload', processor.staff_id).then(tasks => {
                        const grouped = R.pipe(
                            R.groupBy(R.prop('Status')),
                            R.map(data => data.length),
                        )(tasks)

                        this.$set(this.workload, processor.staff_id, grouped)
                    })
                })
            })
        }
    }
}
</script>

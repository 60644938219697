<template>
        <div :in-viewport-once="true">
            <span v-if="attachment" class="tag tooltip" :data-tooltip="filename">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" style="fill:currentColor">
                    <path d="M18.08 12.42l-6.18 6.19a4.25 4.25 0 0 1-6-6l8-8a2.57 2.57 0 0 1 3.54 0 2.52 2.52 0 0 1 0 3.54l-6.9 6.89A.75.75 0 1 1 9.42 14l5.13-5.12a1 1 0 0 0-1.42-1.42L8 12.6a2.74 2.74 0 0 0 0 3.89 2.82 2.82 0 0 0 3.89 0l6.89-6.9a4.5 4.5 0 0 0-6.36-6.36l-8 8A6.25 6.25 0 0 0 13.31 20l6.19-6.18a1 1 0 1 0-1.42-1.42z"/>
                </svg>
            </span>
        </div>
</template>


<script>
import * as R from 'ramda'
const inViewport = require('vue-in-viewport-mixin');

export default {
    props: ['task'],
    mixins: [ inViewport ],
    data(){
        return {
            inViewport: {
                now: false
            },
            loaded: false,
        }
    },
    mounted(){
        //this.loadData()
    },
    watch:{
        'inViewport.now'(visible){
            if(visible && !this.loaded){
                this.loadData()
                this.loaded = true
            }
        }
    },
    computed:{
        attachment(){
            const attachmentLens = R.lensPath(['om','attachments','network-diagram',this.task.SK, 0])
            return R.view(attachmentLens, this.$store.state)
        },
        filename(){
            return this.attachment ? this.attachment.details.filename : ''
        }
    },
    methods:{
        loadData(){
            this.$store.dispatch('om.attachments.get', {
                resource:this.task,
                attachment_type: 'network-diagram'
            })
        }
    }
}
</script>
